import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type FC, useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { ReqPostMessageRecord, ResPostMessageRecord } from '~/@common/constants/iframe/iframe'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { webviewService } from '~/@common/services'
import authService from '~/@common/services/auth.service'
import { localStorageService } from '~/@common/services/storage.service'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import RecentChallengeSection from './@widgets/RecentChallenge/RecentChallengeSection'
import RecentStudySection from './@widgets/RecentStudy/RecentStudySection'
import SmileChallengeSection from './@widgets/SmileChallenge/SmileChallengeSection'
import WeeklyInfoSection from './@widgets/WeeklyInfo/WeeklyInfoSection'

const StudentHomePage: FC = observer(() => {
  useCallbackOnVisibilityChange()
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()

  // for teacher iframe
  useEffect(() => {
    window?.parent?.postMessage(ReqPostMessageRecord.PAGE_RENDERED_AFTER_LOGIN, '*')

    const handleGetMessage = (e) => {
      if (e?.data?.type === ResPostMessageRecord.LOG_OUT.type) {
        authService.logout()
        return
      }
    }

    window.addEventListener('message', handleGetMessage)

    return () => {
      window.removeEventListener('message', handleGetMessage)
    }
  }, [])

  useEffect(() => {
    const worksheetId = localStorageService.user.worksheetId
    const dateTime = localStorageService.user.dateTime
    const lectureId = localStorageService.lecture.lectureId

    if (worksheetId && dateTime) {
      localStorageService.isInitialPasswordClear()
      navigate(`/worksheet/${worksheetId}/${dateTime}/scoring?size=all`)
      localStorageService.qrDataClear()
    }
    if (lectureId) {
      localStorageService.isInitialPasswordClear()
      navigate(`${generatePath(routeName.student.lectureDetail, { lectureId })}?hideList=true`)
      localStorageService.lectureDataClear()
    }
    webviewService.ready()
  }, [navigate])

  return (
    <div css={_Style}>
      <div className="section-group">
        <WeeklyInfoSection />
        {!isMobile && <SmileChallengeSection />}
      </div>
      <RecentChallengeSection />
      <RecentStudySection />
    </div>
  )
})

export default StudentHomePage

const _Style = css`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
  .section-group {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  ${mediaQuery.underTablet} {
    padding: 20px 16px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    height: auto;

    .section-group {
      overflow: unset;
    }
  }

  .qr-tooltip {
    display: flex;
    right: 60px;
    top: -7px;
    z-index: 1;
  }

  .qr-tooltip-guide {
    padding: 8px 12px;
    width: 194px;
    border-radius: var(--Radius-200);
    background-color: ${colors.white};
    color: ${colors.gray.$900};
    text-align: right;
  }
`
