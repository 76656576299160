import { css } from '@emotion/react'
import { WorksheetDomain } from '@mathflat/domain/@entities'

import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

interface SelfLearningTitleTagProps {
  titleTag: string
  selfLearningType: WorksheetDomain.SelfLearningType
}

export const SelfLearningTitleTag = ({ titleTag, selfLearningType }: SelfLearningTitleTagProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 4px;
        border-radius: var(--Radius-100);
        background-color: ${selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습
          ? colors.blue.$110
          : colors.green['S-GREEN7']};
        color: ${selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습
          ? colors.blue.$500
          : colors.green.$100};
        ${typo.caption02}
        font-weight: 700;
        white-space: nowrap;
      `}
    >
      <Icon
        name={
          selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습
            ? 'icon_balloon_x'
            : 'icon_graph'
        }
        size={14}
      />
      <span>{`${selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습 ? '오답학습' : '심화학습'} - ${titleTag}`}</span>
    </div>
  )
}
