import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { ChangeEvent, FC } from 'react'

import { colors, fontWeight, typo } from '~/@common/styles'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import Switch from '~/@common/ui/Switch/Switch'

interface Props {
  problemSize: number
  isCompleted: boolean
  isShowWithAnswer: boolean
  onIsShowWithAnswerChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ConceptualLearningSwiperController: FC<Props> = ({
  problemSize,
  isCompleted,
  isShowWithAnswer,
  onIsShowWithAnswerChange,
}) => {
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)
  const isShowProblemNumber = !isCompleted || isShowWithAnswer

  return (
    <div css={_css}>
      <div className="controller-header">
        <p className="title">
          {isCompleted ? (
            <strong>예제 풀이결과</strong>
          ) : (
            <>
              <strong>예제 풀기</strong>
              강의를 보며 예제를 풀어보세요.
            </>
          )}
        </p>
        {isCompleted ? (
          <div>
            <Switch
              className="문제같이보기_토글"
              checked={isShowWithAnswer}
              onChange={onIsShowWithAnswerChange}
            >
              문제 푼 화면으로 보기
            </Switch>
          </div>
        ) : (
          <p className="controller">
            <SwiperDefaultBackButton
              theme="none"
              size={24}
              iconSize={16}
              disabled={swiperController.isBeginningSlide}
              onClick={() => {
                swiperController.control?.slidePrev()
              }}
            />
            <SwiperDefaultNextButton
              theme="none"
              size={24}
              iconSize={16}
              disabled={swiperController.isEndSlide}
              onClick={() => {
                swiperController.control?.slideNext()
              }}
            />
          </p>
        )}
      </div>
      {isShowProblemNumber && (
        <p className="problem-number">
          {swiperController.activeIndex + 1}번 | 총 {problemSize}문제
        </p>
      )}
    </div>
  )
}

export default observer(ConceptualLearningSwiperController)

const _css = css`
  background-color: ${colors.white};
  border-top-left-radius: var(--Radius-300);
  border-top-right-radius: var(--Radius-300);
  padding: 24px 20px 0 20px;
  color: ${colors.gray.$900};
  width: 100%;

  .controller-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${colors.gray.$300};

    .title {
      display: flex;
      gap: 4px;
      align-items: center;
      strong {
        ${typo.heading05};
        font-weight: ${fontWeight.bold};
      }
    }
    .controller {
      display: flex;
      align-items: center;
      gap: 22px;
    }
  }
  .problem-number {
    display: flex;
    align-items: center;
    height: 69px;
    border-bottom: 1px solid ${colors.gray.$300};
    ${typo.body01};
    font-weight: ${fontWeight.bold};
  }
`
