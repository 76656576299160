import '../ui/toast/toast.css'

import type { ReactNode } from 'react'
import { toast, type ToastOptions, type ToastPosition, type TypeOptions } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'

import { colors } from '../styles'
import { Icon } from '../ui/Icon/Icon'
import type { IconNames } from '../ui/Icon/iconNames.type'
import ToastContent from '../ui/toast/ToastContent'

// CSS import 적용되지 않아서 injectStyle 사용, CSS loader 확인 필요
// https://fkhadra.github.io/react-toastify/how-to-style/#inject-style-on-demand
injectStyle()

interface ToastData {
  type: TypeOptions
  icon: ReactNode
  color?: string
  backgroundColor?: string
  allowMultipleToast?: boolean // false이면 toast type별로 하나의 toast만 노출
  isMobile?: boolean
}

type ToastDataParams = (
  | {
      type: Extract<TypeOptions, 'success'>
      isDeleted?: boolean
      isSystemError?: never
      isMobile?: boolean
    }
  | {
      type: Extract<TypeOptions, 'error'>
      isDeleted?: never
      isSystemError?: boolean
      isMobile?: boolean
    }
  | {
      type: Extract<TypeOptions, 'info'>
      isDeleted?: never
      isSystemError?: never
      isMobile?: boolean
    }
) & {
  allowMultipleToast: ToastData['allowMultipleToast']
}

const DEFAULT_OPTION = {
  position: 'bottom-left' as ToastPosition,
  marginTop: 10,
  marginLeft: 20,
}

const UNDER_TABLET_OPTION = {
  position: 'bottom-center' as ToastPosition,
  marginTop: 10,
  marginLeft: 0,
}

class ToastService {
  success(
    message: string,
    options: { isDeleted?: boolean; allowMultipleToast?: boolean; isMobile?: boolean } = {},
  ) {
    const type: TypeOptions = 'success'
    const { isDeleted, allowMultipleToast, isMobile } = options
    const toastData = this._getToastData({ type, isDeleted, allowMultipleToast, isMobile })

    toast(<ToastContent text={message} icon={toastData.icon} />, this._getOptions(toastData))
  }

  info(message: string, options: { allowMultipleToast?: boolean; isMobile?: boolean } = {}) {
    const type: TypeOptions = 'info'
    const { allowMultipleToast, isMobile } = options
    const toastData = this._getToastData({ type, allowMultipleToast, isMobile })

    toast(<ToastContent text={message} icon={toastData.icon} />, this._getOptions(toastData))
  }

  error(
    message: string,
    options: {
      isSystemError?: boolean
      allowMultipleToast?: boolean
      subContent?: React.ReactNode
      isMobile?: boolean
    } = {},
  ) {
    const type: TypeOptions = 'error'
    const { isSystemError, allowMultipleToast, isMobile } = options
    const toastData = this._getToastData({ type, isSystemError, allowMultipleToast, isMobile })

    toast(
      <ToastContent text={message} icon={toastData.icon} subContent={options.subContent} />,
      this._getOptions(toastData),
    )
  }

  private _getOptions(toastData: ToastData): ToastOptions {
    const { type, color, backgroundColor, allowMultipleToast, isMobile } = toastData

    const options = isMobile ? UNDER_TABLET_OPTION : DEFAULT_OPTION

    return {
      toastId: allowMultipleToast ? undefined : `Toastify-toast-${type}`,
      type,
      closeButton: () => <Icon name="icon_close" size={20} />,
      hideProgressBar: true,
      draggablePercent: 60,
      autoClose: 3000,
      icon: false,
      position: options.position,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: options.marginTop,
        marginLeft: options.marginLeft,
        padding: 18,
        borderRadius: 'var(--Radius-300)',
        minWidth: 358,
        maxWidth: 600,
        border: `border: 1px solid ${colors.gray.$400}`,
        color: color ?? colors.gray.$900,
        backgroundColor: backgroundColor ?? colors.white,
      },
    }
  }

  private _getToastData(params: ToastDataParams): ToastData {
    const { type, isDeleted, isSystemError, allowMultipleToast, isMobile } = params
    let iconName: IconNames
    let iconColor: string

    switch (type) {
      case 'success':
        iconName = isDeleted ? 'icon_delete_trash' : 'icon_check_circle'
        iconColor = isDeleted ? colors.red.$400 : colors.blue.$500
        break
      case 'info':
        iconName = 'icon_info_fill'
        iconColor = colors.blue.$500
        break
      case 'error':
        iconName = isSystemError ? 'icon_info_fill' : 'icon_close_circle'
        iconColor = isSystemError ? colors.white : colors.red.$400
        break
    }

    return {
      type,
      allowMultipleToast,
      isMobile,
      icon: <Icon name={iconName} size={20} color={iconColor} />,
      color: isSystemError ? colors.white : colors.gray.$900,
      backgroundColor: isSystemError ? colors.gray.$700 : colors.white,
    }
  }
}

export const toastService = new ToastService()
