import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { colorTheme, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

interface Props {
  tags: string[]
}

const ChallengeFilters: FC<Props> = ({ tags }) => {
  return (
    <Swiper
      modules={[FreeMode]}
      freeMode={true}
      style={{
        whiteSpace: 'nowrap',
        margin: 0,
      }}
      centeredSlides={false}
      slidesPerView="auto"
      spaceBetween={6}
    >
      {tags.map((tag, index) => (
        <SwiperSlide
          key={index}
          tag="span"
          style={{
            width: 'fit-content',
          }}
        >
          <span css={_BadgeStyle}>#{tag}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const _BadgeStyle = css`
  ${typo.caption01};
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  font-weight: ${fontWeight.bold};
  color: ${colorTheme.primary};
  border-radius: var(--Radius-100);
  background-color: rgba(57, 95, 226, 0.05);

  ${mediaQuery.underTablet} {
    background-color: transparent;
    padding: 4px 1px;
  }
`

export default observer(ChallengeFilters)
