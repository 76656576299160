import { css } from '@emotion/react'
import React, { useId, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery.ts'
import { localStorageService } from '~/@common/services/storage.service.ts'
import { colors, zIndex } from '~/@common/styles'
import { textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery.tsx'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import PrevLink from '~/@common/ui/(Link)/PrevLink/PrevLink.tsx'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import { GlobalHeader, parts } from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'
import LearningProcessStepChips from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/LearningProcessStepChips.tsx'
import LearningProcessStepDrawer from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/LearningProcessStepDrawer.tsx'
import type {
  Items,
  SelectedItem,
} from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/types/LearningProcessNavigation.type.ts'

type Props = {
  title: string
  items: Items
  selectedItem: SelectedItem
  onClickItem?: (item: SelectedItem) => void
  onClickBack?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onClickNext?: () => void
  onClickPrev?: () => void
} & Pick<React.ComponentProps<'nav'>, 'children' | 'className' | 'style'>

const LearningProcessNavigation = ({
  title,
  items,
  selectedItem,
  onClickItem,
  onClickBack,
  onClickNext,
  onClickPrev,
}: Props) => {
  const [isVisitedConceptLearning, setIsVisitedConceptLearning] = useState(
    localStorageService.learningProcess.isVisitedConceptLearning === 'TRUE',
  )

  const [isDrawerOpened, setIsDrawerOpened] = useState(false)

  const tooltipId = useId()

  const navigate = useNavigate()

  const { isMobile } = useStudentAppMediaQuery()

  const prevDisabled = selectedItem.parent?.value === 0
  // TODO 나중에 수정하고 싶은 로직.
  const nextDisabled =
    selectedItem.parent?.value === items.length - 1 ||
    items[Number(selectedItem.parent?.value) + 1]?.disabled

  return (
    <div css={_css}>
      <GlobalHeader
        className="global-navigation"
        SlotLeft={
          <div className="slot-left-wrapper">
            <PrevLink
              className={parts.slotLeftPrevLink}
              onClick={(e) => {
                e.preventDefault()
                onClickBack ? onClickBack(e) : navigate(-1)
              }}
            >
              <Icon name="icon_chevron_left" size={20} />
            </PrevLink>
            {!isMobile && <div className="navigation-title">{title}</div>}
          </div>
        }
        SlotCenter={
          isMobile ? (
            <div className="navigation-title">{title}</div>
          ) : (
            <LearningProcessStepChips
              items={items}
              onClickItem={(item) => {
                if (!isVisitedConceptLearning) {
                  setIsVisitedConceptLearning(true)
                }

                onClickItem && onClickItem(item)
              }}
              selectedItem={selectedItem}
            />
          )
        }
        SlotRight={
          <div className="slot-right">
            {isMobile ? (
              <button
                data-tooltip-id={tooltipId}
                className="mobile-step-button"
                onClick={() => setIsDrawerOpened(!isDrawerOpened)}
              >
                <Icon name="icon_list" size={20} color={colors.gray.$600} />
                {`step${Number(selectedItem.parent?.value) + 1}`}
              </button>
            ) : (
              <>
                <IconButton
                  name="icon_navigation_arrow_back"
                  onClick={onClickPrev}
                  size="small"
                  disabled={prevDisabled}
                />
                <IconButton
                  name="icon_navigation_arrow_right"
                  onClick={() => {
                    if (!isVisitedConceptLearning) {
                      localStorageService.update({
                        learningProcess: {
                          isVisitedConceptLearning: 'TRUE',
                        },
                      })
                      setIsVisitedConceptLearning(true)
                    }
                    onClickNext && onClickNext()
                  }}
                  size="small"
                  disabled={nextDisabled}
                  data-tooltip-id={tooltipId}
                />
              </>
            )}
            <Tooltip
              id={tooltipId}
              isOpen={!isVisitedConceptLearning && selectedItem.parent?.label === '개념학습'}
              place="bottom-end"
              className="tooltip"
              offset={9}
              opacity={1}
              clickable
              disableStyleInjection={true}
              style={{ display: isVisitedConceptLearning ? 'none' : 'block' }}
            >
              <div
                className="tooltip-content"
                onClick={() => {
                  setIsVisitedConceptLearning(true)
                }}
              >
                <Icon name="icon_check_circle" size={26} color="#B7F08A" />
                이미 알고있는 개념이라면
                <br /> 건너뛰고 문제부터 풀 수 있어요!
              </div>
            </Tooltip>
          </div>
        }
      />
      <LearningProcessStepDrawer
        isOpened={isDrawerOpened}
        handleOpened={(value: boolean) => setIsDrawerOpened(value)}
        items={items}
        selectedItem={selectedItem}
        onClickItem={(item) => {
          if (!isVisitedConceptLearning) {
            setIsVisitedConceptLearning(true)
          }

          onClickItem && onClickItem(item)
        }}
      />
    </div>
  )
}

export default LearningProcessNavigation

const _css = css`
  .slot-left-wrapper {
    display: flex;
    gap: 14px;

    > .navigation-title {
      min-width: 106px;
      max-width: 234px;
    }
  }

  .navigation-title {
    ${textEllipsis(1)};
  }

  .${parts.slotLeft} {
    ${typo.body01};
    font-weight: bold;
  }

  .slot-right {
    display: flex;
    gap: 22px;

    .mobile-step-button {
      display: flex;
      gap: 2px;
      color: ${colors.gray.$600};
      ${typo.body02}
    }
  }

  .global-navigation {
    height: 62px;

    .${parts.slotCenter} {
      position: absolute;
      flex: unset;
      ${typo.body02};
      overflow: unset;
      z-index: ${zIndex.네비게이션_센터};
    }
  }

  .tooltip {
    width: max-content;
    padding: 12px 18px;
    border-radius: var(--Radius-Circle);
    border: 1px solid rgba(57, 95, 226, 0.2);
    background-color: ${colors.white};
    ${typo.body02};
    color: ${colors.gray.$900};
    z-index: ${zIndex.툴팁};
    margin-left: 27px;

    ${mediaQuery.underTablet} {
      margin-left: 0;
      margin-right: 16px;
    }

    .react-tooltip-arrow {
      width: 11px;
      height: 11px;
      top: -6px !important;
      left: auto !important;
      right: 32px;
      transform: rotate(-135deg);
      border-right: 1px solid rgba(57, 95, 226, 0.2);
      border-bottom: 1px solid rgba(57, 95, 226, 0.2);
    }

    .tooltip-content {
      display: flex;
      align-items: center;
      gap: 14px;
      cursor: pointer;
    }
  }
`
