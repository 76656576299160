import styled from '@emotion/styled'
import clsx from 'clsx'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

interface Props extends DefaultProps {
  size?: number
  theme?: 'ghost' | 'round'
  onClick?: () => void
}

export const NoteScaleResetButton = ({
  theme = 'round',
  size = 20,
  className,
  style,
  onClick,
}: Props) => {
  const themeClassName = `theme__${theme}`

  if (theme === 'ghost') {
    return (
      <StyledNoteScaleResetButton
        name="icon_reset-size"
        className={clsx(themeClassName, className)}
        style={style}
        iconSize={size}
        iconStyle={{
          color: colors.gray.$600,
        }}
        onClick={onClick}
      />
    )
  }

  return (
    <StyledNoteScaleResetButton
      name="icon_reset-size"
      className={clsx(themeClassName, className)}
      style={style}
      iconSize={size}
      iconStyle={{
        color: colors.gray.$700,
      }}
      onClick={onClick}
    />
  )
}

const StyledNoteScaleResetButton = styled(IconButton)`
  position: relative;
  z-index: 10;
  width: 44px;
  height: 44px;

  &.theme__ghost {
    gap: 12px;
    padding: 3px;
    width: 24px;
    height: 24px;
  }

  &.theme__round {
    padding: 12px;
    border-radius: var(--Radius-Circle);
    background: hsla(0, 0%, 0%, 0.1);
  }
`
