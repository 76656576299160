import styled from '@emotion/styled'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import qs from 'qs'
import { useEffect, useRef } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { routeName, type studentWorkbookDetailPathParams } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { VirtualKeyboardDrawer } from '~/@common/ui/(Keypad)'
import { virtualKeyboardService } from '~/@common/ui/(Keypad)/keypad.service'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { SwiperController } from '~/@common/ui/SwiperController/SwiperController'
import type { StudentWorkbookDetailPageDTO } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'
import ProblemScoringCard from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringCard'
import ProblemScoringViewGuideOption from '~/@pages/@common/(ProblemScoring)/ProblemScoringViewGuideOption'
import 전체정답_전체삭제_제출 from '~/@pages/@common/(ProblemScoring)/전체정답_전체삭제_제출'
import { StudentEduMaterialScoringDashboard } from '~/@pages/@common/StudentEduMaterialScoringDashboard'

import { StudentWorkbookDetailService } from '../../detail/@service/StudentWorkbookDetail.service'
import StudentWorkbookDetailPageList from '../../detail/@widgets/StudentWorkbookDetailPageList'
import { StudentWorkbookViewerService } from '../@service/StudentWorkbookViewer.service'

const StudentWorkbookViewerScoring = () => {
  const { studentWorkbookId, revisionId, pageNumber } =
    useParams<typeof studentWorkbookDetailPathParams>()

  const service = useRepository(
    StudentWorkbookDetailService<WorkbookDomain.TYPE['시그니처교재']>,
    pageNumber,
  )
  const viewerService = useRepository(StudentWorkbookViewerService)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const searchParamObject = Object.fromEntries(searchParams.entries())
  const scrollRef = useRef<HTMLDivElement>(null)
  const { isMobile } = useStudentAppMediaQuery()

  const handlePageNavigate = (pageTo?: StudentWorkbookDetailPageDTO['prevPageNumber']) => {
    if (pageTo) {
      const path = generatePath(routeName.student.studentWorkbookViewer, {
        studentWorkbookId,
        revisionId,
        pageNumber: pageTo,
      })

      // TODO: 공통 네비게이트 함수 필요할듯
      navigate(
        {
          pathname: path,
          search: qs.stringify(searchParamObject),
        },
        { replace: true },
      )
    }
  }

  const { currentPage, problemScoringViewOption, workbookDetail } = service
  const pageData = viewerService.pageData.get(Number(pageNumber))

  useEffect(() => {
    if (scrollRef.current) {
      const { disposer } = CustomEventService.alltooltipOff.listen(scrollRef.current)
      return () => {
        disposer()
      }
    }
  }, [viewerService.isScoringMode])

  if (!workbookDetail || !viewerService.isScoringMode || !pageData) return null

  return (
    <>
      <S.Container style={{ ...(virtualKeyboardService.show && { paddingBottom: 0 }) }}>
        <div className="swiper">
          <SwiperController
            className="viewer-scoring-swiper"
            style={{ minWidth: 384 }}
            SlotLeft={
              <SwiperDefaultBackButton
                onClick={() => {
                  handlePageNavigate(pageData.prevPageNumber)
                }}
                disabled={!pageData.prevPageNumber}
              />
            }
            SlotCenter={
              <button
                onClick={() => {
                  if (!workbookDetail) return null
                  modalService.openModal(
                    {
                      header: '페이지 리스트',
                      content: (
                        <StudentWorkbookDetailPageList
                          onPageNavigate={handlePageNavigate}
                          allPages={workbookDetail.allPages}
                        />
                      ),
                    },
                    {
                      modalName: '교재 페이지 선택',
                      hasCloseButton: true,
                    },
                  )
                }}
              >
                <Icon
                  name="icon_list"
                  size={20}
                  style={{ marginRight: 4 }}
                  color={colors.gray.$500}
                />
                {pageNumber}P
              </button>
            }
            SlotRight={
              <SwiperDefaultNextButton
                onClick={() => {
                  handlePageNavigate(pageData.nextPageNumber)
                }}
                disabled={!pageData.nextPageNumber}
              />
            }
          />
        </div>
        <div className="problem-card-container" ref={scrollRef}>
          {!currentPage || !problemScoringViewOption || !currentPage.problemScoringColl ? (
            <div className="empty">
              <div className="round round-no-scoring">
                <Icon name="icon_alert_error_fill" size={20} color={colors.white} />
              </div>
              <div className="single-line-text">이 페이지에는 채점할 수 있는 문제가 없어요.</div>
            </div>
          ) : (
            <>
              {problemScoringViewOption?.hasCopyRight &&
              problemScoringViewOption?.채점불가능 &&
              currentPage.problemScoringColl.isNothingSubmitted ? (
                <div className="empty">
                  <div className="round">
                    <Icon name="icon_lock" size={20} color={colors.white} />
                  </div>
                  정답이 비공개 되어있습니다.
                  <br />
                  정답 공개는 선생님에게 문의해주세요.
                </div>
              ) : (
                <>
                  <ProblemScoringViewGuideOption
                    viewOption={problemScoringViewOption}
                    guideDisabled
                  />
                  <div className={clsx('problem-scoring-list')}>
                    {problemScoringViewOption.content.status !== CONTENT_STATUS.채점전 &&
                      problemScoringViewOption.content.autoScored && (
                        <div className="dashboard">
                          <StudentEduMaterialScoringDashboard
                            className="scoring-dashboard"
                            status={problemScoringViewOption.content.status}
                            eduMaterialType="WORKBOOK"
                            score={Math.floor(
                              (currentPage.problemScoringColl.toCorrectArr.length /
                                currentPage.problemScoringColl.toArr.length) *
                                100,
                            )}
                            scoring={{
                              total: currentPage.problemScoringColl.toArr.length,
                              correct: currentPage.problemScoringColl.toCorrectArr.length,
                              incorrect: currentPage.problemScoringColl.toWrongArr.length,
                              unknown: currentPage.problemScoringColl.toUnknowonArr.length,
                              none: currentPage.problemScoringColl.toNoneArr.length,
                            }}
                          />
                        </div>
                      )}
                    {currentPage.problemScoringColl?.toArr.map((problemScoring) => {
                      return (
                        <ProblemScoringCard
                          key={problemScoring.id}
                          className="problem-scoring-list-item"
                          problemScoring={problemScoring}
                          viewOption={problemScoringViewOption}
                          virtualKeybaordShowType="drawer"
                          isSubmittedAnswerAvailable={false}
                          onSubmitVideoAssist={service.onSubmitVideoAssist}
                        />
                      )
                    })}
                    {!problemScoringViewOption.채점완료_혹은_채점불가능 && (
                      <전체정답_전체삭제_제출
                        className="problem-card-grid-footer"
                        {...(!problemScoringViewOption.content.autoScored && {
                          onAllCorrectClick: () => {
                            currentPage.problemScoringColl?.onAllCheckCorrect()
                          },
                        })}
                        onAllClearClick={() => {
                          modalService.openModal(
                            <Modal.Confirm.Negative
                              confirm={{
                                children: '삭제하기',
                                onClick: () => {
                                  currentPage.problemScoringColl?.onAllClear()
                                  modalService.closeModal()
                                },
                              }}
                            >
                              입력한 내용을 전체 삭제하시겠습니까?
                            </Modal.Confirm.Negative>,
                            { modalName: '교재 채점 - 전체삭제' },
                          )
                        }}
                        onSubmitClick={() => {
                          if (studentWorkbookId && revisionId) {
                            modalService.openModal(
                              <Modal.Confirm.Positive
                                confirm={{
                                  children: '제출하기',
                                  onClick: () => {
                                    service.onSubmit({
                                      studentWorkbookId,
                                      revisionId,
                                    })
                                    modalService.closeModal()
                                  },
                                }}
                              >
                                채점 내용을 제출하면 다시 수정할 수 없습니다.
                                <br />
                                채점 내용을 선생님께 제출하시겠습니까?
                              </Modal.Confirm.Positive>,
                              { modalName: '교재 채점 - 제출하기' },
                            )
                          }
                        }}
                        submitTitle={`${
                          currentPage.problemScoringColl.toScoredArr.length ?? '-'
                        }문제 제출`}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!isMobile && (
          <VirtualKeyboardDrawer
            open={virtualKeyboardService.show && virtualKeyboardService.showType === 'drawer'}
            keypadType={virtualKeyboardService.type}
            closeKeyboard={() => virtualKeyboardService.close()}
            onKeyButtonClick={(e, latexNodeType) => {
              virtualKeyboardService.onKeyButtonClick(e, latexNodeType)
            }}
          />
        )}
      </S.Container>
    </>
  )
}

export default observer(StudentWorkbookViewerScoring)

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 0 0 384px;
    .round {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50px;
      height: 50px;
      border-radius: var(--Radius-Circle);

      background-color: ${colors.gray.$600};
      margin-bottom: 10px;

      &.round-no-scoring {
        background-color: rgba(57, 95, 226, 0.5);
      }
    }
    .empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .single-line-text {
        padding-top: 5px;
      }
    }
    .swiper {
      flex-shrink: 0;
      width: 100%;
      margin-bottom: 14px;
    }
    .viewer-scoring-swiper {
      min-width: 384px;
      width: 100%;
      max-width: 100%;
    }
    .dashboard {
      width: 100%;
      margin-top: 10px;
    }
    .scoring-dashboard {
      padding: 0;
    }

    .problem-card-container {
      height: 100%;

      display: flex;
      flex-direction: column;

      overflow: hidden;
    }

    .problem-scoring-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 10px;

      flex: 1 1 100%;

      overflow-y: auto;
      overflow-x: hidden;

      .problem-scoring-list-item {
        width: 100%;
        height: auto;
      }
    }
    .problem-card-grid-footer {
      flex: 0 0 50px;
      width: 100%;
      background: var(--Colors-Gray-150, #f0f2f4);
      padding: 20px 0px 40px;
      > .submit-button {
        width: 100%;
      }
    }

    ${mediaQuery.underTablet} {
      flex: 1 1 100%;
      padding-bottom: 138px;

      .swiper {
        display: none;
      }
      .problem-card-container {
        gap: 0;
      }
      .problem-scoring-list {
        padding-top: 10px;
      }

      .problem-card-grid-footer {
        padding-left: 16px;
        padding-right: 16px;
      }
      .scoring-dashboard {
        min-width: auto;
      }
      .dashboard {
        margin-top: 0;
        padding: 0 16px;
      }
    }
  `,
}
