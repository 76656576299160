import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  일반채점: styled.div`
    display: flex;
    align-items: center;

    width: 384px;
    height: 70px;
    .divider {
      flex: 0 0 1px;
      height: 24px;

      background: ${colors.gray.$300};
    }

    ${mediaQuery.underTablet} {
      width: 100%;
    }
  `,
  일반채점입력Item: css`
    flex: 1 1 127px;
    height: 100%;

    input:disabled + label {
      cursor: default;
    }
    input:not(:disabled) + label {
      cursor: pointer;
    }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &:first-of-type label {
      border-bottom-left-radius: var(--Radius-300);
    }
    &:last-of-type label {
      border-bottom-right-radius: var(--Radius-300);
    }
  `,
  일반채점_정답: css`
    input:checked + label {
      background-color: ${colors.blue.$100};
    }
  `,
  일반채점_오답: css`
    input:checked + label {
      background-color: ${colors.red.$100};
    }
  `,
  일반채점_모름: css`
    input:checked + label {
      background-color: #fff2e2;
    }
  `,
}

export default S
