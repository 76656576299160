import { decodeSync } from '@effect/schema/Parser'
import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import type { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import { observer } from 'mobx-react'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import Modal from '~/@common/ui/modal/Modal'
import ConceptChipDifficultyLevel from '~/@pages/@common/(ConceptChip)/ConceptChipDifficultyLevel/ConceptChipDifficultyLevel'
import { LearningUnavailableAlertModal } from '~/@pages/@widgets/LearningUnavailableAlertModal'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'
import type { ConceptChipDto } from '~/@pages/student/student-home/@common/response.ts'

import RecentChallengeService from '../../@service/RecentChallenge.service'
import ConceptChip from '../ConceptChip/ConceptChip'
import ContentBox from '../ContentBox'
import MoreButton from '../MoreButton'
import NoData from '../NoData'
import SectionHeader from '../SectionHeader'
import ChallengeFilters from './ChallengeFilters'
import RecentChallengeSlider from './RecentChallengeSlider'

const MOBILE_ITEMS_PER_PAGE = 10
const MOBILE_ITEMS_DEFAULT_LENGTH = 3
const MOBILE_CONTENT_BOX_MIN_HEIGHT = 205

const RecentChallengeSection: FC = () => {
  const { isMobile } = useStudentAppMediaQuery()
  const service = useRepository(RecentChallengeService)

  const challengeStore = useRepository(ChallengeStore)
  const learningProcessService = useRepository(LearningProcessService)
  const { recentChallenges, recentChallenge, recentChallengeIndex, isReady } = service
  const [page, setPage] = useState(1)

  const navigate = useNavigate()

  useEffect(() => {
    challengeStore.loadFromChallengeRemoteStorage()
    service.loadRecentChallenges().catch(errorHandlerService.handle)
  }, [challengeStore, service])

  const mobileConceptChipTotalPage = useMemo(() => {
    if (!recentChallenge?.conceptChips.length) {
      return 0
    }
    return (
      Math.ceil(
        (recentChallenge.conceptChips.length - MOBILE_ITEMS_DEFAULT_LENGTH) / MOBILE_ITEMS_PER_PAGE,
      ) + 1
    )
  }, [recentChallenge])

  const conceptChipItems = useMemo(() => {
    if (!recentChallenge?.conceptChips) {
      return
    }
    if (isMobile) {
      return recentChallenge.conceptChips.slice(
        0,
        (page - 1) * MOBILE_ITEMS_PER_PAGE + MOBILE_ITEMS_DEFAULT_LENGTH,
      )
    }
    return recentChallenge.conceptChips
  }, [isMobile, page, recentChallenge?.conceptChips])

  const handleResume = async (params: StudentWorksheetApi.챌린지학습지조회.Params) => {
    try {
      const 챌린지학습 = await learningProcessService.챌린지학습_시작하기(params)

      if (챌린지학습.이어하기_여부) {
        modalService.openModal(
          <Modal.Confirm.Positive
            css={{ width: 266 }}
            cancel={{
              children: '새로하기',
              onClick: async () => {
                const url = await 챌린지학습.새로하기()
                navigate(url)
              },
            }}
            confirm={{
              children: '이어하기',
              onClick: async () => {
                const url = await 챌린지학습.이어하기()
                navigate(url)
                modalService.closeModal()
              },
            }}
          >
            <p>
              학습중이던 소단원 챌린지가 있습니다.
              <br />
              이어서 하시겠습니까?
            </p>
          </Modal.Confirm.Positive>,
          {
            modalName: '소단원 챌린지 이어하기',
          },
        )
        return
      }

      const url = await 챌린지학습.새로하기()
      navigate(url)
    } catch (e) {
      modalService.openModal(<LearningUnavailableAlertModal />, {
        modalName: '학습 불가',
      })
    }
  }

  const handleSlideChange = (index: number) => {
    service.setRecentChallengeIndex(index)
  }

  const recentChallengeItems = recentChallenges?.map((item) => (
    <div key={item.littleChapterId}>
      <span className="challenge-title">
        <b>소단원명</b>
        {item.littleChapterName}
      </span>
    </div>
  ))

  const onClickConceptChip = async (params: StudentWorksheetApi.챌린지학습지조회.Params) => {
    try {
      const data = await learningProcessService.챌린지학습_시작하기(params)

      if (data.이어하기_여부) {
        modalService.openModal(
          <Modal.Confirm.Positive
            css={{ width: 266 }}
            cancel={{
              children: '새로하기',
              onClick: async () => {
                const url = await data.새로하기()
                navigate(url)
              },
            }}
            confirm={{
              children: '이어하기',
              onClick: async () => {
                const url = await data.이어하기()
                navigate(url)
                modalService.closeModal()
              },
            }}
          >
            <p>
              학습중이던 유형 챌린지가 있습니다.
              <br />
              이어서 하시겠습니까?
            </p>
          </Modal.Confirm.Positive>,
          {
            modalName: '유형 챌린지 이어하기',
          },
        )
        return
      }

      const url = await data.새로하기()
      navigate(url)
    } catch (e) {
      modalService.openModal(<LearningUnavailableAlertModal />, {
        modalName: '학습 불가',
      })
    }
  }

  const getOrderScore = ({
    levelOfAchievement,
    levelOfConceptChip,
  }: Pick<ConceptChipDto, 'levelOfConceptChip' | 'levelOfAchievement'>) => {
    const achievementOrderScoreBase = 10e14
    const difficultyOrderScoreBase = 10e13
    let orderScore = 0
    const ORDER_LEVEL_OF_ACHIEVEMENT = [null, 0, 1, 2, 3, 4, 5]
    const ORDER_LEVEL_OF_DIFFICULTY = ['A', 'B', 'C']
    // 성취도 order score 추가
    orderScore +=
      (ORDER_LEVEL_OF_ACHIEVEMENT.indexOf(levelOfAchievement) + 1) * achievementOrderScoreBase
    // 난이도 order score 추가
    orderScore +=
      (ORDER_LEVEL_OF_DIFFICULTY.indexOf(levelOfConceptChip) + 1) * difficultyOrderScoreBase
    // orderNumber order score 추가
    return orderScore
  }

  const hasMore = conceptChipItems && page < mobileConceptChipTotalPage
  const workbookNames = challengeStore.workbooks.map((book) => book.fulltitle)

  const handleMore = () => {
    if (hasMore) {
      setPage(page + 1)
    } else {
      setPage(1)
    }
  }

  return (
    <section css={_Style} data-component="ms__RecentChallengeSection">
      <SectionHeader title="최근 학습한 챌린지" className="section-header">
        {isReady && recentChallengeIndex !== undefined && <ChallengeFilters tags={workbookNames} />}
      </SectionHeader>

      {!recentChallenge ||
      !recentChallenges?.length ||
      !recentChallengeItems ||
      recentChallengeIndex === undefined ? (
        <ContentBox
          css={{ flexGrow: 1, minHeight: MOBILE_CONTENT_BOX_MIN_HEIGHT }}
          isReady={isReady}
        >
          {isReady && !recentChallenges?.length && <NoData type="RECENT_CHALLENGE" />}
        </ContentBox>
      ) : (
        <>
          <ContentBox borderRadiusType="flat-bottom" isReady={true}>
            <RecentChallengeSlider
              items={recentChallengeItems}
              index={recentChallengeIndex}
              onIndexChange={handleSlideChange}
            />
            <p className="status-text">학습한 유형</p>
            <ul className="challenge-type-chip-list">
              {[...recentChallenge.learningConceptChip.entries()].map(([level, value]) => (
                <li key={level} className="challenge-type-chip-item">
                  <ConceptChipDifficultyLevel
                    level={decodeSync(ConceptChipDomain.LevelOfDifficulty)(level)}
                    css={{
                      width: 37,
                      height: 22,
                      borderRadius: 'var(--Radius-100)',
                      marginRight: 10,
                      padding: '4px 9px',
                    }}
                  />
                  {value}개
                </li>
              ))}
            </ul>

            <Button
              theme="secondary"
              size="small"
              className="resume-button"
              onClick={async () => {
                if (!recentChallenge?.littleChapterId) return

                await handleResume({
                  conceptChipIds: [...recentChallenge.conceptChips]
                    .sort(
                      (a, b) =>
                        getOrderScore({
                          levelOfConceptChip: a.levelOfConceptChip,
                          levelOfAchievement: a.levelOfAchievement,
                        }) -
                        getOrderScore({
                          levelOfConceptChip: b.levelOfConceptChip,
                          levelOfAchievement: b.levelOfAchievement,
                        }),
                    )
                    .map((item) => item.conceptChipId)
                    .splice(0, 5),

                  littleChapterId: recentChallenge.littleChapterId,
                  referenceId: recentChallenge.littleChapterId,
                  referenceType: 'LITTLE_CHAPTER',
                })
              }}
            >
              소단원 챌린지 시작하기
            </Button>
          </ContentBox>
          <div css={{ overflow: isMobile ? 'unset' : 'hidden' }}>
            <SimpleBar style={{ maxHeight: '100%', overflow: isMobile ? 'hidden' : 'unset' }}>
              <ul className="concept-chip-list">
                {conceptChipItems?.map((item) => (
                  <ConceptChip
                    key={item.conceptChipId}
                    item={item}
                    tagName="li"
                    className="concept-chip-item"
                    onClick={() =>
                      onClickConceptChip({
                        conceptChipIds: [item.conceptChipId],
                        littleChapterId: recentChallenge.littleChapterId,
                        referenceId: item.conceptChipId,
                        referenceType: 'CONCEPT_CHIP',
                      })
                    }
                  />
                ))}
              </ul>
              {isMobile && conceptChipItems?.length && mobileConceptChipTotalPage > 1 && (
                <MoreButton hasMore={hasMore} onClick={handleMore} />
              )}
            </SimpleBar>
          </div>
        </>
      )}
    </section>
  )
}

export default observer(RecentChallengeSection)

const _Style = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .section-header {
    gap: 10px;
  }
  .challenge-title {
    width: -webkit-fill-available;
    color: ${colors.black};
    ${typo.body02};
    > b {
      font-weight: ${fontWeight.bold};
      margin-right: 6px;
    }
  }
  .challenge-type-chip {
    width: 37px;
    height: 22px;
    border-radius: var(--Radius-100);
    margin-right: 10px;
  }
  .challenge-type-chip-list {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    @media (min-width: 1180px) {
      gap: 20px;
    }
  }
  .challenge-type-chip-item {
    flex-shrink: 1;
  }
  .resume-button {
    width: 100%;
  }
  .concept-chip-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;
  }
  .concept-chip-item {
    padding: 10px 20px;
  }
  .status-text {
    font-weight: ${fontWeight.bold};
    padding: 16px 0 10px 0;
    color: ${colors.gray.$900};
    ${typo.body02};
  }

  ${mediaQuery.underTablet} {
    overflow: unset;
    .content-box {
      min-height: ${MOBILE_CONTENT_BOX_MIN_HEIGHT}px;
    }
    .challenge-type-chip-list {
      justify-content: space-between;
      gap: 0;
      > li {
        padding-right: 6px;
      }
    }
    .concept-chip-list {
      > li:last-of-type {
        border-radius: 0 0 var(--Radius-300) var(--Radius-300);
        overflow: hidden;
      }
    }
  }
`
