import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import { InlineMath } from 'react-katex'
import type SimpleBarCore from 'simplebar-core'

import { curriculumApi } from '~/@common/api/curriculumApi.ts'
import { handwrittenNoteApi, HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi.ts'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import {
  AiTutorService,
  AnalyzeMessage,
  AnalyzeQuestion,
  CheckMessage,
  CheckQuestion,
  ConceptHintQuestion,
  type Message,
  MiddleMessage,
  StartMessage,
} from '~/@common/services/AiTutor.service.ts'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery.tsx'
import type { DefaultProps } from '~/@common/types'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait.ts'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

const 문제영역_최소크기 = '689px'
const 좌우패딩 = '40px'
const 사이간격 = '16px'
const 헤더높이 = '62px'

type Props = {
  isOpened?: boolean
  closeDrawer: () => void
  problemScoring: ProblemScoring<'WORKSHEET'>
}

const WorksheetAiTutorDrawer = ({ isOpened = false, problemScoring, closeDrawer }: Props) => {
  const learningProcessService = useRepository(LearningProcessService)
  const currentStepStr = learningProcessService.currentStep === 1 ? 'step2' : 'step4'

  const loadAndSetQuestion = async (type: string) => {
    const [conceptData] = await curriculumApi.getCurriculum(
      [problemScoring?.problem.conceptId],
      'CONCEPT',
    )

    const 학제학년 =
      conceptData.schoolName === '고등학교'
        ? conceptData.schoolName
        : `${conceptData.schoolName} ${conceptData.gradeName}`

    const 학기과목 =
      conceptData.schoolName === '고등학교'
        ? conceptData.subjectName ?? ''
        : conceptData.semesterName

    if (!conceptData.littleChapterId) return

    const [littleChapterData] = await curriculumApi.getCurriculum(
      [conceptData.littleChapterId],
      'LITTLE_CHAPTER',
    )

    if (type === 'analyze') {
      const url = await handwrittenNoteApi.uploadStudentWorksheetPng({
        id: problemScoring.id,
        subId: problemScoring.problem.id,
        type: HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
      })

      aiTutorService.setQuestion(
        new AnalyzeQuestion({
          problemId: problemScoring.problem.id,
          problemImageUrl: url,
        }),
      )
      window.freshpaint?.track('[챌린지] AI튜터', {
        힌트타입: '풀이분석',
        사용단계: currentStepStr,
        '학제/학년': 학제학년,
        '학기/과목': 학기과목,
      })
    } else if (type === 'check') {
      const url = await handwrittenNoteApi.uploadStudentWorksheetPng({
        id: problemScoring.id,
        subId: problemScoring.problem.id,
        type: HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
      })

      aiTutorService.setQuestion(
        new CheckQuestion({
          problemId: problemScoring.problem.id,
          problemImageUrl: url,
        }),
      )
      window.freshpaint?.track('[챌린지] AI튜터', {
        힌트타입: '풀이확인',
        사용단계: currentStepStr,
        '학제/학년': 학제학년,
        '학기/과목': 학기과목,
      })
    } else {
      aiTutorService.setQuestion(
        new ConceptHintQuestion({
          problemId: problemScoring.problem.id,
          conceptImageUrl: conceptData.url,
          littleChapterImageUrl: littleChapterData.url,
          problemImageUrl: problemScoring.problem.problemImageUrl,
        }),
      )
      window.freshpaint?.track('[챌린지] AI튜터', {
        힌트타입: '개념힌트',
        사용단계: currentStepStr,
        '학제/학년': 학제학년,
        '학기/과목': 학기과목,
      })
    }
  }

  const aiTutorService = useRepository(AiTutorService)

  const scrollRef = useRef<SimpleBarCore>(null)

  useEffect(() => {
    const content = scrollRef.current?.getScrollElement()
    content?.scrollTo(0, content.scrollHeight)

    // 답변 완료시에 한번 더 스크롤 맞춰줌.
    if (aiTutorService.recentMessage.isReplyCompleted) {
      setTimeout(() => content?.scrollTo({ top: content?.scrollHeight }), 500)
    }
  }, [
    aiTutorService.recentMessage.isReplyCompleted,
    aiTutorService.recentMessage.comment,
    isOpened,
  ])

  const onClickRequestQuestion = async (type: string) => {
    if (type === 'end') {
      closeDrawer()
      return
    }

    if (type === 'more') {
      aiTutorService.addMessage(new MiddleMessage())
      return
    }

    await loadAndSetQuestion(type)
    aiTutorService.sendQuestion()
  }

  const renderMessage = (message: Message, index: number, isClickable: boolean) => {
    if (message instanceof StartMessage) {
      return (
        <StartMessageComponent
          key={index}
          message={message}
          onClick={(type) => onClickRequestQuestion(type)}
          isClickable={isClickable}
        />
      )
    } else if (message instanceof MiddleMessage) {
      return (
        <MiddleMessageComponent
          key={index}
          message={message}
          onClick={(type) => onClickRequestQuestion(type)}
          isClickable={isClickable}
        />
      )
    } else if (message instanceof AnalyzeMessage) {
      return (
        <AnalyzeMessageComponent
          key={index}
          message={message}
          onClick={(type) => onClickRequestQuestion(type)}
          isClickable={isClickable}
        />
      )
    } else if (message instanceof CheckMessage) {
      return (
        <CheckMessageComponent
          key={index}
          message={message}
          onClick={(type) => onClickRequestQuestion(type)}
          isClickable={isClickable}
        />
      )
    } else {
      return (
        <ConceptHintMessageComponent
          key={index}
          message={message}
          onClick={(type) => onClickRequestQuestion(type)}
          isClickable={isClickable}
        />
      )
    }
  }

  return (
    <Drawer
      isOpened={isOpened}
      placement="left"
      rootSelector="#app"
      position="absolute"
      closeDrawer={closeDrawer}
      showBackdrop={false}
    >
      <Drawer.Content css={S.DrawerContentStyle}>
        <Drawer.Header hasBorderBottom={false}>
          <S.DrawerHeaderContent>
            <img
              src={`/images/icons/icon_ai_bot_gray.svg`}
              alt="AI 튜터"
              width="20"
              height="auto"
            />
            <div className="header">AI 튜터</div>
            <div className="beta">beta</div>
          </S.DrawerHeaderContent>
        </Drawer.Header>
        <S.Notice>
          AI 튜터는 실험중! 참고용으로만 활용해주세요. <br />
          조금씩 더 똑똑해지고 있어요 💡
        </S.Notice>
        <Drawer.Body scrollRef={scrollRef}>
          <S.DrawerBodyContent>
            {aiTutorService.messages.map((message, index) =>
              renderMessage(message, index, aiTutorService.messages.length - 1 === index),
            )}
          </S.DrawerBodyContent>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer>
  )
}

export default observer(WorksheetAiTutorDrawer)

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const loadingAnimation = keyframes`
    0%{
        opacity: 1;
        transform: scale(0.5);
    }
    
    50% {
        opacity: 0.5;
        transform: scale(1.2);
    }
    
    100% {
        opacity: 0.3;
        transform: scale(0.5);
    }
`

const S = {
  DrawerContentStyle: css`
    border-top: 1px solid var(--Colors-Slate-200);
    box-shadow: 10px 4px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0;
    width: 534px;
    height: calc(100vh - ${헤더높이} - var(--g-safe-area-top) - var(--g-safe-area-bottom));
    margin-top: 0;
    ${mediaQuery.underDesktop} {
      width: calc(100% - ${문제영역_최소크기} - ${좌우패딩} - ${사이간격});
    }
  `,

  DrawerHeaderContent: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    .header {
      color: var(--Colors-Slate-800);
      ${typo.body02};
      font-weight: bold;
    }

    .beta {
      border-radius: var(--Radius-200);
      background-color: var(--Colors-Slate-100);
      padding: 2px 8px;
      margin-left: 4px;
      ${typo.caption01};
      color: var(--Colors-Slate-400);
    }
  `,

  Notice: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 16px;
    background-color: var(--Colors-Slate-100);
    border-top: 1px solid var(--Colors-Slate-200);
    ${typo.caption01};
    font-weight: 700;
    color: var(--Colors-Slate-500);
    text-align: center;

    br {
      display: none;
    }

    @media (max-width: 1164px) {
      br {
        display: block;
      }
    }
  `,

  DrawerBodyContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 30px 20px 20px;

    .message-wrapper {
      display: flex;
      gap: 12px;

      @media (max-width: 1064px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }

    .tutor-wrapper {
      display: flex;
      justify-content: center;

      .tutor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: linear-gradient(
          180deg,
          var(--Colors-Indigo-400) 0%,
          var(--Colors-Indigo-50) 49.5%,
          var(--Colors-Indigo-400) 100%
        );
        border-radius: var(--Radius-Circle);
        overflow: hidden;
      }
    }

    .tutor-response-section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .tutor-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      width: 100%;
      border-radius: var(--Radius-300);
      background-color: var(--Colors-Slate-50);
      padding: 16px;

      .text-section {
        width: 100%;
        ${typo.body02};
        color: var(--Colors-Slate-800);

        .cursor {
          display: inline-flex;
          align-items: center;
          gap: 2px;
          margin-left: 4px;
          animation: ${blink} 1s step-end infinite;

          .dot {
            width: 2px;
            height: 2px;
            background: ${colors.gray.$900};
            border-radius: var(--Radius-Circle);
          }
        }
      }

      .sub-description {
        width: 100%;
        ${typo.caption01}
        font-weight: 700;
        color: var(--Colors-Slate-400);

        .analyze,
        .check {
          display: inline-block;
          min-width: fit-content;
          margin: 0 4px 4px 0;
          ${typo.caption02};
          font-weight: 700;
          vertical-align: middle;
        }

        .analyze {
          border-radius: var(--Radius-100);
          border: 1px solid var(--Colors-Blue-400);
          background-color: var(--Colors-Blue-50);
          padding: 3px 4px;
          color: var(--Colors-Blue-400);
        }

        .check {
          border-radius: var(--Radius-100);
          border: 1px solid var(--Colors-Purple-400);
          background-color: var(--Colors-Purple-50);
          padding: 3px 4px;
          color: var(--Colors-Purple-400);
        }
      }

      strong {
        font-weight: bold;
      }

      h3 {
        font-weight: bold;
      }
    }

    .cta-section {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      &.full-width {
        flex-wrap: nowrap;
        width: 100%;
        margin-top: 16px;

        @media (max-width: 1064px) {
          flex-direction: column;
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        width: fit-content;
        padding: 12px 16px;
        border-radius: var(--Radius-300);
        border: 1px solid var(--Colors-Slate-300);
        background: #fff;
        ${typo.body02};
        cursor: pointer;
        color: var(--Colors-Slate-700);

        &:disabled {
          cursor: not-allowed;
        }

        &.concept-hint {
          &.active {
            color: var(--Colors-Red-400);
            border: 1px solid var(--Colors-Red-400);
            background-color: rgba(254, 242, 242, 0.6);
            font-weight: 700;
          }

          &.off {
            background-color: var(--Colors-Slate-50);
            border: 1px solid var(--Colors-Slate-200);
            color: var(--Colors-Slate-400);
            font-weight: 400;
          }
        }

        &.analyze {
          &.active {
            color: var(--Colors-Blue-400);
            border: 1px solid var(--Colors-Blue-400);
            background-color: rgba(239, 246, 255, 0.4);
            font-weight: 700;
          }

          &.off {
            background-color: var(--Colors-Slate-50);
            border: 1px solid var(--Colors-Slate-200);
            color: var(--Colors-Slate-400);
            font-weight: 400;
          }
        }

        &.check {
          &.active {
            color: var(--Colors-Purple-400);
            border: 1px solid var(--Colors-Purple-400);
            background-color: rgba(250, 245, 255, 0.4);
            font-weight: 700;
          }

          &.off {
            background-color: var(--Colors-Slate-50);
            border: 1px solid var(--Colors-Slate-200);
            color: var(--Colors-Slate-400);
            font-weight: 400;
          }
        }
      }

      .more-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: var(--Radius-300);
        background-color: var(--Colors-Slate-200);
        ${typo.body02};
        font-weight: 700;
        color: var(--Colors-Slate-600);

        &:disabled {
          cursor: not-allowed;
        }
      }

      .confirm-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: var(--Radius-300);
        background-color: var(--Colors-Slate-800);
        ${typo.body02};
        font-weight: 700;
        color: #fff;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  `,

  Loading: styled.div`
    display: flex;
    gap: 8px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: var(--Radius-Circle);
      background-color: var(--Colors-Slate-300);

      &.one {
        animation: ${loadingAnimation} 1s infinite;
      }
      &.two {
        animation: ${loadingAnimation} 1s 0.2s infinite;
      }
      &.three {
        animation: ${loadingAnimation} 1s 0.4s infinite;
      }
    }
  `,

  ButtonBadge: styled.div`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: var(--Radius-100);
    ${typo.caption02};
    font-weight: 700;
  `,
}

const ButtonBadge = ({
  children,
  backgroundColor,
  color,
}: {
  backgroundColor: string
  color: string
} & DefaultProps) => {
  return (
    <S.ButtonBadge
      className="badge"
      style={{ backgroundColor, color }}
      css={css`
        background-color: ${backgroundColor};
        color: ${color};
      `}
    >
      {children}
    </S.ButtonBadge>
  )
}

type CTAProps = {
  onClick?: (type: string) => void
  disabled?: boolean
  isSelected?: boolean
  isOff: boolean
}

const 개념힌트 = ({ onClick, disabled, isSelected, isOff }: CTAProps) => {
  return (
    <button
      className={clsx('concept-hint btn', isSelected && 'active', isOff && 'off')}
      onClick={() => onClick?.('hint')}
      disabled={disabled || isOff}
    >
      <Icon
        name="icon_ai_tutor_blue"
        size={16}
        color={isOff ? 'var(--Colors-Slate-300)' : 'var(--Colors-Red-400)'}
      />
      개념을 모르겠어요
      <ButtonBadge
        backgroundColor={
          isOff
            ? 'var(--Colors-Slate-200)'
            : isSelected
              ? 'var(--Colors-Red-100)'
              : 'var(--Colors-Red-50)'
        }
        color={isOff ? 'var(--Colors-Slate-400,#94a3b8)' : 'var(--Colors-Red-400, #f87171)'}
      >
        개념힌트
      </ButtonBadge>
    </button>
  )
}

const 풀이분석 = ({ onClick, disabled, isSelected, isOff }: CTAProps) => {
  return (
    <button
      className={clsx('analyze btn', isSelected && 'active', isOff && 'off')}
      onClick={() => onClick?.('analyze')}
      disabled={disabled || isOff}
    >
      <Icon
        name="icon_ai_tutor_blue"
        size={16}
        color={isOff ? 'var(--Colors-Slate-300,#CBD5E1)' : 'var(--Colors-Blue-400, #60A5FA)'}
      />
      풀다가 막혔어요
      <ButtonBadge
        backgroundColor={
          isOff
            ? 'var(--Colors-Slate-200,#E2E8F0)'
            : isSelected
              ? 'var(--Colors-Blue-100,#DBEAFE)'
              : 'var(--Colors-Blue-50,#EFF6FF)'
        }
        color={isOff ? 'var(--Colors-Slate-400,#94a3b8)' : 'var(--Colors-Blue-400, #60A5FA)'}
      >
        풀이분석
      </ButtonBadge>
    </button>
  )
}

const 풀이확인 = ({ onClick, disabled, isSelected, isOff }: CTAProps) => {
  return (
    <button
      className={clsx('check btn', isSelected && 'active', isOff && 'off')}
      onClick={() => onClick?.('check')}
      disabled={disabled || isOff}
    >
      <Icon
        name="icon_ai_tutor_blue"
        size={16}
        color={isOff ? 'var(--Colors-Slate-300,#CBD5E1)' : 'var(--Colors-Purple-400,#C084FC)'}
      />
      제 풀이가 맞았나요?
      <ButtonBadge
        backgroundColor={
          isOff
            ? 'var(--Colors-Slate-200,#E2E8F0)'
            : isSelected
              ? 'var(--Colors-Purple-100,#F3E8FF)'
              : 'var(--Colors-Purple-50,#FAF5FF)'
        }
        color={isOff ? 'var(--Colors-Slate-400,#94a3b8)' : 'var(--Colors-Purple-400,#C084FC)'}
      >
        풀이확인
      </ButtonBadge>
    </button>
  )
}

const 답변준비중 = () => {
  return (
    <S.Loading>
      <div className="dot one" />
      <div className="dot two" />
      <div className="dot three" />
    </S.Loading>
  )
}

type MessageProps = {
  message: Message
  onClick?: (type: string) => void
  isClickable?: boolean
}

const StartMessageComponent = observer(({ message, onClick, isClickable }: MessageProps) => {
  return (
    <>
      <div className="message-wrapper">
        <div className="tutor-wrapper">
          <div className="tutor">
            <img src={`/images/icons/icon_ai_bot_main.svg`} alt="AI 튜터" width="34" height="34" />
          </div>
        </div>
        <div className="tutor-response-section">
          <div className="tutor-message">
            <div className="text-section" dangerouslySetInnerHTML={{ __html: message.comment }} />
            {message.isReplyCompleted && (
              <div className="sub-description">
                <span className="analyze">풀이분석</span>
                <span className="check">풀이확인</span>은 필기가 정확할수록 AI 튜터가 더 많은 도움을
                줄 수 있어요!
              </div>
            )}
          </div>
          {message.isReplyCompleted && (
            <div className="cta-section">
              <개념힌트
                onClick={onClick}
                disabled={!isClickable}
                isSelected={message.question instanceof ConceptHintQuestion}
                isOff={!commonRepo.studentAppSetting?.개념힌트_사용}
              />
              <풀이분석
                onClick={onClick}
                disabled={!isClickable}
                isSelected={message.question instanceof AnalyzeQuestion}
                isOff={!commonRepo.studentAppSetting?.풀이분석_사용}
              />
              <풀이확인
                onClick={onClick}
                disabled={!isClickable}
                isSelected={message.question instanceof CheckQuestion}
                isOff={!commonRepo.studentAppSetting?.풀이확인_사용}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
})

const MiddleMessageComponent = observer(({ message, onClick, isClickable }: MessageProps) => {
  return (
    <div className="message-wrapper">
      <div className="tutor-wrapper">
        <div className="tutor">
          <img src={`/images/icons/icon_ai_bot_main.svg`} alt="AI 튜터" width="34" height="34" />
        </div>
      </div>
      <div className="tutor-response-section">
        <div className="tutor-message">
          <div className="text-section" dangerouslySetInnerHTML={{ __html: message.comment }} />
        </div>
        {message.isReplyCompleted && (
          <div className="cta-section">
            <개념힌트
              onClick={onClick}
              disabled={!isClickable}
              isSelected={message.question instanceof ConceptHintQuestion}
              isOff={!commonRepo.studentAppSetting?.개념힌트_사용}
            />
            <풀이분석
              onClick={onClick}
              disabled={!isClickable}
              isSelected={message.question instanceof AnalyzeQuestion}
              isOff={!commonRepo.studentAppSetting?.풀이분석_사용}
            />
            <풀이확인
              onClick={onClick}
              disabled={!isClickable}
              isSelected={message.question instanceof CheckQuestion}
              isOff={!commonRepo.studentAppSetting?.풀이확인_사용}
            />
          </div>
        )}
      </div>
    </div>
  )
})

const TextWithKatex = observer(({ message }: MessageProps) => {
  const lines = message.comment.split('\n')

  return lines.map((line, lineIndex) => {
    const parts = line.split(/(\$\$.*?\$\$|\$.*?\$|\\\(.*?\\\)|\*\*.*?\*\*)/)

    return (
      <div key={lineIndex}>
        {parts.map((part, index) => {
          if (part.startsWith('$') && part.endsWith('$')) {
            return <InlineMath key={index} math={part.replace(/\$/g, '')} />
          } else if (part.startsWith('\\(') && part.endsWith('\\)')) {
            return <InlineMath key={index} math={part.slice(2, -2)} />
          } else if (part.startsWith('\\')) {
            return <InlineMath key={index} math={part.slice(1)} />
          } else if (part.startsWith('**') && part.endsWith('**')) {
            if (part.includes('$')) {
              return (
                <strong key={index}>
                  <InlineMath key={index} math={part.replace(/\*\*|\$/g, '')} />
                </strong>
              )
            }

            return <strong key={index}>{part.replace(/\*\*/g, '')}</strong>
          } else if (part.startsWith('#')) {
            return <h3 key={index}>{part.replace(/#/g, '')}</h3>
          } else {
            return part
          }
        })}
        {!message.isReplyCompleted &&
          lineIndex === lines.length - 1 &&
          message.comment.length > 0 && (
            <div className="cursor">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
          )}
        {message.comment.length > 0 && <br />}
      </div>
    )
  })
})

const ConceptHintMessageComponent = observer(({ message, onClick, isClickable }: MessageProps) => {
  const aiTutorService = useRepository(AiTutorService)

  return (
    <div className="message-wrapper">
      <div className="tutor-wrapper">
        <div className="tutor">
          <img src={`/images/icons/icon_ai_bot_main.svg`} alt="AI 튜터" width="34" height="34" />
        </div>
      </div>
      <div className="tutor-response-section">
        <div className="tutor-message">
          <div className="text-section">
            <TextWithKatex message={message} />
            {!message.isReplyCompleted && message.comment.length < 1 && (
              <>
                <답변준비중 />
              </>
            )}
          </div>
          {message.isReplyCompleted &&
            aiTutorService.question instanceof ConceptHintQuestion &&
            aiTutorService.question.rawValue.conceptImageUrl && (
              <img
                src={aiTutorService.question.rawValue.conceptImageUrl}
                width="100%"
                alt="tutor"
              />
            )}
          {message.isReplyCompleted && (
            <div className="cta-section full-width">
              <button
                className="more-button"
                onClick={() => onClick?.('more')}
                disabled={!isClickable}
              >
                힌트가 더 필요해요
              </button>
              <button
                className="confirm-button"
                onClick={() => onClick?.('end')}
                disabled={!isClickable}
              >
                이해했어요!
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const AnalyzeMessageComponent = observer(({ message, onClick, isClickable }: MessageProps) => {
  return (
    <div className="message-wrapper">
      <div className="tutor-wrapper">
        <div className="tutor">
          <img src={`/images/icons/icon_ai_bot_main.svg`} alt="AI 튜터" width="34" height="34" />
        </div>
      </div>
      <div className="tutor-response-section">
        <div className="tutor-message">
          <div className="text-section">
            <TextWithKatex message={message} />
            {!message.isReplyCompleted && message.comment.length < 1 && (
              <>
                <답변준비중 />
              </>
            )}
          </div>
          {message.isReplyCompleted && (
            <div className="cta-section full-width">
              <button
                className="more-button"
                onClick={() => onClick?.('more')}
                disabled={!isClickable}
              >
                힌트가 더 필요해요
              </button>
              <button
                className="confirm-button"
                onClick={() => onClick?.('end')}
                disabled={!isClickable}
              >
                이해했어요!
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const CheckMessageComponent = observer(({ message, onClick, isClickable }: MessageProps) => {
  return (
    <div className="message-wrapper">
      <div className="tutor-wrapper">
        <div className="tutor">
          <img src={`/images/icons/icon_ai_bot_main.svg`} alt="AI 튜터" width="34" height="34" />
        </div>
      </div>
      <div className="tutor-response-section">
        <div className="tutor-message">
          <div className="text-section">
            <TextWithKatex message={message} />
            {!message.isReplyCompleted && message.comment.length < 1 && (
              <>
                <답변준비중 />
              </>
            )}
          </div>
          {message.isReplyCompleted && (
            <div className="cta-section full-width">
              <button
                className="more-button"
                onClick={() => onClick?.('more')}
                disabled={!isClickable}
              >
                힌트가 더 필요해요
              </button>
              <button
                className="confirm-button"
                onClick={() => onClick?.('end')}
                disabled={!isClickable}
              >
                이해했어요!
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
