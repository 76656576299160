import { css } from '@emotion/react'
import type { CSSProperties } from 'react'

import { zIndex } from '~/@common/styles'
import { keyframes } from '~/@common/styles/keyframes.ts'

export const kind = 'ms__FloatingLoading'
export const parts = {
  loader: `${kind}_loader`,
}

export type Props = {
  bg?: CSSProperties['background']
}
export const FloatingLoader = ({ bg = 'transparent' }: Props) => {
  return (
    <div data-kind={kind} css={_css} style={{ background: bg }}>
      <div className={parts.loader} />
    </div>
  )
}

const _css = css`
  position: absolute;
  inset: 0;
  z-index: ${zIndex.로더};
  border-radius: var(--Radius-100);

  .${parts.loader} {
    position: absolute;
    width: 80px;
    height: 80px;
    border: 3px solid rgba(255, 255, 255, 0.25);
    border-top-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--Radius-Circle);
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    -webkit-animation: ${keyframes.rotation} 0.8s ease infinite;
    animation: ${keyframes.rotation} 0.8s ease infinite;
  }
`
