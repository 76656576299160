import { Schema } from '@effect/schema'
import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import type { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import { observer } from 'mobx-react'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import Modal from '~/@common/ui/modal/Modal.tsx'
import ConceptChipDetail from '~/@pages/@common/(ConceptChip)/ConceptChipDetail/ConceptChipDetail.tsx'
import SmileChallengeHelpModal from '~/@pages/student/@widgets/SmileChallenge/SmileChallengeHelpModal.tsx'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

import { ConceptChipDetailModule } from '../../../@common/(ConceptChip)/ConceptChipDetail'
import SmileChallengeService from './SmileChallenge.service'

export const kind = 'ms__SmileChallenge' as const
export const parts = {
  title: `${kind}_title`,
  contents: `${kind}_contents`,
} as const

const achievementLevelParser = Schema.parseSync(ConceptChipDomain.LevelOfAchievement)
const difficultyLevelParser = Schema.parseSync(ConceptChipDomain.LevelOfDifficulty)

type Props = {
  curriculumKey?: string
}

const SmileChallenge = ({ curriculumKey }: Props) => {
  const learningProcessService = useRepository(LearningProcessService)
  const service = useRepository(SmileChallengeService)
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()
  const [isShowDrawer, setIsShowDrawer] = useState(false)

  const loadSmileChallenges = useCallback(() => {
    if (curriculumKey) {
      service.loadSmileChallenge(curriculumKey)
    }
  }, [curriculumKey, service])

  useLayoutEffect(() => {
    void loadSmileChallenges()
  }, [service, curriculumKey, loadSmileChallenges])

  const onClickConceptChip = async (params: StudentWorksheetApi.챌린지학습지조회.Params) => {
    try {
      const 챌린지학습 = await learningProcessService.챌린지학습_시작하기(params)

      if (챌린지학습.이어하기_여부) {
        modalService.openModal(
          <Modal.Confirm.Positive
            css={{ width: 266 }}
            cancel={{
              children: '새로하기',
              onClick: async () => {
                const url = await 챌린지학습.새로하기()
                navigate(url)
              },
            }}
            confirm={{
              children: '이어하기',
              onClick: async () => {
                const url = await 챌린지학습.이어하기()
                navigate(url)
                modalService.closeModal()
              },
            }}
          >
            <p>
              학습중이던 유형 챌린지가 있습니다.
              <br />
              이어서 하시겠습니까?
            </p>
          </Modal.Confirm.Positive>,
          {
            modalName: '유형 챌린지 이어하기',
          },
        )
        return
      }

      const url = await 챌린지학습.새로하기()
      navigate(url)
    } catch (e) {
      errorHandlerService.handle(e)
    }
  }

  const handleHelpModal = () => {
    if (isMobile) {
      setIsShowDrawer((value) => !value)
    } else {
      SmileChallengeHelpModal.open()
    }
  }

  return (
    <div css={_css} data-kind={kind}>
      <div className={parts.title}>
        <h3>스마일 챌린지</h3>
        <IconButton
          name="icon_refresh"
          iconSize={20}
          iconStyle={{ color: colors.gray.$900 }}
          onClick={loadSmileChallenges}
        />
        <IconButton name="icon_info_fill" iconSize={20} onClick={handleHelpModal} />
        {isMobile && (
          <SmileChallengeHelpModal
            isShowDrawer={isShowDrawer}
            onCloseDrawer={() => setIsShowDrawer(false)}
          />
        )}
      </div>

      <div className={parts.contents}>
        <h4>최고 등급 도전</h4>
        {service.smileChallenge?.strengths ? (
          <ConceptChipDetail
            dataCategory="최고 등급 도전"
            description={service.smileChallenge.strengths.conceptName}
            achievementLevel={achievementLevelParser(
              service.smileChallenge.strengths.levelOfAchievement,
            )}
            difficultyLevel={difficultyLevelParser(
              service.smileChallenge.strengths.levelOfConceptChip,
            )}
            onLearningStart={() => {
              onClickConceptChip({
                conceptChipIds: [service.smileChallenge!.strengths!.conceptChipId],
                littleChapterId: service.smileChallenge!.strengths!.littleChapterId,
                referenceId: service.smileChallenge!.strengths!.conceptChipId,
                referenceType: 'CONCEPT_CHIP',
              })
            }}
          />
        ) : (
          <ConceptChipDetail isEmpty={true} />
        )}
        {isMobile && (
          <div
            css={css`
              height: 1px;
              background-color: ${colors.gray.$200};
            `}
          ></div>
        )}
        <h4>취약 유형 탈출</h4>
        {service.smileChallenge?.weaknesses ? (
          <ConceptChipDetail
            dataCategory="취약 유형 탈출"
            description={service.smileChallenge.weaknesses.conceptName}
            achievementLevel={achievementLevelParser(
              service.smileChallenge.weaknesses.levelOfAchievement,
            )}
            difficultyLevel={difficultyLevelParser(
              service.smileChallenge.weaknesses.levelOfConceptChip,
            )}
            onLearningStart={() => {
              onClickConceptChip({
                conceptChipIds: [service.smileChallenge!.weaknesses!.conceptChipId],
                littleChapterId: service.smileChallenge!.weaknesses!.littleChapterId,
                referenceId: service.smileChallenge!.weaknesses!.conceptChipId,
                referenceType: 'CONCEPT_CHIP',
              })
            }}
          />
        ) : (
          <ConceptChipDetail isEmpty={true} />
        )}
      </div>
    </div>
  )
}
export default observer(SmileChallenge)

const _css = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  .${parts.title} {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;

    svg {
      color: ${colors.gray.$500};
    }

    h3 {
      color: ${colors.gray.$900};
      font-weight: ${fontWeight.bold};
      ${typo.heading05};
    }
  }

  .${parts.contents} {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    h4 {
      color: ${colors.gray.$900};
      font-weight: ${fontWeight.bold};
      ${typo.body02};
      margin-bottom: 14px;
    }
    h4 ~ h4 {
      margin-top: 20px;
    }
    .${ConceptChipDetailModule.parts.levelInfo} {
      align-items: flex-end;
    }
  }

  ${mediaQuery.underTablet} {
    .${parts.title} {
      margin-bottom: 10px;
    }

    .${parts.contents} {
      background-color: ${colors.white};
      border-radius: var(--Radius-300);
      h4 {
        padding: 10px 20px;
        margin: 0;
      }
      h4 ~ h4 {
        margin-top: 0;
      }
    }
  }
`
