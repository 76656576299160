import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import { Option, pipe } from 'effect'
import { observer, useLocalObservable } from 'mobx-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store.ts'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'
import ConceptChipOfDifficultyLevel from '~/@pages/student/challenge/@widget/ConceptChipOfDifficultyLevel/ConceptChipOfDifficultyLevel.tsx'

import ConceptualLearningStatus from '../ConceptualLearningStatus/ConceptualLearningStatus'

export const kind = 'ms__ConceptChipOfLittleChapter'

export const parts = {
  title: `${kind}_title`,
  contents: `${kind}_contents`,
  typeKind: `${kind}_type`,
  typeContents: `${kind}_typeContents`,
  startChallengeButton: `${kind}_startChallengeButton`,
} as const

export type Props = {
  middleChapterId: number
  littleChapterId: number
}

const ConceptChipOfLittleChapter = ({ middleChapterId, littleChapterId }: Props) => {
  const service = useRepository(ChallengeService)
  const { isMobile } = useStudentAppMediaQuery()

  const store = useRepository(ChallengeStore)

  const localStore = useLocalObservable(() => ({
    get firstItem() {
      return pipe(
        service.getIdFromTag('littleChapterId', littleChapterId),
        Option.flatMap((ids) => service.get(ids.values().next().value)),
        Option.match({
          onNone: () => null,
          onSome: (firstItem) => firstItem.value,
        }),
      )
    },
    itemsForDifficultyLevel(level: ConceptChipDomain.LevelOfDifficulty) {
      return service.getFilteredFromIntersectionTagArr([
        ['littleChapterId', littleChapterId],
        ['levelOfConceptChip', level],
      ])
    },
    handleClickConceptChip: (conceptChipId: number) => {
      store.setSelectedChallenge({ type: 'conceptChip', id: conceptChipId, middleChapterId })
    },
    handleClickLittleChapter: () => {
      if (!localStore.firstItem) return
      store.setSelectedChallenge({
        type: 'littleChapter',
        id: littleChapterId,
        middleChapterId,
      })
    },

    get isSelected() {
      return (
        store.selectedChallenge?.type === 'littleChapter' &&
        store.selectedChallenge.id === littleChapterId
      )
    },
    get 개념() {
      return this.itemsForDifficultyLevel(ConceptChipDomain.LEVEL_OF_DIFFICULTY['개념'])
    },
    get 기본() {
      return this.itemsForDifficultyLevel(ConceptChipDomain.LEVEL_OF_DIFFICULTY['기본'])
    },
    get 심화() {
      return this.itemsForDifficultyLevel(ConceptChipDomain.LEVEL_OF_DIFFICULTY['심화'])
    },
  }))

  if (!localStore.firstItem) return null

  if (localStore.기본.length === 0 && localStore.개념.length === 0 && localStore.심화.length === 0)
    return null

  return (
    <div css={_css}>
      <div className={parts.title} data-component={parts.title}>
        <p>{localStore.firstItem?.littleChapterName}</p>
        <button
          className={parts.startChallengeButton}
          data-selected={localStore.isSelected}
          onClick={localStore.handleClickLittleChapter}
        >
          취약유형 추천 학습
        </button>
      </div>
      <div className={parts.contents} data-component={parts.contents}>
        <ConceptualLearningStatus littleChapterId={littleChapterId} isMobile={isMobile} />

        {ConceptChipDomain.ORDER_LEVEL_OF_DIFFICULTY.map((level) => {
          const items = localStore[level]

          return (
            <ConceptChipOfDifficultyLevel
              key={level}
              difficultyLevel={level}
              items={items}
              handleChange={localStore.handleClickConceptChip}
            />
          )
        })}
      </div>
    </div>
  )
}
export default observer(ConceptChipOfLittleChapter)

const _css = css`
  border: 1px solid ${colors.gray.$300};
  border-radius: var(--Radius-300);

  .${parts.title} {
    display: flex;
    padding: 10px 12px 10px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.$300};
    color: ${colors.gray.$900};
  }

  .${parts.title} p {
    ${textEllipsis(1)};
    padding-right: 6%;
    flex-grow: 1;
  }

  .${parts.contents} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    & > * + * {
      border-left: 1px solid ${colors.gray.$300};
    }
  }

  .${parts.startChallengeButton} {
    border-radius: var(--Radius-200);
    min-width: unset;
    padding: 10px 24px;
    height: unset;
    background-color: ${colors.gray.$300};
    color: ${colors.gray.$800};
    font-weight: bold;
    ${typo.caption01};

    &[data-selected='true'] {
      background-color: ${colors.gray.$500};
    }
  }

  ${mediaQuery.underTablet} {
    background-color: ${colors.white};
    border: 0;
    border-radius: 0;

    .${parts.title} {
      padding: 14px 16px;
      flex-direction: column;
      gap: 6px;

      > p {
        align-self: start;
      }

      > button {
        width: 100%;
      }
    }
    .${parts.title} p {
      padding-right: 0;
    }
    .${parts.contents} {
      display: flex;
      flex-direction: column;
      & > * + * {
        border-left: 0;
        border-top: 1px solid ${colors.gray.$200};
      }
    }
    .${parts.startChallengeButton} {
      color: ${colors.gray.$700};
    }
  }
`
