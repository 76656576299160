import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  AutoMarkingContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 340px;
    position: relative;
    margin: 0 auto;

    .input-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 0 1 280px;
      max-width: 280px;
      ${typo.heading05};
      font-weight: bold;
      &:focus-within {
        border-color: ${colors.gray.$500};
      }
    }

    .auto-marking-input-prefix,
    .auto-marking-input-postfix {
      display: flex;
      flex: 0 0 auto;
    }

    math-field {
      flex: 1 1 auto;
      position: relative;
      width: 100%;
      &:hover {
        cursor: text;
      }

      --placeholder-color: #5c5c5c;
      --caret-color: #1a8ef9;
      --highlight: #94dcff4d;
      --contains-highlight: #94dcff4d;

      &:focus-within {
        outline: none;
      }

      &:host {
        background-color: red;
      }

      &::part(container) {
        width: 100%;
        max-width: 280px;
        padding: 11px 13px;
        border-radius: var(--Radius-100);
        border: 1px solid ${colors.gray.$400};
        outline: none;
        color: ${colors.gray.$900};
        overflow: hidden;
        -webkit-appearance: none; //아이패드 기본 input 스타일 삭제

        ${typo.caption01}
        max-height:24px;
        &::placeholder {
          ${typo.caption01};
          color: ${colors.gray.$500};
        }
        &:focus {
          border-color: ${colors.gray.$500};
        }
        min-height: auto;
        height: 18px;
      }
      &::part(content) {
        width: 100%;
        padding: 0;
      }
    }
    ${mediaQuery.underTablet} {
      width: 100%;
      gap: 10px;

      .input-wrapper {
        flex-grow: 1;
        max-width: calc(100% - 50px);
      }
      math-field::part(container) {
        max-width: 100%;
      }
    }
  `,
}
export default S
