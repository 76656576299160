import { css } from '@emotion/react'
import type { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import clsx from 'clsx'
import { getOrNull } from 'effect/Option'
import { noop } from 'lodash'
import { observer } from 'mobx-react'
import { useRef } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import CheckboxInput from '~/@common/ui/checkbox/CheckboxInput'
import ConceptChipAchievementLevel from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel/ConceptChipAchievementLevel.tsx'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'

import { CONCEPT_CHIP_DATA } from './ConceptChipSummaryHelpModal'

const conceptChipNameMap = new Map(CONCEPT_CHIP_DATA.map((item) => [item[0], item[1].name]))

export type Props = {
  levelOfAchievement: ConceptChipDomain.LevelOfAchievement
  isShowLabel?: boolean
}

export const kind = 'ms__ConceptChipSummaryItem'

const ConceptChipSummaryItem = ({ levelOfAchievement, isShowLabel }: Props) => {
  const service = useRepository(ChallengeService)
  const checkboxRef = useRef<HTMLInputElement>(null)
  const { isMobile } = useStudentAppMediaQuery()

  const items = getOrNull(service.getIdFromTag('levelOfAchievement', levelOfAchievement))
  const checked = service.hasLevelOfAchievementFilter(levelOfAchievement)

  const handleContainerClick = () => {
    if (items === null) {
      return
    }

    if (checked) {
      service.deleteLevelOfAchievementFilter(levelOfAchievement)
    } else {
      service.addLevelOfAchievementFilter(levelOfAchievement)
    }
  }

  return (
    <li
      css={_css}
      data-component={kind}
      className={clsx(checked && 'on')}
      onClick={handleContainerClick}
    >
      <ConceptChipAchievementLevel
        level={levelOfAchievement}
        checked={checked && !isShowLabel}
        onChange={noop}
        onClick={(e) => e.stopPropagation()}
        disabled={items === null}
      />
      {isShowLabel && <span className="label">{conceptChipNameMap.get(levelOfAchievement)}</span>}
      <span className="count">{items?.size ?? 0}개</span>
      {isMobile && (
        <CheckboxInput
          ref={checkboxRef}
          type="checkbox"
          checked={checked}
          onChange={noop}
          disabled={items === null}
          className="checkbox"
        />
      )}
    </li>
  )
}
export default observer(ConceptChipSummaryItem)

const _css = css`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  margin: 0 5px;

  &.on {
    border-radius: var(--Radius-100);
    background-color: rgba(57, 95, 226, 0.1);
  }

  .count {
    color: ${colors.gray.$600};
    ${typo.body02};
  }

  ${mediaQuery.underTablet} {
    gap: 0;
    height: 61px;
    margin: 0;
    padding: 0 24px;

    .label {
      margin-left: 6px;
      ${typo.body02};
      font-weight: ${fontWeight.bold};
    }

    .count {
      margin-left: auto;
    }
    .checkbox {
      margin-left: 20px;
      gap: 0;
    }
  }
`
