import { css } from '@emotion/react'

import { colors, typo } from '~/@common/styles'
import _internal from '~/@common/ui/Calendar/_internal.ts'

const { parts } = _internal

export default css`
  display: flex;
  border-radius: var(--Radius-300);
  flex-direction: column;
  max-width: 312px;
  max-height: 288px;
  padding: 8px 20px 32px 20px;

  .${parts.header} {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    color: ${colors.gray.$900};
    ${typo.body01};
    font-weight: bold;

    margin-bottom: 8px;
  }

  .${parts.weekDays} {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    color: ${colors.gray.$900};
    font-weight: bold;

    min-height: 32px;
    margin-bottom: 5px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .${parts.dateGrid} {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: ${colors.gray.$900};
    gap: 4px 8px;
  }

  .${parts.date} {
    position: relative;
    min-height: 32px;
    color: ${colors.gray.$900};

    &[data-outside='true'] {
      color: ${colors.gray.$500};
    }

    &[data-in-range='true'] {
      color: ${colors.blue.$500};
    }

    &[data-today='true'] {
      width: 32px;
      height: 32px;
      border-radius: var(--Radius-Circle);
      background-color: ${colors.gray.$300};
    }

    &[data-special='true']:after {
      content: '';
      position: absolute;
      left: calc(50% - 1.5px);
      bottom: 2px;

      width: 3px;
      height: 3px;
      background-color: ${colors.blue.$500};
      border-radius: var(--Radius-Circle);
    }

    &[data-selected='true'] {
      width: 32px;
      height: 32px;
      border-radius: var(--Radius-Circle);
      background-color: ${colors.blue.$500};
      color: white;
    }
  }
`
