import styled from '@emotion/styled'
import { ProblemDomain } from '@mathflat/domain/@entities'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import clsx from 'clsx'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, textEllipsis, tooltipContainerCss, typo } from '~/@common/styles'
import Katex from '~/@common/ui/Katex/Katex'
import Radio from '~/@common/ui/radio'
import Tooltip2 from '~/@common/ui/Tooltip2/Tooltip2'

import type { ProblemScoring } from '../../(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringCardStyle } from '../../(ProblemScoring)/ProblemScoringCard'
import { AnswerType, 일반채점입력Item } from '../(일반채점)/일반채점입력'

export type 채점결과_마킹Props = Pick<
  ProblemScoring<'WORKSHEET' | 'WORKBOOK'>,
  '채점결과' | '제출한답' | '문제정답타입' | 'isAutoScoring' | 'id'
>

// autoScored: boolean
// 일반채점 + 자동채점 케이스 둘 다 있음.
// 일반채점입력의 스타일을 그대로 가져다 쓰기 위해 라디오로 구현. 스타일 수정하기 위해서는 일반채점입력으로 가야됨.
export const 채점결과_마킹 = ({
  id,
  채점결과,
  isAutoScoring,
  제출한답,
  문제정답타입,
}: 채점결과_마킹Props) => {
  const { isMobile } = useStudentAppMediaQuery()

  return (
    <Radio.Group value={채점결과}>
      <S.채점결과_마킹Item
        className="scoring-result-marking-item"
        data-tooltip-id={`submitted-answer-${id}`}
      >
        <일반채점입력Item disabled value={채점결과} className="general-scoring-input-item">
          {isAutoScoring && !!제출한답 && (
            <>
              <div className="divider" />
              <div className="submitted-answer">
                {채점결과 === AnswerType.UNKNOWN
                  ? '모름'
                  : !!제출한답 && (
                      <>
                        <div
                          className="submitted-answer-known"
                          onClick={(e) => {
                            if (isMobile) {
                              // modalService.openModal(
                              //   <S.MobileModalContainer>
                              //     <SubmittedAnswer
                              //       제출한답={제출한답}
                              //       문제정답타입={문제정답타입}
                              //       ellipse={false}
                              //     />
                              //   </S.MobileModalContainer>,
                              //   { modalName: '제출한 답 모달', hasCloseButton: true },
                              // )
                            } else {
                              CustomEventService.tooltipOn.dispatch(`submitted-answer-${id}`, e)
                            }
                          }}
                        >
                          <label>제출한 답 :&nbsp;</label>
                        </div>

                        <채점결과_말풍선
                          id={`submitted-answer-${id}`}
                          value={채점결과}
                          문제정답타입={문제정답타입}
                          제출한답={제출한답}
                        />
                      </>
                    )}
              </div>
            </>
          )}
        </일반채점입력Item>
      </S.채점결과_마킹Item>
    </Radio.Group>
  )
}

// autoScored:true
// 자동채점 Only 케이스만 있음
export const 채점결과_말풍선 = ({
  id,
  value,
  제출한답,
  문제정답타입,
}: {
  value: ValueOf<typeof AnswerType>
  id: string
} & Pick<채점결과_마킹Props, '제출한답' | '문제정답타입'>) => {
  return (
    <Tooltip2.WhiteArea id={id} place="right-start">
      <S.채점결과_말풍선>
        <SubmittedAnswer
          제출한답={제출한답}
          문제정답타입={문제정답타입}
          ellipse={false}
          채점결과={value}
        />
      </S.채점결과_말풍선>
    </Tooltip2.WhiteArea>
  )
}

export const SubmittedAnswer = ({
  제출한답,
  문제정답타입,
  채점결과,
  className,
  ellipse = true,
}: Pick<채점결과_마킹Props, '제출한답' | '문제정답타입' | '채점결과'> & {
  ellipse?: boolean
  className?: string
}) => {
  if (!제출한답) return <></>

  if (채점결과 === ProblemDomain.SCORING_RESULT.모름) {
    return <S.UnknownAnswer>모름</S.UnknownAnswer>
  }

  if (문제정답타입 === 'SHORT_ANSWER') {
    return (
      <S.SubmittedAnswer className={clsx(ellipse && 'ellipse', className)}>
        <Katex>{제출한답}</Katex>
      </S.SubmittedAnswer>
    )
  }

  return (
    <>
      {제출한답.split(',').map((item) => (
        <S.AnswerCircle key={item}>{item}</S.AnswerCircle>
      ))}
    </>
  )
}

const S = {
  채점결과_마킹Item: styled.div`
    display: flex;

    width: 100%;
    height: 100%;

    ${typo.body01};
    .general-scoring-input-item label {
      display: flex;
      gap: 30px;
    }
    .divider {
      flex: 0 0 1px;
      height: 24px;
      background-color: ${colors.gray.$400};
    }
    .submitted-answer {
      height: 48px;
      max-width: 148px;

      display: flex;
      align-items: center;

      color: ${colors.gray.$900};
      ${typo.body01};

      .katex-html {
        ${typo.body01};

        color: inherit;
      }
    }
    .submitted-answer-known {
      display: flex;
      align-items: center;

      width: 100%;

      > * {
        cursor: pointer;
      }
      > label {
        width: 73px;
        white-space: nowrap;
      }
    }
  `,
  SubmittedAnswer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    flex-wrap: wrap;

    .katex .base,
    .katex .strut {
      display: inline;
    }

    > span {
      max-width: 100%;
    }

    &.ellipse {
      width: 100%;
      height: auto;
      max-height: 36px;
      ${textEllipsis(1)};
      white-space: nowrap;
      > span {
        display: contents;
      }
      .katex {
        display: block;
      }
      .katex .base {
        white-space: normal;
      }
      .katex-mathml {
        display: none;
      }

      .mord {
        display: contents;
        white-space: normal;
        word-break: break-all;
      }
    }
  `,
  // MobileModalContainer: ProblemScoringCardStyle.MobileModalContainer,
  채점결과_말풍선: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 250px;
    padding: 9px 0px;

    border-radius: var(--Radius-200);
    ${typo.caption01};

    .katex .base {
      white-space: unset;
      width: 100%;
    }
  `,
  UnknownAnswer: styled.span`
    font-weight: 400;
    ${typo.body01};
  `,
  AnswerCircle: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding-top: 1px;
    border: 1px solid ${colors.gray.$700};
    border-radius: var(--Radius-Circle);
    font-weight: normal;

    ${typo.body02};
    & + &:before {
      position: absolute;
      content: ',';
      left: -25%;
      bottom: -2px;
    }
    & + & {
      margin-left: 4px;
    }
  `,
  TooltipContainer: styled.div`
    ${tooltipContainerCss};
  `,
}
