import styled from '@emotion/styled'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'

import { CustomEventService } from '~/@common/services/event.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import { QuickScoringTemplate } from '~/@pages/@common/(QuickScoring)/QuickScoring.template'

type Props = {
  viewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  problemScoringColl?: ProblemScoringColl<'WORKBOOK'>
}

const StudentWorkbookDetailProblemList = ({ problemScoringColl, viewOption }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { disposer } = CustomEventService.alltooltipOff.listen(scrollRef.current)
    return () => {
      disposer()
    }
  }, [scrollRef, problemScoringColl, viewOption])

  if (!problemScoringColl || !viewOption) return null

  // 스스로 채점권한이 없고, 선생님이 이 페이지에서 한문제도 채점하지 않았을 때
  // 저작권이 있으면 문제라도 보여줄 수 있지만, 저작권이 없으면 문제도 못보여준다.
  // 따라서 다음의 empty case를 노출한다.
  if (viewOption.hasCopyRight && viewOption.채점불가능 && problemScoringColl.isNothingSubmitted) {
    return (
      <S.StudentWorkbookDetailProblemList className="empty">
        <div className="round">
          <Icon name="icon_lock" size={20} color={colors.white} />
        </div>
        정답이 비공개 되어있습니다.
        <br />
        정답 공개는 선생님에게 문의해주세요.
      </S.StudentWorkbookDetailProblemList>
    )
  }

  return (
    <S.StudentWorkbookDetailProblemList
      className={clsx('student-workbook-detail-problem-list-body')}
      ref={scrollRef}
    >
      <QuickScoringTemplate
        className="student-workbook-quick-scoring"
        viewOption={viewOption}
        problemScoringColl={problemScoringColl}
        type="WORKBOOK"
        score={Math.floor(
          (problemScoringColl.toCorrectArr.length / problemScoringColl.toArr.length) * 100,
        )}
        scoring={{
          total: problemScoringColl.toArr.length,
          correct: problemScoringColl.toCorrectArr.length,
          incorrect: problemScoringColl.toWrongArr.length,
          unknown: problemScoringColl.toUnknowonArr.length,
          none: problemScoringColl.toNoneArr.length,
        }}
      />
    </S.StudentWorkbookDetailProblemList>
  )
}

export default observer(StudentWorkbookDetailProblemList)

const S = {
  StudentWorkbookDetailProblemList: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.gray.$600};
    ${typo.caption01};
    min-height: 480px;
    height: 100%;

    ${mediaQuery.underTablet} {
      min-height: auto;
    }

    .round {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50px;
      height: 50px;
      border-radius: var(--Radius-Circle);

      background-color: ${colors.gray.$600};
      margin-bottom: 10px;
    }

    &.empty {
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }

    .problem-card-grid-container {
      overflow-y: auto;
    }
    .student-workbook-quick-scoring {
      overflow-y: hidden;
    }

    ${mediaQuery.underTablet} {
      height: auto;

      // 리스트 없는 경우에는 높이를 100%로 잡아줘야함.
      &:empty,
      &:has(.hasNotWrongOrUnknown) {
        height: 100%;
      }

      .student-workbook-quick-scoring {
        height: auto;
      }
    }
  `,
}
