import { useNavigate } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { colors } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

export const GuideVideoButton = () => {
  const navigate = useNavigate()
  const moveGuidePage = () => {
    navigate(routeName.guideVideo)
  }
  return (
    <IconButton
      name="icon_help_line"
      title="사용가이드 보러가기"
      iconSize={26}
      iconStyle={{ color: colors.gray.$600 }}
      size={42}
      style={style}
      onClick={moveGuidePage}
    />
  )
}
const style = {
  borderRadius: 'var(--Radius-200)',
  border: `1px solid ${colors.gray.$300}`,
}
