import type { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto.ts'
import clsx from 'clsx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useState } from 'react'
import WorkbookSearchModalEmptyList, {
  NO_SEARCH_RESULT,
} from 'src/@pages/@widgets/WorkbookSearchModal/EmptyList.tsx'
import { parts as WorkbookSearchModalParts } from 'src/@pages/@widgets/WorkbookSearchModal/index.ts'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button.tsx'
import Badge from '~/@common/ui/Badge/Badge.tsx'
import Checkbox from '~/@common/ui/checkbox'
import CheckboxItem from '~/@common/ui/checkbox/CheckboxItem.tsx'
import { Icon } from '~/@common/ui/Icon/Icon'
import {
  type SearchWorkbookType,
  WorkbookSearchService,
} from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'

const WorkbookSearchModalMain = () => {
  const service = useRepository(WorkbookSearchService)
  const { WORKBOOK_TYPES, WORKBOOK_TYPE_KR } = WorkbookSearchService
  const { isMobile } = useStudentAppMediaQuery()
  const [summaryOpenState, setSummaryOpenState] = useState({
    [WORKBOOK_TYPES[0]]: true,
    [WORKBOOK_TYPES[1]]: true,
    [WORKBOOK_TYPES[2]]: true,
  })

  useEffect(() => {
    if (!service) {
      return
    }
    service.fetchWorkbookForTypes()
  }, [service])

  const _store = useLocalObservable(() => ({
    get checkedWorkbookIdsFormatByString() {
      if (!service) return []

      return service.checkedWorkbookToArray
        .flatMap((workbookSet) => [...workbookSet.values()])
        .map((workbook) => String(workbook.id))
    },
  }))

  const handleWorkbookTypeToggle = (type: SearchWorkbookType) => {
    const state = summaryOpenState[type]
    const newState = {
      ...summaryOpenState,
      [type]: !state,
    }
    setSummaryOpenState(newState)
  }

  if (!service) return null

  if (isMobile && !service.hasFilteredWorkbooks) {
    return NO_SEARCH_RESULT
  }

  return (
    <main className={WorkbookSearchModalParts.main}>
      {!isMobile && (
        <nav>
          {WORKBOOK_TYPES.map((type) => (
            <Button
              key={type}
              className={clsx(service.selectedWorkbookType === type && 'active')}
              onClick={() => {
                service.setSelectWorkbookType(type)
              }}
            >
              {WORKBOOK_TYPE_KR[type]}
            </Button>
          ))}
        </nav>
      )}
      <section
        style={{
          borderBottomRightRadius: service.hasFilteredWorkbooks ? '0' : 'var(--Radius-300)',
        }}
      >
        {!isMobile && (
          <header>
            <div className="row">
              <div className="col1">선택</div>
              <div className="col2">교재명</div>
            </div>
          </header>
        )}
        {WORKBOOK_TYPES.map((workbookType) => {
          let workbookItems: WorkbookEntity.Workbook[] = []

          if (isMobile) {
            const workbookData = service.searchWorkbookMap.get(workbookType)
            if (workbookData) {
              workbookItems = [...workbookData.values()]
            }
          } else {
            workbookItems = service.selectedWorkbookList
          }

          const filteredWorkbookList = service.getFilteredWorkbookList(workbookType)
          const checkedWorkbookSize = service.getCheckedWorkbookSizeBy(workbookType)
          const isCheckedMaxSize = service.checkIsMaxSizeReached(workbookType)

          const activeType = isMobile ? workbookType : service.selectedWorkbookType

          return (
            <div
              key={workbookType}
              style={{
                flexGrow: !isMobile && workbookType === service.selectedWorkbookType ? 1 : 'unset',
              }}
            >
              {isMobile && (
                <button
                  className="summary"
                  onClick={() => handleWorkbookTypeToggle(workbookType)}
                  style={{
                    backgroundColor: summaryOpenState[workbookType]
                      ? colors.gray.$100
                      : colors.white,
                  }}
                >
                  <p>
                    {WORKBOOK_TYPE_KR[workbookType]}
                    <span
                      className={clsx(
                        'number-for-workbook-type',
                        isCheckedMaxSize && 'highlight',
                        checkedWorkbookSize && 'selected',
                      )}
                    >
                      {checkedWorkbookSize}
                    </span>
                    권
                  </p>
                  <Icon
                    name="icon_dropdown_arrow_up"
                    size={20}
                    color={summaryOpenState[workbookType] ? colors.gray.$800 : colors.gray.$500}
                    rotate={summaryOpenState[workbookType] ? 180 : 0}
                    className="arrow"
                  />
                </button>
              )}

              {((isMobile && summaryOpenState[workbookType]) ||
                (!isMobile && activeType === workbookType)) && (
                <main>
                  <WorkbookSearchModalEmptyList
                    workbookType={workbookType}
                    workbookList={workbookItems}
                    filteredWorkbookList={service.getFilteredWorkbookList(workbookType)}
                  />
                  <Checkbox.Group values={_store.checkedWorkbookIdsFormatByString}>
                    {filteredWorkbookList?.map((workbook) => {
                      const isDisabled = !service.checkWorkbook(activeType, workbook)
                      return (
                        <CheckboxItem
                          key={workbook.id}
                          onClick={() => {
                            if (isDisabled) return
                            service.toggleWorkbook(activeType, workbook)
                          }}
                        >
                          <div className={clsx('row', isDisabled && 'max-checked')}>
                            <div className="col1">
                              <Checkbox.GroupInput value={workbook.id} disabled={isDisabled} />
                            </div>
                            <div className="col2">
                              <p>{workbook.fulltitle}</p>
                              {'assigned' in workbook && workbook.assigned && (
                                <Badge theme="primaryBlue">출제됨</Badge>
                              )}
                            </div>
                          </div>
                        </CheckboxItem>
                      )
                    })}
                  </Checkbox.Group>
                </main>
              )}
            </div>
          )
        })}
      </section>
    </main>
  )
}

export default observer(WorkbookSearchModalMain)
