import 'simplebar-react/dist/simplebar.min.css'

import { css } from '@emotion/react'
import { makeUseSessionScrollRestore } from '@mathflat/design-system/@common/hooks/makeSessionScrollRestore.tsx'
import { kindSelector } from '@mathflat/design-system/@common/utils/dataSelector.ts'
import { isDevelopMode } from '@mathflat/shared/@common/utils/dev'
import { pipe } from 'effect'
import * as O from 'effect/Option'
import { observer, useLocalObservable } from 'mobx-react'
import { useLayoutEffect, useState } from 'react'
import SimpleBar from 'simplebar-react'

import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { errorHandlerService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { FloatingLoader } from '~/@common/ui/(Loader)/FloatingLoader.tsx'
import { ConceptChipDetailModule } from '~/@pages/@common/(ConceptChip)/ConceptChipDetail'
import type { WorkbookSearchModalOnSubmit } from '~/@pages/@widgets/WorkbookSearchModal'
import { WorkbookSearchService } from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service'
import {
  ChallengeStore,
  DEFAULT_WORKBOOK_SEARCH_TAB_TYPE,
} from '~/@pages/student/@common/store/Challenge.store.ts'
import SmileChallenge from '~/@pages/student/@widgets/SmileChallenge/SmileChallenge.tsx'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'
import ChallengeStartDrawer from '~/@pages/student/challenge/@widget/ChallengeStartDrawer/ChallengeStartDrawer.tsx'

import { ConceptualLearningService } from '../conceptual-learning/@widgets/service/ConceptualLearning.service'
import ChallengeFilters from './@widget/ChallengeFilters/ConceptChipFilters'
import ConceptChipOfMiddleChapter from './@widget/ConceptChipOfMiddleChapter/ConceptChipOfMiddleChapter'
import { EmptyCaseInChallenge } from './@widget/EmptyCaseInChallenge'

export const kind = 'ms__ChallengePage' as const

const scrollValueSessionKey = `${kind}/scrollValue`
const useStoreRestore = makeUseSessionScrollRestore(scrollValueSessionKey)

const ChallengePage = () => {
  useCallbackOnVisibilityChange()
  const challengeService = useRepository(ChallengeService)
  const challengeStore = useRepository(ChallengeStore)
  const conceptualLearningService = useRepository(ConceptualLearningService)
  const searchService = useRepository(WorkbookSearchService)
  const { isMobile } = useStudentAppMediaQuery()
  const [isReady, setIsReady] = useState(false)

  const { scrollRefCallback, innerRefCallback, innerStyle, setScroll } = useStoreRestore<
    HTMLElement,
    HTMLDivElement
  >()

  const localStore = useLocalObservable(() => ({
    scrollReset() {
      setScroll(0)
    },
    get middleChapterIds() {
      return pipe(
        challengeService.getFilteredIdMapFromKindTag('middleChapterId'),
        O.flatMap((ids) => {
          if (ids.size === 0) return O.none()
          return O.some([...ids.keys()])
        }),
      )
    },

    async onSearch(
      payload: Parameters<WorkbookSearchModalOnSubmit>[0],
    ): ReturnType<WorkbookSearchModalOnSubmit> {
      if (!commonRepo.studentId) {
        throw Error('studentId를 알 수 없습니다.')
      }

      const searchData = { ...payload }
      const isCurriculumChanged = payload.curriculumKey !== challengeStore.curriculumKey

      if (isCurriculumChanged) {
        searchData.workbookList = []
        searchData.selectedWorkbookType = DEFAULT_WORKBOOK_SEARCH_TAB_TYPE

        const storedWorkbooksByCurriculumKey =
          challengeStore.workbooksByCurriculumKey[payload.curriculumKey]

        if (storedWorkbooksByCurriculumKey) {
          searchData.workbookList = storedWorkbooksByCurriculumKey.workbooks
          searchData.selectedWorkbookType = storedWorkbooksByCurriculumKey.workbookType
        }
      }

      await challengeService.fetch({
        studentId: commonRepo.studentId,
        curriculumKey: searchData.curriculumKey,
        workbookIds: searchData.workbookList.map((workbook) => workbook.id),
      })

      this.scrollReset()

      challengeStore.setCurriculumWorkbooks({
        curriculumKey: searchData.curriculumKey,
        workbookType: searchData.selectedWorkbookType,
        workbooks: searchData.workbookList,
        searchText: searchData.searchText,
      })
      challengeStore.saveToChallengeRemoteStorage()
      searchService.init({
        selectedWorkbooks: searchData.workbookList,
        curriculumKey: searchData.curriculumKey,
        searchText: searchData.searchText,
        selectedWorkbookType: searchData.selectedWorkbookType,
      })
      searchService.unchanged()
    },

    async loadChallenges(studentId: string) {
      try {
        await challengeStore.loadFromChallengeRemoteStorage()

        if (challengeStore.curriculumKey) {
          searchService.init({
            selectedWorkbooks: challengeStore.workbooks,
            curriculumKey: challengeStore.curriculumKey,
            searchText: challengeStore.searchText ?? '교재・교과서 선택',
            selectedWorkbookType: challengeStore.selectedWorkbookType,
          })

          await challengeService.fetch({
            studentId: studentId,
            curriculumKey: challengeStore.curriculumKey,
            workbookIds: challengeStore.workbooks.map((workbook) => workbook.id),
          })
          await conceptualLearningService.loadConceptualWorksheets()
        }
        setIsReady(true)
      } catch (err) {
        errorHandlerService.handle(err)
      }
    },
  }))

  const resetWorkbookFilterOptions = () => {
    const curriculumKey = challengeStore.curriculumKey

    if (!curriculumKey) {
      throw new Error('curriculumKey가 없습니다.')
    }

    const payload = {
      curriculumKey,
      selectedWorkbookType: DEFAULT_WORKBOOK_SEARCH_TAB_TYPE,
      workbookList: [],
      searchText: '',
    }
    localStore.onSearch(payload)
    searchService.clear()
    searchService.unchanged()
  }

  useLayoutEffect(() => {
    if (!commonRepo.studentId) {
      return
    }

    localStore.loadChallenges(commonRepo.studentId).then(() => {
      if (isDevelopMode) {
        globalThis.challengeService = challengeService
        globalThis.challengeStore = challengeStore
      }
    })
  }, [localStore, challengeService, challengeStore])

  return (
    <div css={_css} data-component={kind}>
      {!isReady && <FloatingLoader bg={colors.white} />}
      {isMobile && <ChallengeFilters onSearch={localStore.onSearch} />}
      <div className="container" ref={isMobile ? scrollRefCallback : undefined}>
        <section className="challenge-main">
          {!isMobile && (
            <ChallengeFilters
              onSearch={localStore.onSearch}
              onWorkbookReset={resetWorkbookFilterOptions}
            />
          )}

          <article className="contents">
            <div className="inner-contents" style={innerStyle} ref={innerRefCallback}>
              {challengeService.isEmpty ? (
                <EmptyCaseInChallenge />
              ) : (
                <SimpleBar
                  style={{ maxHeight: '100%' }}
                  scrollableNodeProps={{ ref: !isMobile ? scrollRefCallback : undefined }}
                >
                  {O.match(localStore.middleChapterIds, {
                    onNone: () => <EmptyCaseInChallenge />,
                    onSome: (ids) =>
                      ids.map((id) => (
                        <ConceptChipOfMiddleChapter key={`${id}`} middleChapterId={id} />
                      )),
                  })}
                </SimpleBar>
              )}
            </div>
          </article>

          {/* drawers */}
          <ChallengeStartDrawer />
        </section>

        {challengeStore.curriculumKey && (
          <section className="sidebar">
            <SmileChallenge curriculumKey={challengeStore.curriculumKey} />
          </section>
        )}
      </div>
    </div>
  )
}

export default observer(ChallengePage)

export const _css = css`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;

  .container {
    width: 100%;
    display: flex;
    gap: 24px;
    z-index: 1; // iOS Safari 스크롤바 이슈
  }

  .challenge-main {
    position: relative;
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .sidebar {
    flex: 0 0 178px;
  }

  .contents {
    flex: 1;
    overflow-y: hidden;

    .inner-contents {
      position: relative;
      height: 100%;
    }
  }

  .challenge-to-littlechapter,
  .challenge-to-concept-chip {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 14px 16px 14px;

    .challenge-to-littlechapter-contents,
    .challenge-to-concept-chip-contents {
      max-width: 968px;
      margin: 0 auto;
      padding: 14px;
      background: ${colors.white};
      box-shadow: 0px 12px 16px 0px rgba(41, 42, 43, 0.1);
      border-radius: var(--Radius-300);
    }
    .challenge-to-littlechapter-contents {
      .challenge-start {
        flex: 1;
        align-self: end;
      }
      > p {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
    }

    .challenge-to-littlechapter-concepts {
      > .items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        max-width: 720px;

        gap: 10px;
        margin-right: 16px;
      }

      > .action {
        margin-left: auto;
        align-self: end;
      }

      ${kindSelector(ConceptChipDetailModule)} {
        border: 1px solid ${colors.gray.$300};
        border-radius: var(--Radius-300);
        overflow: hidden;
      }
      display: flex;
      align-items: center;
    }

    .challenge-to-concept-chip-contents {
      display: flex;
      align-items: center;

      button > b {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        padding-right: 20px;
      }

      .action {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }

    .challenge-start {
      margin-left: auto;
      white-space: nowrap;
      padding: 0 30px;
    }
  }
  &[data-checked='true'] {
    .challenge-to-learning-process {
      transform: translateY(0);
    }
  }

  ${mediaQuery.underTablet} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 16px 0;

    .container {
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 40px;
      height: 100%;
    }

    .challenge-main {
      order: 1;
      height: auto;
      flex-grow: 1;
      overflow: unset;
    }
    .contents {
      flex: unset;
      overflow: visible;

      .inner-contents {
        height: auto;
      }
    }
  }
`
