import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import {
  CONTENT_STATUS,
  CONTENT_STATUS_KO,
  MAAT_CONTENT_STATUS_KO,
} from '@mathflat/domain/@entities/(Content)/module'
import type { LearningContentStatus } from '@mathflat/domain/@entities/StudentWorksheet/api2'
import { observer } from 'mobx-react'
import { nanoid } from 'nanoid'
import { Fragment } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { activeCSS, colors, colorTheme, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import Table from '~/@common/ui/Table/Table'
import { StudentExamService } from '~/@pages/student/student-exam/$studentExamId/scoring/@service/StudentExamPage.service'
import MAATAlreadyCompleteModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/MAATAlreadyCompleteModal'
import StudentMAATExamModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/StudentMAATExamModal'

import { LearningProcessService } from '../../../learning-process/@widgets/service/LearningProcess.service'
import type {
  StudentExamWorksheetList,
  StudentWorksheetList,
} from '../../@common/model/(StudentWorksheetList)'
import type {
  StudentCustomWorksheetList,
  StudentSelfLearningWorksheetGroupList,
  StudentSelfLearningWorksheetList,
} from '../../@common/model/(StudentWorksheetList)'
import { S } from '../../@common/style/common.style'
import { CautionPopupContent } from '../../@common/ui/CautionPopupContent'
import LearningBadge from '../../@common/ui/LearningBadge'
import { LearningProgress } from '../../@common/ui/LearningProgress'
import SelfLearningWorksheetListModal from '../../@common/ui/SelfLearningListModal'
import { SelfLearningTitleTag } from '../../@common/ui/SelfLearningTitleTag'
import { WrongCorrectCount } from '../../@common/ui/WrongCorrectCount'
import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'
import { EmptyCaseInStudentWorksheetList } from '../EmptyCaseInStudentWorksheetList'
import { StudentWorksheetTableSkeleton } from './StudentWorksheetTableSkeleton'

export const STUDENT_WORKSHEET_TABLE_DESKTOP_RATIO = '30px 80px 80px auto 176px'
export const STUDENT_WORKSHEET_TABLE_UNDER_DESKTOP_RATIO = '30px 80px 80px auto 120px'

// 수경시
const isMAATStudentWorksheet = (studentWorksheet: StudentWorksheetList) => {
  return (studentWorksheet as StudentExamWorksheetList)?.exam?.examType === 'MAAT'
}

// 학력평가
const isMiddleSchoolAchievementEvaluation = (studentWorksheet: StudentWorksheetList) => {
  return (
    (studentWorksheet as StudentExamWorksheetList)?.exam?.examType ===
    'MIDDLE_SCHOOL_ACHIEVEMENT_EVALUATION'
  )
}

const isMAATUnstartedAndComplete = (studentWorksheet: StudentWorksheetList) => {
  return (
    isMAATStudentWorksheet(studentWorksheet) &&
    !(studentWorksheet as StudentExamWorksheetList)?.openDatetime &&
    studentWorksheet.getStatus() === CONTENT_STATUS.전체채점
  )
}

export const StudentWorksheetTable = observer(() => {
  const { uiState, beautifiedStudentWorksheetList, loadStudentWorksheetList } = useRepository(
    StudentWorksheetListService,
  )
  const studentExamService = useRepository(StudentExamService)
  const learningProcessService = useRepository(LearningProcessService)
  const { isTablet } = useStudentAppMediaQuery()

  const navigate = useNavigate()
  const ratio = isTablet
    ? STUDENT_WORKSHEET_TABLE_UNDER_DESKTOP_RATIO
    : STUDENT_WORKSHEET_TABLE_DESKTOP_RATIO

  const clickParentCard = async (studentWorksheet: StudentWorksheetList) => {
    if (isMAATStudentWorksheet(studentWorksheet)) {
      if (studentWorksheet.getStatus() === CONTENT_STATUS.채점전) {
        modalService.openModal(
          <StudentMAATExamModal
            title={studentWorksheet.getTitle()}
            studentExamId={studentWorksheet.id}
          />,
          { modalName: 'MAAT응시확인팝업' },
        )

        return
      }

      if (studentWorksheet.getStatus() === CONTENT_STATUS.일부채점) {
        const { currentDatetime, startDatetime, timeLimit } =
          await studentExamService.getRemainingTime(studentWorksheet.id)
        if (new Date(currentDatetime).getTime() < new Date(startDatetime).getTime() + timeLimit) {
          const searchParams = createSearchParams({
            title: studentWorksheet.getTitle(),
            size: 'all',
            problemIndex: '0',
            examType: 'MAAT',
          })
          navigate(
            `${generatePath(routeName.student.studentExamScoringById, {
              studentExamId: String(studentWorksheet.id),
            })}?${searchParams.toString()}`,
          )
          return
        }
      }

      modalService.openModal(<MAATAlreadyCompleteModal />, {
        modalName: 'MAAT제출완료팝업',
      })
      return
    }

    if (isMiddleSchoolAchievementEvaluation(studentWorksheet)) {
      await studentExamService.startExam(studentWorksheet.id)
    }

    if (
      (studentWorksheet as StudentCustomWorksheetList)?.worksheet?.accessModifierToStudent ===
      'PRIVATE'
    ) {
      modalService.openModal(
        <Modal.Alert
          confirm={{
            onClick: () => {
              modalService.closeModal()
            },
          }}
        >
          선생님이 비공개 한 학습지입니다.
          <br />
          공개하기 전까지는 볼 수 없어요!
        </Modal.Alert>,
        { modalName: '비공개 학습지 안내 모달' },
      )
      return
    }
    navigate(studentWorksheet.getRoute())
  }

  const confirmAssignSelfLearning = async (
    학습타입: '오답' | '심화',
    studentWorksheet: StudentWorksheetList,
  ) => {
    modalService.openModal(
      <Modal.Confirm.Positive
        confirm={{
          children: <p data-track={`${학습타입}학습만들기`}>만들기</p>,
          onClick: async () => {
            try {
              학습타입 === '심화'
                ? await learningProcessService.보충_심화학습지_생성({
                    studentWorksheetId: studentWorksheet.id,
                  })
                : await learningProcessService.보충_오답학습지_생성({
                    studentWorksheetId: studentWorksheet.id,
                  })

              await loadStudentWorksheetList()
              modalService.closeModal()
            } catch (e) {
              modalService.closeModal()
              modalService.openModal(
                <Modal.Alert
                  css={{ width: 307 }}
                  confirm={{
                    onClick: modalService.closeModal,
                    style: {
                      background: colorTheme.primary,
                    },
                    children: '확인',
                  }}
                >
                  <>
                    <Icon name="icon_notification" size={24} />
                    <p>
                      더 이상 풀 수 있는 문제가 없어요.
                      <br />
                      다른 보충학습을 시도해주세요!
                    </p>
                  </>
                </Modal.Alert>,
                {
                  modalName: '학습 불가',
                },
              )
            }
          },
        }}
      >
        {학습타입} 보충 학습지를 만들까요?
      </Modal.Confirm.Positive>,
      {
        modalName: 학습타입 + '학습 시작',
      },
    )
  }

  const navigateSelfLearningWorksheet = async (selfLearning: StudentSelfLearningWorksheetList) => {
    const url = await selfLearning.getSelfLearningRoutePath(
      learningProcessService,
      selfLearning.id.toString(),
    )
    navigate(url)
  }

  const clickSelfLearningTableRow = async (selfLearning: StudentSelfLearningWorksheetGroupList) => {
    if (selfLearning.자기주도_그룹_여부) {
      modalService.openModal(
        {
          content: (
            <SelfLearningWorksheetListModal
              selfLearningGroup={selfLearning}
              onClick={(s) => {
                navigateSelfLearningWorksheet(s)
                modalService.closeModal()
              }}
            />
          ),
          header: selfLearning.labelSelfLearning.worksheet.title,
        },
        {
          modalName: '보충_오답_팝업',
          hasCloseButton: true,
          headerOption: { position: 'left', hasBottomBorder: false },
        },
      )
      return
    }

    navigateSelfLearningWorksheet(selfLearning.labelSelfLearning)
  }

  if (uiState.isFetching) {
    return <StudentWorksheetTableSkeleton />
  }

  return (
    <>
      <StudentWorksheetListTable>
        <Table.Header ratio={ratio + ' 186px'} className="header-row">
          <Table.HeaderItem />
          <Table.HeaderItem>출제일</Table.HeaderItem>
          <Table.HeaderItem>상태</Table.HeaderItem>
          <Table.HeaderItem className="학습지명">학습지명</Table.HeaderItem>
          <Table.HeaderItem>결과</Table.HeaderItem>
          <Table.HeaderItem className="보충학습">
            <span>보충학습</span>
            <button
              onClick={() => {
                modalService.openModal(<CautionPopup />, {
                  modalName: 'caution-popup',
                  hasCloseButton: true,
                })
              }}
            >
              <Icon name="icon_info_fill" size={18} color={colors.gray.$700} />
            </button>
          </Table.HeaderItem>
        </Table.Header>
        {!beautifiedStudentWorksheetList.length ? (
          <EmptyCaseInStudentWorksheetList />
        ) : (
          <Table.Body>
            {beautifiedStudentWorksheetList.map((studentWorksheet) => {
              return (
                <Fragment key={nanoid()}>
                  <DoubleRow>
                    <Table.Row
                      onClick={() => clickParentCard(studentWorksheet)}
                      ratio={ratio}
                      css={contentRowStyles}
                    >
                      <Table.Item
                        css={css`
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          ${typo.caption02}
                          color: ${colors.gray.$500};
                          font-weight: 700;
                        `}
                      >
                        <Icon
                          name={studentWorksheet.getIconName()}
                          size={20}
                          color={colors.gray.$500}
                        />
                        {studentWorksheet.checkIsExam() && <p>TEST</p>}
                      </Table.Item>
                      <Table.Item>{studentWorksheet.getFormattedAssignDatetime()}</Table.Item>
                      <Table.Item>
                        <RowStatus status={studentWorksheet.getStatus()}>
                          {isMAATStudentWorksheet(studentWorksheet)
                            ? isMAATUnstartedAndComplete(studentWorksheet)
                              ? '응시종료'
                              : MAAT_CONTENT_STATUS_KO[studentWorksheet.getStatus()]
                            : CONTENT_STATUS_KO[studentWorksheet.getStatus()]}
                        </RowStatus>
                      </Table.Item>
                      <Table.Item
                        css={[
                          titleItemStyles,
                          S.labelsStyles,
                          isMAATUnstartedAndComplete(studentWorksheet) &&
                            unStartedCompleteMAATTitleStyles,
                        ]}
                      >
                        <div className="title">{studentWorksheet.getTitle()}</div>
                        <div className="labels">
                          {studentWorksheet.getLabels().map((label) => (
                            <span key={nanoid()} className="label">
                              {label}
                            </span>
                          ))}
                          <span>{studentWorksheet.getProblemCount()}문제</span>
                        </div>
                        <div
                          css={css`
                            position: absolute;
                            top: -14px;
                            right: 12px;
                            display: flex;
                            gap: 6px;
                          `}
                        >
                          {studentWorksheet.checkIsSelfLearningCtaVisible()
                            ?.isWrongTypeSelfLearningCompleted && (
                            <LearningBadge type={WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습} />
                          )}
                          {studentWorksheet.checkIsSelfLearningCtaVisible()
                            ?.isLevelUpTypeSelfLearningCompleted && (
                            <LearningBadge type={WorksheetDomain.SELF_LEARNING_TYPE.심화학습} />
                          )}
                        </div>
                      </Table.Item>
                      <Table.Item>
                        <div>
                          {isMAATStudentWorksheet(studentWorksheet) ? (
                            <div
                              css={css`
                                color: ${colors.gray.$500};
                                ${typo.caption01}
                              `}
                            >
                              채점 비공개
                            </div>
                          ) : studentWorksheet.getStatus() === CONTENT_STATUS.전체채점 ? (
                            <div
                              css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 4px;
                              `}
                            >
                              <strong className="result__score gray800">
                                {studentWorksheet.getScore()}점
                              </strong>
                              <WrongCorrectCount
                                wrongCount={studentWorksheet.getWrongCount()}
                                correctCount={studentWorksheet.getCorrectCount()}
                              />
                            </div>
                          ) : (
                            <p
                              css={css`
                                color: ${colors.gray.$500};
                                ${typo.caption01}
                              `}
                            >
                              채점 전
                            </p>
                          )}
                        </div>
                      </Table.Item>
                    </Table.Row>
                    <Table.Row ratio="186px" css={selfLearningRowStyles}>
                      <Table.Item>
                        {studentWorksheet.checkIsSelfLearningCtaVisible() && (
                          <div className="table__actions">
                            <button
                              className="actions__education-button"
                              onClick={() => confirmAssignSelfLearning('오답', studentWorksheet)}
                              disabled={studentWorksheet
                                .checkIsSelfLearningCtaVisible()
                                ?.checkIsCtaDisabled(
                                  WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
                                )}
                            >
                              <Icon
                                name="icon_balloon_x"
                                size={12}
                                color={
                                  studentWorksheet
                                    .checkIsSelfLearningCtaVisible()
                                    ?.checkIsCtaDisabled(
                                      WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
                                    )
                                    ? colors.gray.$500
                                    : colors.gray.$700
                                }
                              />
                              <span>오답학습</span>
                            </button>
                            <button
                              className="actions__education-button"
                              onClick={() => confirmAssignSelfLearning('심화', studentWorksheet)}
                              disabled={studentWorksheet
                                .checkIsSelfLearningCtaVisible()
                                ?.checkIsCtaDisabled(WorksheetDomain.SELF_LEARNING_TYPE.심화학습)}
                            >
                              <Icon
                                name="icon_graph"
                                size={12}
                                color={
                                  studentWorksheet
                                    .checkIsSelfLearningCtaVisible()
                                    ?.checkIsCtaDisabled(
                                      WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
                                    )
                                    ? colors.gray.$500
                                    : colors.gray.$700
                                }
                              />
                              <span>심화학습</span>
                            </button>
                          </div>
                        )}
                      </Table.Item>
                    </Table.Row>
                  </DoubleRow>
                  {studentWorksheet.getSelfLearning()?.map((selfLearning) => (
                    <DoubleRow key={nanoid()}>
                      <Table.Row
                        onClick={() => clickSelfLearningTableRow(selfLearning)}
                        ratio={ratio}
                        css={contentRowStyles}
                      >
                        <Table.Item>
                          <Icon
                            name={selfLearning.getIconName()}
                            size={20}
                            color={colors.gray.$500}
                          />
                        </Table.Item>
                        <Table.Item>{selfLearning.getFormattedAssignDatetime()}</Table.Item>
                        <Table.Item>
                          <RowStatus status={selfLearning.getStatus()}>
                            {CONTENT_STATUS_KO[selfLearning.getStatus()]}
                          </RowStatus>
                        </Table.Item>
                        <Table.Item css={[titleItemStyles, S.labelsStyles]}>
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              gap: 10px;
                              ${mediaQuery.underDesktop} {
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 4px;
                              }
                            `}
                          >
                            <SelfLearningTitleTag
                              selfLearningType={
                                selfLearning.labelSelfLearning.worksheet.selfLearningType
                              }
                              titleTag={selfLearning.labelSelfLearning.worksheet.titleTag}
                            />
                            <span className="title">{selfLearning.getTitle()}</span>
                          </div>
                          <div className="labels">
                            {selfLearning.getLabels().map((label) => (
                              <span key={nanoid()} className="label">
                                {label}
                              </span>
                            ))}
                            {selfLearning.자기주도_그룹_여부 && (
                              <span>
                                {selfLearning.getTotalWorksheetCount().toLocaleString()}학습지
                              </span>
                            )}
                            <span>{selfLearning.getProblemCount().toLocaleString()}문제</span>
                            {selfLearning.자기주도_그룹_여부 && (
                              <LearningProgress learningProgress={selfLearning.진척도} />
                            )}
                          </div>
                        </Table.Item>
                        <Table.Item>
                          <div>
                            {isMAATStudentWorksheet(studentWorksheet) ? (
                              <div
                                css={css`
                                  color: ${colors.gray.$500};
                                  ${typo.caption01}
                                `}
                              >
                                채점 비공개
                              </div>
                            ) : selfLearning.labelSelfLearning.status ===
                              CONTENT_STATUS.전체채점 ? (
                              <div
                                css={css`
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  gap: 4px;
                                `}
                              >
                                <strong className="result__score gray800">
                                  {selfLearning.getScore()}점
                                </strong>
                                <WrongCorrectCount
                                  wrongCount={selfLearning.getWrongCount()}
                                  correctCount={selfLearning.getCorrectCount()}
                                />
                              </div>
                            ) : (
                              <p
                                css={css`
                                  color: ${colors.gray.$500};
                                  ${typo.caption01}
                                `}
                              >
                                채점 전
                              </p>
                            )}
                          </div>
                        </Table.Item>
                      </Table.Row>
                      <Table.Row ratio="186px" css={selfLearningRowStyles}>
                        <Table.Item>
                          <div className="table__actions">
                            {selfLearning.getStatus() === CONTENT_STATUS.전체채점 ? (
                              <p className="caption1 gray600">
                                {selfLearning.labelSelfLearning.worksheet.selfLearningType ===
                                  WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습 &&
                                  (studentWorksheet
                                    .checkIsSelfLearningCtaVisible()
                                    ?.checkIsCtaDisabled(
                                      WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
                                    ) || selfLearning.checkIsSelfLearningCompleted()
                                    ? '학습을 완료했어요.'
                                    : '선생님이 출제한 학습지의\n오답학습 버튼을 누르세요.')}
                                {selfLearning.labelSelfLearning.worksheet.selfLearningType ===
                                  WorksheetDomain.SELF_LEARNING_TYPE.심화학습 &&
                                  (studentWorksheet
                                    .checkIsSelfLearningCtaVisible()
                                    ?.checkIsCtaDisabled(
                                      WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
                                    ) || selfLearning.checkIsSelfLearningCompleted()
                                    ? '학습을 완료했어요.'
                                    : '선생님이 출제한 학습지의\n심화학습 버튼을 누르세요.')}
                              </p>
                            ) : (
                              <p className="caption1 gray600">학습을 진행하세요.</p>
                            )}
                          </div>
                        </Table.Item>
                      </Table.Row>
                    </DoubleRow>
                  ))}
                </Fragment>
              )
            })}
          </Table.Body>
        )}
      </StudentWorksheetListTable>
    </>
  )
})

const DoubleRow = styled.div`
  display: flex;
`

const unStartedCompleteMAATTitleStyles = css`
  .title,
  .labels {
    color: ${colors.gray.$500};
  }
`
const titleItemStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  padding-left: 20px;
  padding-right: 10px;

  .title {
    ${textEllipsis(1)};

    ${mediaQuery.underDesktop} {
      ${textEllipsis(2)};
    }
  }

  .tags {
    ${typo.caption01}
    color: ${colors.gray.$700};
  }
`

const contentRowStyles = css`
  padding-right: 0;
  width: 100%;
`

const selfLearningRowStyles = css`
  padding: 0;
  &:hover {
    background-color: inherit;
    cursor: default;
  }
`

const RowStatus = styled.p<{ status: LearningContentStatus }>`
  color: ${colors.gray.$600};
  ${({ status }) =>
    (status === 'INCOMPLETE' || status === 'PROGRESS') &&
    css`
      color: ${colors.blue.$500};
    `}
`

const StudentWorksheetListTable = styled(Table)`
  display: flex;
  overflow: hidden;
  background-color: white;
  width: 100%;
  height: 100%;

  .보충학습 {
    display: flex;
    justify-content: center;
    gap: 4px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
    }
  }
  .header-row tr {
    padding-right: 0;
  }
  .학습지명 {
    text-align: left;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 88px;
  }

  .table__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    height: 100%;
    cursor: default;

    .caption1 {
      white-space: pre-wrap;
    }
  }
  .actions__education-button {
    height: 30px;

    display: flex;
    align-items: center;
    padding: 0 11px;
    gap: 2px;

    border-radius: var(--Radius-100);
    border: 1px solid ${colors.gray.$400};
    background-color: ${colors.gray.$100};
    color: ${colors.gray.$900};
    ${typo.caption01}
    span {
      width: 45px;
    }

    :disabled {
      color: ${colors.gray.$500};
      cursor: not-allowed;
      svg {
        fill: ${colors.gray.$400};
        color: ${colors.gray.$500};
      }
    }
  }
  .table__icon-description {
    color: ${colors.gray.$500};
    ${typo.caption02}
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .gap-0\.5 {
    gap: 2px;
  }
  .caption1 {
    ${typo.caption01}
  }
  .gray500 {
    color: ${colors.gray.$500};
  }
  .gray600 {
    color: ${colors.gray.$600};
  }
  .gray700 {
    color: ${colors.gray.$700};
  }
  .gray800 {
    color: ${colors.gray.$800};
  }
  .gray900 {
    color: ${colors.gray.$900};
  }
  .clickable {
    ${activeCSS};
  }
  .row {
    display: flex;
    height: 70px;
    > td {
      padding-right: 0;
    }
  }
`

const CautionPopup = () => {
  return (
    <Modal.Confirm.Default
      title="안내"
      style={{
        width: '660px',
      }}
    >
      <div>
        <header>
          <h3
            css={css`
              text-align: center;
              color: ${colors.gray.$900};
              font-weight: 700;
              ${typo.body01}
            `}
          >
            오답학습과 심화학습에 대해 알려드려요
          </h3>
        </header>
        <div
          css={css`
            height: 470px;
            overflow: auto;
          `}
        >
          <CautionPopupContent />
        </div>
      </div>
    </Modal.Confirm.Default>
  )
}
