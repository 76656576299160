import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { colors } from '~/@common/styles'
import type { StrapiApi } from '~/@common/types/api/strapi'
import { Icon } from '~/@common/ui/Icon/Icon'

import ServerMaintenanceService from './@service/ServerMaintenance.service'

const ServerMaintenance = () => {
  const [serverMaintenanceData, setServerMaintenanceData] =
    useState<StrapiApi.ServerMaintenance | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const data = await ServerMaintenanceService.fetchServerMaintenanceData()
      setServerMaintenanceData(data)
    }

    fetchData()
  }, [])

  if (!serverMaintenanceData) return <></>

  return (
    <S.Container>
      <div className="header">
        <Icon name="icon_caution" color={colors.red.$400} size={40} />
        <div>현재 서버 점검중입니다</div>
      </div>
      <div className="header-description">
        <div>보다 나은 서비스 제공을 위한 점검 시간입니다.</div>
        <div>점검 시간은 변경될 수 있으니 참고해주시기 바라며,</div>
        <div>불편하시더라도 조금만 기다려주시면 감사드리겠습니다.</div>
      </div>
      <div className="contents-container">
        <div className="contents-title">서버 점검 일시</div>
        <div className="period">{serverMaintenanceData.period}</div>
        <div className="contents-title">점검 내용</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: serverMaintenanceData.content }}
        />
      </div>
    </S.Container>
  )
}
export default ServerMaintenance

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    font-family: 'Spoca Han Sans Neo';
    padding: 0 16px;
    background-color: #f0f2f4;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      gap: 8px;
      color: ${colors.gray.$900};
      > div {
        font-weight: 700;
      }
    }
    .header-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${colors.gray.$800};
      margin-top: 8px;
      font-size: 14px;
      gap: 2px;
    }
    .contents-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: ${colors.gray.$100};
      border-radius: var(--Radius-Circle);
      width: 100%;
      max-width: 1120px;
      padding: 24px;
      margin-top: 32px;
    }
    .contents-title {
      font-size: 14px;
      font-weight: 700;
      color: ${colors.gray.$800};
      margin-bottom: 8px;
    }
    .period {
      color: ${colors.gray.$900};
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .content {
      text-align: center;
      font-size: 14px;
      color: ${colors.gray.$700};
    }
  `,
}
