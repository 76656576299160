import { css } from '@emotion/react'
import { Link, type LinkProps } from 'react-router-dom'

import { type Priority, usePrevLinkTo } from '~/@common/services/(LastLocationManager)/hooks.ts'
import { activeCSS } from '~/@common/styles'

export type Props = Omit<LinkProps, 'to'> & {
  to?: LinkProps['to']
  period?: Priority
}

export const kind = `ms__PrevLink`

export default function PrevLink(props: Props) {
  const prevLinkTo = usePrevLinkTo()
  const applyTo = props.to ?? prevLinkTo

  return <Link data-component={kind} css={_css} {...props} to={applyTo} />
}

const _css = css`
  display: flex;
  align-items: center;
  border-radius: var(--Radius-100);
  ${activeCSS}
`
