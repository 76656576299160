import { css } from '@emotion/react'
import { type HandwrittenNoteTypes, ReadOnlyHandwrittenNote } from '@mathflat/handwritten-note'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import { type ComponentProps, type FC, useCallback } from 'react'
import { isTablet } from 'react-device-detect'

import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository'
import { webviewService, WorksheetProblemNoteService } from '~/@common/services'
import { colors, fontWeight, typo, zIndex } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

type ReadOnlyHandwrittenNoteProps = Pick<
  ComponentProps<typeof ReadOnlyHandwrittenNote>,
  'preventScale' | 'preventOverflow'
>

type Props = ReadOnlyHandwrittenNoteProps & {
  noteData: HandwrittenNoteTypes.NoteData
  problemImgUrl: string
  onClose: () => void
}

const ProblemNoteMobileViewer: FC<Props> = ({ problemImgUrl, noteData, onClose }) => {
  const { type: orientationType } = useOrientation()
  const noteService = useRepository(WorksheetProblemNoteService)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const switchOrientationOnWebview = useCallback(
    debounce(() => {
      if (orientationType === 'landscape') {
        webviewService.requestOrientation('portrait')
      } else {
        webviewService.requestOrientation('landscape')
      }
    }, 250),
    [orientationType],
  )

  const handleGuideClose = () => {
    noteService.setIsShowMobileOrientationGuide(false)
  }

  return (
    <div css={_Css}>
      <header>
        내 풀이
        <span className="btn-close">
          <IconButton name="icon_close" size={20} onClick={onClose} />
        </span>
      </header>
      <div className="note-container">
        {noteService.isShowMobileOrientationGuide && orientationType === 'portrait' && (
          <div className="viewer-guide">
            화면 회전 잠금을 해제한 후 가로로 돌리면
            <br className="responsible-break" /> 넓게 볼 수 있어요
            <span className="guide-btn-close">
              <IconButton name="icon_close" size={20} onClick={handleGuideClose} />
            </span>
          </div>
        )}
        {!noteService.isShowMobileOrientationGuide && !isTablet && (
          <button
            type="button"
            className="orientation-change-button"
            onClick={switchOrientationOnWebview}
          >
            <img
              src="/images/icons/icon_rotate_orientation.svg"
              alt="화면_전환_버튼"
              width="22"
              height="22"
            />
          </button>
        )}
        <ReadOnlyHandwrittenNote noteData={noteData} preventOverflow={true} fitMode="center">
          <img src={problemImgUrl} alt="문제 이미지" width={344} />
        </ReadOnlyHandwrittenNote>
      </div>
    </div>
  )
}

export default observer(ProblemNoteMobileViewer)

const _Css = css`
  z-index: ${zIndex.모달};
  position: fixed;
  top: var(--g-safe-area-top);
  left: var(--g-safe-area-left);
  right: var(--g-safe-area-right);
  bottom: var(--g-safe-area-bottom);
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};

  header {
    height: 61px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typo.body02};
    font-weight: ${fontWeight.bold};
    color: ${colors.gray.$900};

    .btn-close {
      position: absolute;
      right: 24px;
    }
  }

  .note-container {
    display: flex;
    flex-grow: 1;
    background-color: ${colors.gray.$300};
    position: relative;
    padding: 16px;

    .orientation-change-button {
      position: absolute;
      bottom: 32px;
      right: 24px;
      width: 40px;
      height: 40px;
      padding: 8px;
      background-color: ${colors.gray.$100};
      border: 1px solid ${colors.gray.$400};
      border-radius: var(--Radius-100);
      opacity: 0.8;
      z-index: 1;
    }
  }

  .viewer-guide {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--Radius-200);
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    padding: 16px 14px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.white};
    ${typo.body02};
  }

  @media (min-width: 480px) {
    .responsible-break {
      display: none;
    }
  }
`
