import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import { observer } from 'mobx-react'
import { type FC, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, elevation, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'

import { ClearSelectedFiltersIcon } from '../../../@common/ClearSelectedWorkbookButton'
import { ChallengeService } from '../../Challenge.service'
import ConceptChipSummaryHelpModal from '../ConceptChipSummaryHelpModal'
import ConceptChipSummaryItem from '../ConceptChipSummaryItem'

const title = '성취도 선택'

interface Props {
  isShow?: boolean
  onClose?: () => void
  className?: string
}

const ChallengeFiltersModal: FC<Props> = ({ isShow, onClose, className }) => {
  const service = useRepository(ChallengeService)
  const { isMobile } = useStudentAppMediaQuery()
  const [isShowGuide, setIsShowGuide] = useState(false)
  const { type } = useOrientation()

  if (!isShow) {
    return
  }

  const handleReset = () => {
    ConceptChipDomain.ORDER_LEVEL_OF_ACHIEVEMENT.forEach(service.deleteLevelOfAchievementFilter)
    if (onClose) {
      onClose()
    }
  }

  const handleInfoOpen = () => {
    if (isMobile) {
      setIsShowGuide(true)
    } else {
      modalService.openModal(
        {
          header: '성취도 컬러에 대해 알려드려요',
          content: <ConceptChipSummaryHelpModal onCloseDrawer={() => modalService.closeModal()} />,
        },
        {
          modalName: '챌린지_성취도선택모달',
          hasCloseButton: true,
          modalStyle: {
            padding: '0px',
          },
        },
      )
    }
  }

  const content = (
    <section css={_css} className={className}>
      {!isMobile && (
        <header>
          <span className="title">{title}</span>
          <Icon
            name="icon_close"
            size={20}
            color={colors.gray.$900}
            className="icon-close"
            onClick={onClose}
          />
        </header>
      )}
      <p className="info">
        성취도 컬러가 무엇인가요?
        <IconButton
          name="icon_info_fill"
          size={20}
          iconStyle={{ color: colors.gray.$500 }}
          css={css`
            color: ${colors.gray.$600};
            ${typo.body02};
            gap: 2px;
          `}
          RightSlot="설명보기"
          onClick={handleInfoOpen}
        />
      </p>

      <ul className="list">
        {ConceptChipDomain.ORDER_LEVEL_OF_ACHIEVEMENT.map((level) => (
          <ConceptChipSummaryItem key={level} levelOfAchievement={level} isShowLabel={isMobile} />
        ))}
      </ul>
      {isMobile && (
        <div className="reset">
          <ClearSelectedFiltersIcon onClick={handleReset} />
        </div>
      )}
    </section>
  )

  if (isMobile) {
    return isShowGuide ? (
      <ConceptChipSummaryHelpModal
        isShowDrawer={isShowGuide}
        onCloseDrawer={() => setIsShowGuide(false)}
      />
    ) : (
      <Drawer
        size={type === 'landscape' ? '596px' : 'auto'}
        isOpened={Boolean(isShow)}
        closeDrawer={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <Drawer.Content>
          <Drawer.Header>{title}</Drawer.Header>
          <Drawer.Body style={{ overflow: 'hidden' }}>{content}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }
  return content
}

export default observer(ChallengeFiltersModal)

const _css = css`
  background-color: #fff;
  border-radius: var(--Radius-300);
  ${elevation.$05};

  header {
    position: relative;
    padding: 20px 24px;
    ${typo.body02};
    font-weight: ${fontWeight.bold};
    color: ${colors.gray.$900};
    text-align: center;

    .icon-close {
      position: absolute;
      right: 24px;
      top: 50%;
      margin-top: -10px;
    }
  }
  .info {
    padding: 14px 24px;
    background-color: ${colors.gray.$100};
    ${typo.body02};
    color: ${colors.gray.$800};
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid ${colors.gray.$100};
  }
  .list {
    padding: 30px 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  ${mediaQuery.underTablet} {
    width: 100%;
    border-radius: 0;
    position: unset;

    .info {
      justify-content: space-between;
      background-color: unset;
    }
    .list {
      display: block;
      padding: 0;
      border-bottom: 1px solid ${colors.gray.$100};
    }
    .reset {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 16px 40px;
    }
  }
`
