import styled from '@emotion/styled'

import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  Container: styled.div`
    display: flex;
    height: 100%;
    overflow: auto;
    width: 100%;

    ${mediaQuery.underTablet} {
      flex-direction: column;
      gap: 9.5px;
      padding: 10px 16px 0;
      height: calc(100% - 108px);
    }
  `,

  Scoring: styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    width: 100%;

    ${mediaQuery.underTablet} {
      padding-right: 0;
      width: 100%;
      padding-top: 10px;
    }

    .tooltip {
      padding: 12px 18px;
      border-radius: var(--Radius-Circle);
      border: 1px solid rgba(57, 95, 226, 0.2);
      background-color: ${colors.white};
      ${typo.body02};
      color: ${colors.gray.$900};
      z-index: 1;

      .react-tooltip-arrow {
        width: 11px;
        height: 11px;
        top: -6px !important;
        left: auto !important;
        right: 32px;
        transform: rotate(-135deg);
        border-right: 1px solid rgba(57, 95, 226, 0.2);
        border-bottom: 1px solid rgba(57, 95, 226, 0.2);
      }

      .tooltip-content {
        display: flex;
        align-items: center;
        gap: 14px;
        cursor: pointer;
      }
    }
  `,
  ScoringCard: styled.div`
    width: 100%;

    .scoring-card-container {
      display: flex;
      flex-direction: column;
      gap: 9.5px;
    }
  `,
  Table: styled.div`
    .concept-name {
      ${textEllipsis(1)};
    }
    .ellipse {
      width: 66px;
      height: 100%;
      ${textEllipsis(3)}

      .katex .base {
        display: contents;
        white-space: unset;
        ${typo.body02};
        line-height: 1;
      }
    }
  `,
  SubmitButton: styled.div`
    margin-top: 24px;

    ${mediaQuery.underTablet} {
      margin: 20px 16px 0;
    }
  `,
  AnswerCircle: styled.div`
    display: flex;
    align-items: flex-end;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding-top: 1px;
      border: 1px solid ${colors.gray.$700};
      border-radius: var(--Radius-Circle);
      font-weight: normal;
      ${typo.body02};
    }
    > p {
      margin-right: 5px;
    }
  `,
}

export default S
