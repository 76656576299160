import styled from '@emotion/styled'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import clsx from 'clsx'
import type { ReactNode } from 'react'

import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import { VerticalDivider } from '~/@pages/student/@widgets/VerticalDivider.tsx'
import type { AssignedStudentWorksheetHomeworksSelfLearnings } from '~/@pages/student/student-worksheet/@common/model'

type Props = {
  studentLearningContent: AssignedStudentWorksheetHomeworksSelfLearnings
  onClick: (selfLearningType: string, studentWorksheetId: number) => void
}

const SelfLearningWorksheetListModal = ({ studentLearningContent, onClick }: Props) => {
  const makeWorksheetTag = (item: {
    studentWorksheet: Omit<
      MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet,
      'worksheet'
    >
    worksheet: MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet['worksheet']
  }) => {
    const arr: ReactNode[] = []

    if (item.worksheet.autoScorable) {
      arr.push('자동채점')

      arr.push(`${item.worksheet.problemCount}문제`)
    }
    return arr.join(VerticalDivider)
  }

  return (
    <S.ModalWrapper className="modal-wrapper">
      <div className="info">
        <Icon name="icon_info_fill" size={20} />
        소단원 별로 학습할 수 있도록 학습지를 나누었어요. <br />
        합산된 점수는 모든 학습지를 다 풀면 결과 영역에서 볼 수 있어요.
      </div>
      <div className="count">
        <p>
          <strong>총 학습지 수</strong>
          {studentLearningContent.group.length}개
        </p>
        <p>
          <strong>총 문제 수</strong>
          {studentLearningContent.group.reduce((result, curr) => {
            return result + curr.worksheet.problemCount
          }, 0)}
          개
        </p>
      </div>
      <ul className="self-learning-list">
        {studentLearningContent.group.map((item, index) => (
          <li
            key={item.worksheet.id}
            onClick={() => onClick(item.worksheet.selfLearningType, item.studentWorksheet.id)}
          >
            <div className={clsx('list-number', item.studentWorksheet.status)}>{index + 1}</div>
            <div className="list-content">
              <div className={clsx('상태', item.studentWorksheet.status)}>
                {item.studentWorksheet.koStatus}
              </div>
              <div className="소단원명">
                <div className="title-top">{item.worksheet.chapter}</div>
                {makeWorksheetTag(item) !== '' && (
                  <div
                    className="title-bottom"
                    dangerouslySetInnerHTML={{
                      __html: makeWorksheetTag(item),
                    }}
                  />
                )}
              </div>
              <Icon name="icon_chevron_right" size={20} />
            </div>
          </li>
        ))}
      </ul>
    </S.ModalWrapper>
  )
}

export default SelfLearningWorksheetListModal

const S = {
  ModalWrapper: styled.div`
    width: 500px;
    padding: 0 24px 24px;

    ${mediaQuery.underTablet} {
      width: 100%;
      padding-top: 20px;
    }

    h3 {
      width: 100%;
      color: ${colors.gray.$900};
      font-weight: 700;
      ${typo.body01}
    }

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      background-color: ${colors.gray.$100};
      border-radius: var(--Radius-200);
      padding: 10px 12px;
      margin-bottom: 10px;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 10px 0;

      p {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      strong {
        color: ${colors.gray.$900};
        font-weight: 700;
        ${typo.body02};
      }
    }

    ul {
      height: 355px;
      overflow: scroll;
    }

    li {
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      cursor: pointer;

      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 15px;
          width: 1px;
          height: 30px;
          border-left: 1px dashed ${colors.gray.$500};
        }
      }

      .list-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: ${colors.gray.$200};
        border-radius: var(--Radius-100);
        padding: 10px;
        color: ${colors.gray.$700};
        ${typo.body02};

        &.INCOMPLETE,
        &.PROGRESS {
          background-color: ${colors.blue.$110};
          color: ${colors.blue.$500};
        }
      }

      .list-content {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid ${colors.gray.$200};
        padding: 20px 0;
      }

      .상태 {
        white-space: nowrap;
        &.COMPLETE {
          color: ${colors.gray.$600};
        }
        &.INCOMPLETE,
        &.PROGRESS {
          color: ${colors.blue.$500};
        }
      }

      .소단원명 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        margin: 0 auto 0 20px;

        .title-top {
          color: ${colors.gray.$900};
          ${textEllipsis(1)}
        }

        .title-bottom {
          color: ${colors.gray.$700};
          ${typo.caption01}
          ${textEllipsis(1)}
        }
      }
    }
  `,
}
