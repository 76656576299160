import { css } from '@emotion/react'
import type { HandwrittenNoteControllerService } from '@mathflat/handwritten-note'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import type { FC } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import HandwrittenNoteToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteToolbar'

import { StudentWorkbookViewerService } from '../../../@service/StudentWorkbookViewer.service'
import ImageViewerToolbarButton from './ImageViewerToolbarButton'
import ImageViewerToolbarPaging, {
  type ImageViewerToolbarPagingProps,
} from './ImageViewerToolbarPaging'

interface Props {
  isDragging?: boolean
  pagingProps?: ImageViewerToolbarPagingProps
  isWritingModeOnly?: boolean
  controllerService: HandwrittenNoteControllerService
  isDefaultNoteScale?: boolean
  onResetNoteScale?: () => void
}

const StudentWorkbookViewerToolbar: FC<Props> = ({
  isDragging,
  pagingProps,
  isWritingModeOnly,
  controllerService,
  isDefaultNoteScale,
  onResetNoteScale,
}) => {
  const viewerService = useRepository(StudentWorkbookViewerService)
  const { isWritingMode } = controllerService

  return (
    <div css={_Style} className={clsx(isDragging && 'dragging')}>
      <div className="box-container">
        {!isWritingModeOnly && (
          <div className="box control">
            <div>
              <Icon name="icon_drag_handle" css={{ width: 6, height: 10 }} />
            </div>
            {pagingProps && <ImageViewerToolbarPaging {...pagingProps} />}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              {viewerService.fitMode === 'fit-width' ? (
                <ImageViewerToolbarButton
                  iconName="icon_screen_fit"
                  iconSize={24}
                  size="md"
                  label="길이맞춤"
                  onClick={() => {
                    viewerService.setFitMode('fit-height')
                  }}
                />
              ) : (
                <ImageViewerToolbarButton
                  iconName="icon_width_fit"
                  iconSize={24}
                  size="md"
                  label="너비맞춤"
                  onClick={() => viewerService.setFitMode('fit-width')}
                />
              )}
              <div className="bar"></div>
              <ImageViewerToolbarButton
                disabled={isDefaultNoteScale}
                iconName="icon_reset-size"
                iconSize={24}
                label="비율초기화"
                onClick={() => onResetNoteScale?.()}
              />
            </div>
          </div>
        )}
        {!isWritingModeOnly && (
          <div className="box control" style={{ padding: 0 }}>
            <ImageViewerToolbarButton
              iconName="icon_list"
              iconSize={24}
              size="lg"
              label="답안입력"
              isOn={viewerService.isScoringMode}
              onClick={() => viewerService.setIsScoringMode(!viewerService.isScoringMode)}
            />
          </div>
        )}
        <div className="box control" style={{ padding: 0 }}>
          <ImageViewerToolbarButton
            iconName="icon_edit"
            iconSize={24}
            size="lg"
            label="필기"
            isOn={isWritingMode}
            onClick={() => {
              controllerService.setIsWritingMode(!isWritingMode)
            }}
          />
        </div>
      </div>
      {isWritingMode && (
        <HandwrittenNoteToolbar
          controllerService={controllerService}
          uiMode={isWritingModeOnly ? 'minimal' : 'normal'}
        />
      )}
    </div>
  )
}

export default observer(StudentWorkbookViewerToolbar)

const _Style = css`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4px;

  .box-container {
    display: flex;
    gap: 4px;
  }

  .box {
    border-radius: var(--Radius-300);
    border: 1px solid ${colors.gray.$400};
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  }

  &.dragging .box {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .control {
    display: flex;
    gap: 14px;
    white-space: nowrap;
    align-items: center;
    padding: 0 14px;
    align-items: center;

    .bar {
      width: 1px;
      height: 14px;
      background-color: ${colors.gray.$600};
      opacity: 0.4;
    }
  }
`
