import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type ChangeEvent, type FC, type FormEvent, useState } from 'react'
import { isIOS } from 'react-device-detect'

import { toastService } from '~/@common/services'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import ImageViewerToolbarButton from './ImageViewerToolbarButton'

export interface ImageViewerToolbarPagingProps {
  prevPageNumber: number | undefined
  nextPageNumber: number | undefined
  lastPageNumber: number | undefined
  pageNumber: number
  onPageNumberChange: (value: number | undefined) => boolean
}

const ImageViewerToolbarPaging: FC<ImageViewerToolbarPagingProps> = ({
  prevPageNumber,
  nextPageNumber,
  lastPageNumber,
  pageNumber,
  onPageNumberChange,
}) => {
  const [tempPageNumberText, setTempPageNumberText] = useState<string>()
  const maxPad = String(lastPageNumber).length

  const numberWithPad = (value: number) => String(value).padStart(maxPad, '0')

  const handleNumberInputFocus = () => {
    setTempPageNumberText(String(pageNumber))
  }
  const handleNumberInputBlur = () => {
    if (isIOS) {
      navigateToNewPageNumber()
    }

    setTempPageNumberText(undefined)
  }
  const handleNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setTempPageNumberText(value)
  }
  const handlePageNumberSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    navigateToNewPageNumber()
  }

  const navigateToNewPageNumber = () => {
    const newPageNumber = Number(tempPageNumberText?.replace(/^0+/, ''))

    if (newPageNumber === pageNumber) {
      return
    }

    let isValid = !Number.isNaN(newPageNumber)

    if (isValid) {
      isValid = onPageNumberChange(newPageNumber)
    }

    if (!isValid) {
      toastService.info('입력한 페이지는 이동할 수 없어요.', {
        allowMultipleToast: true,
        isMobile: true,
      })
      setTempPageNumberText(String(pageNumber))
    }
  }

  return (
    <div css={_Style}>
      <ImageViewerToolbarButton
        iconName="icon_chevron_left"
        iconSize={20}
        size="sm"
        label="이전"
        marginLeft={14}
        isHiddenLabel={true}
        disabled={!prevPageNumber}
        onClick={() => onPageNumberChange(prevPageNumber)}
      />
      <form className="page-indicator" onSubmit={handlePageNumberSubmit}>
        <input
          type="text"
          className="page-input"
          inputMode="numeric"
          maxLength={3}
          value={tempPageNumberText ?? numberWithPad(pageNumber)}
          onFocus={handleNumberInputFocus}
          onChange={handleNumberInputChange}
          onBlur={handleNumberInputBlur}
        />
        <span className="total-pages">/ {numberWithPad(lastPageNumber ?? 0)}</span>
      </form>
      <ImageViewerToolbarButton
        iconName="icon_chevron_right"
        iconSize={20}
        size="sm"
        label="다음"
        isHiddenLabel={true}
        disabled={!nextPageNumber}
        onClick={() => onPageNumberChange(nextPageNumber)}
      />
    </div>
  )
}

export default observer(ImageViewerToolbarPaging)

const _Style = css`
  display: inherit;
  align-items: inherit;

  .page-indicator {
    ${typo.body02};
    color: ${colors.gray.$900};
    display: flex;
    align-items: center;
    margin: 0 16px;

    .page-input {
      width: 64px;
      height: 40px;
      text-align: center;
      border-radius: var(--Radius-100);
      border: 1px solid ${colors.gray.$400};
      outline: none;
      color: ${colors.gray.$900};
      -webkit-appearance: none;

      &:focus {
        border-color: ${colors.gray.$500};
      }
    }

    .total-pages {
      margin-left: 10px;
      color: ${colors.gray.$700};
    }
  }

  ${mediaQuery.underTablet} {
    display: flex;
    height: 60px;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
  }
`
