import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'

export const S = {
  MobileContentsWrapper: styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding: 0 20px;
  `,
  Header: styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    ${typo.caption01}
    color: ${colors.gray.$800};

    .description {
      display: flex;
      align-items: center;
      gap: 2px;
      color: ${colors.gray.$600};
    }
  `,

  headerDivideLine: css`
    position: absolute;
    left: -16px;
    bottom: 0;
    height: 1px;
    width: 100vw;
    background-color: ${colors.gray.$300};
  `,

  Filters: styled.div`
    display: flex;
    justify-content: space-between;
    .filterOptionsButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      padding: 10px;
      border-radius: var(--Radius-300);
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    }
  `,
  OptionRow: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    .label {
      color: ${colors.gray.$800};
      font-weight: 700;
    }
  `,
  FooterButtons: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 14px 16px 40px;

    .cta-button__reset {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      color: ${colors.gray.$600};
    }
  `,
  CtaWrapper: styled.div`
    display: flex;
    background-color: #fff;
    border-top: 1px solid ${colors.gray.$200};
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      width: 100%;
      padding: 12px 10px;
      ${typo.caption01}
      color: ${colors.gray.$800};
      cursor: pointer;
      :not(:last-of-type) {
        border-right: 1px solid ${colors.gray.$200};
      }
      :disabled {
        color: ${colors.gray.$600};
        cursor: not-allowed;
      }
      :not(:disabled) {
        :active {
          background-color: ${colors.gray.$100};
        }
      }
    }
  `,
  labelsStyles: css`
    .labels {
      display: flex;
      gap: 13px;
      ${typo.caption01}
      color: ${colors.gray.$700};
    }
    .labels span {
      position: relative;
      :not(:last-of-type) {
        ::after {
          position: absolute;
          display: inline-block;
          content: '';
          width: 1px;
          height: 10px;
          margin: 0 6px;
          background-color: #c0c0c0;
          transform: translateY(50%);
        }
      }
    }
  `,
  cardStyles: css`
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  cardTopStyles: css`
    display: flex;
    align-items: center;
    gap: 6px;
    .icon {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${colors.blue.$500};
      ${typo.caption01}
      font-weight: 700;
    }
    .datetime {
      ${typo.caption01}
      color: ${colors.gray.$700};
    }
    .tags {
      display: flex;
      gap: 4px;
      .tag {
        padding: 4px;
        border-radius: var(--Radius-100);
        background-color: ${colors.gray.$200};
        color: ${colors.gray.$800};
        ${typo.caption02}
        line-height: normal;
      }
      .INCOMPLETE,
      .PROGRESS {
        background-color: ${colors.blue.$500};
        color: #ffffff;
      }
    }
  `,
  titleStyles: css`
    ${typo.body02}
    color: ${colors.gray.$900};
  `,
  cardBottomStyles: css`
    display: flex;
    gap: 4px;
  `,

  disabledStyles: css`
    color: ${colors.gray.$500};
  `,

  cardBottomProblemCountStyles: css`
    color: ${colors.gray.$700};
  `,
  cardBottomLeftStyles: css`
    display: flex;
    align-items: center;
    gap: 6px;
    ${typo.caption01}
    .vertical-divider {
      width: 1px;
      height: 10px;
      background-color: ${colors.gray.$500};
    }
    .point {
      color: ${colors.blue.$500};
    }
  `,
  cardBottomRightStyles: css`
    display: flex;
    align-items: center;
    gap: 6px;
    ${typo.caption01}
    color: ${colors.gray.$700};
    .wrapper {
      gap: 4px;
      align-items: center;
    }
  `,

  studentWorksheetMobileViewList: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 0;
    > li:last-of-type {
      padding-bottom: 40px;
    }
  `,
}
