import { css } from '@emotion/react'
import { type HTMLMotionProps, m, useAnimation, type Variants } from 'framer-motion'
import { type RefObject, useEffect } from 'react'
import * as React from 'react'
import { createPortal } from 'react-dom'
import type SimpleBarCore from 'simplebar-core'
import SimpleBar from 'simplebar-react'

import { colors, typo } from '~/@common/styles'
import { zIndex } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import { Backdrop as CommonBackdrop } from '~/@common/ui/(Backdrop)/Backdrop'

import { Icon } from '../../Icon/Icon'
import { DrawerContext, useDrawerContext } from './Drawer.context'
import type { Drawer as DrawerProps } from './Drawer.type'

/**
 * Drawer
 * @component
 * @example
 * const TestComponent = () => {
 *         const [isOpened2, setIsOpened2] = useState(false)
 *
 *        return (
 *             <div>
 *              <button
 *                onClick={() => {
 *                  console.log('열리면서 동작하는 함수입니다.')
 *                  setIsOpened2(true)
 *                 }}
 *              >
 *                Drawer 열기
 *              </button>
 *              <Drawer
 *                isOpened={isOpened2}
 *                onClose={() => {
 *                  console.log('닫히면서 동작하는 함수입니다.')
 *                  setIsOpened2(false)
 *                }}
 *                onAfterOpened={() => {
 *                  console.log('열린 이후 동작하는 함수입니다.')
 *                }}
 *                onAfterClosed={() => {
 *                  console.log('닫힌 이후 동작하는 함수입니다.')
 *                }}
 *                  size="400px"
 *               >
 *               <Drawer.Content>
 *                  <Drawer.Header>타이틀 입니다.</Drawer.Header>
 *                      <Drawer.Body>
 *                        <p>
 *                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
 *                          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
 *                          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
 *                          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
 *                          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
 *                          mollit anim id est laborum.
 *                        </p>
 *                      </Drawer.Body>
 *                      <Drawer.Footer>
 *                        Footer 입니다.
 *                      <Button
 *                         onClick={() => {
 *                           alert('저장되었습니다.')
 *                          setIsOpened2(false)
 *                         }}
 *                         >
 *                         저장하기
 *                       </Button>
 *                     </Drawer.Footer>
 *                  </Drawer.Content>
 *                 </Drawer>
 *               </div>
 * )}
 */
export const Drawer = ({
  size = 'auto', // 디자인 가이드 기준
  placement = 'bottom',
  rootSelector = '#drawer-root', // Drawer를 렌더링할 root selector
  position = 'fixed', // Drawer의 position
  backdropOpacity = 1, // 0 ~ 1 backdrop의 close기능은 유지하면서 보이지 않기 위한 props

  hasCloseButton = true,
  hasBorderRadius = true,
  hasPrevButton = false,
  showBackdrop = true,
  isOpened,
  openDrawer,
  closeDrawer,
  onClickPrev,
  onClosed,
  onOpened,
  children,
}: DrawerProps & DefaultProps) => {
  useEffect(() => {
    isOpened ? onOpened?.() : onClosed?.()
  }, [isOpened])

  if (!isOpened) return null

  return createPortal(
    <DrawerContext.Provider
      value={{
        size,
        isOpened,
        placement,
        openDrawer,
        closeDrawer,
        onClosed,
        onOpened,
        onClickPrev,
        hasCloseButton,
        hasBorderRadius,
        hasPrevButton,
        position,
      }}
    >
      {showBackdrop && (
        <CommonBackdrop style={{ opacity: backdropOpacity }} onClick={closeDrawer} />
      )}
      {children}
    </DrawerContext.Provider>,
    document.querySelector(rootSelector)!,
  )
}

const Content = ({ children, ...props }: HTMLMotionProps<'div'> & DefaultProps) => {
  const { isOpened, placement, size, hasBorderRadius, position, rootSelector } = useDrawerContext()
  const controls = useAnimation()

  const realSize = (() => {
    if (size === 'full') {
      if (placement === 'bottom' || placement === 'top') {
        return '100vh'
      }
      if (placement === 'left' || placement === 'right') {
        return '100vw'
      }
    }
    return size
  })()

  const drawerHorizontalPlacementCommonStyles = css`
    top: 0;
    height: calc(100vh - var(--g-safe-area-top) - var(--g-safe-area-bottom));
    height: calc(100dvh - var(--g-safe-area-top) - var(--g-safe-area-bottom));
    width: calc(${realSize} - var(--g-safe-area-left) - var(--g-safe-area-right));
    margin: var(--g-safe-area-top) var(--g-safe-area-right) var(--g-safe-area-bottom)
      var(--g-safe-area-left);
  `

  const drawerVerticalPlacementCommonStyles = css`
    left: 0;
    height: calc(min(100vh, ${realSize}) - var(--g-safe-area-top) - var(--g-safe-area-bottom));
    height: calc(min(100dvh, ${realSize}) - var(--g-safe-area-top) - var(--g-safe-area-bottom));
    width: calc(100vw - var(--g-safe-area-left) - var(--g-safe-area-right));
    width: calc(100dvw - var(--g-safe-area-left) - var(--g-safe-area-right));
    margin: var(--g-safe-area-top) var(--g-safe-area-right) var(--g-safe-area-bottom)
      var(--g-safe-area-left);
  `

  const placementCase: { styles: ReturnType<typeof css>; framerMotionVariants: Variants } = (() => {
    switch (placement) {
      case 'right':
        return {
          styles: css`
            ${drawerHorizontalPlacementCommonStyles}
            right: 0;

            ${size !== 'full' &&
            css`
              border-top-left-radius: var(--Radius-300);
              border-bottom-left-radius: var(--Radius-300);
            `}
          `,
          framerMotionVariants: {
            active: {
              x: 0,
            },
            inactive: {
              x: realSize,
            },
          },
        }
      case 'left':
        return {
          styles: css`
            ${drawerHorizontalPlacementCommonStyles}
            left: 0;

            ${size !== 'full' &&
            css`
              border-top-right-radius: var(--Radius-300);
              border-bottom-right-radius: var(--Radius-300);
            `}
          `,
          framerMotionVariants: {
            active: {
              x: 0,
            },
            inactive: {
              x: `-${realSize}`,
            },
          },
        }

      case 'top':
        return {
          styles: css`
            ${drawerVerticalPlacementCommonStyles}
            top: 0;

            ${size !== 'full' &&
            css`
              border-bottom-left-radius: var(--Radius-300);
              border-bottom-right-radius: var(--Radius-300);
            `}
          `,
          framerMotionVariants: {
            active: {
              y: 0,
            },
            inactive: {
              y: `-${realSize}`,
            },
          },
        }
      default:
        return {
          styles: css`
            ${drawerVerticalPlacementCommonStyles}
            bottom: 0;

            ${size !== 'full' &&
            css`
              border-top-left-radius: var(--Radius-300);
              border-top-right-radius: var(--Radius-300);
            `}
          `,
          framerMotionVariants: {
            active: {
              y: 0,
            },
            inactive: {
              y: realSize,
            },
          },
        }
    }
  })()

  useEffect(() => {
    controls.start(isOpened ? 'active' : 'inactive')
  }, [isOpened, controls])

  return (
    <m.div
      animate={controls}
      variants={placementCase.framerMotionVariants}
      transition={{ damping: 100 }}
      initial="inactive"
      css={[
        css`
          position: ${position};
          z-index: ${zIndex.drawer};
          display: flex;
          flex-direction: column;
          background-color: #fff;
        `,
        placementCase.styles,
        !hasBorderRadius &&
          css`
            border-radius: 0;
          `,
      ]}
      {...props}
    >
      {children}
    </m.div>
  )
}

const Header = ({
  children,
  hasBorderRadius = true,
  hasBorderBottom = true,
  ...props
}: { hasBorderRadius?: boolean; hasBorderBottom?: boolean } & DefaultProps) => {
  const { size, hasCloseButton, hasPrevButton } = useDrawerContext()

  const borderRadiusStyle = (() => {
    if (size === 'full' || !hasBorderRadius) {
      return css`
        border-radius: 0;
      `
    }
    return css`
      border-radius: var(--Radius-300) var(--Radius-300) 0 0;
    `
  })()

  return (
    <>
      <header
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 24px;
          text-align: center;
          ${borderRadiusStyle};
          ${typo.body02}
          color: ${colors.gray.$900};
          font-weight: 700;
        `}
        {...props}
      >
        {hasPrevButton && <Drawer.PrevButton />}
        <h2>{children}</h2>
        {hasCloseButton && <Drawer.CloseButton />}
      </header>
      {hasBorderBottom && <Divider />}
    </>
  )
}

const Info = ({ children }: DefaultProps) => {
  return (
    <div
      css={css`
        padding: 10px;
        background-color: ${colors.gray.$100};
        text-align: center;
        ${typo.caption01};
        color: ${colors.gray.$800};
      `}
    >
      {children}
    </div>
  )
}

const Body = ({
  children,
  className,
  style,
  css: cssProp,
  scrollRef,
}: DefaultProps & { css?: ReturnType<typeof css>; scrollRef?: RefObject<SimpleBarCore> }) => {
  return (
    <article
      style={style}
      className={className}
      css={[
        cssProp,
        css`
          background-color: #fff;
          overflow: hidden;
        `,
      ]}
    >
      <SimpleBar ref={scrollRef} style={{ maxHeight: '100%', height: '100%' }}>
        {children}
      </SimpleBar>
    </article>
  )
}

const Footer = ({ children }: DefaultProps) => {
  return (
    <footer
      css={css`
        width: 100%;
      `}
    >
      {children}
    </footer>
  )
}

const Divider = ({ color = colors.gray.$300 }: { color?: string }) => {
  return (
    <div
      css={css`
        width: 100%;
        height: 1px;
        background-color: ${color};
      `}
    />
  )
}

const CloseButton = ({ className, style, onClick }: { onClick?: () => void } & DefaultProps) => {
  const { closeDrawer } = useDrawerContext()

  return (
    <button
      type="button"
      className={className}
      style={style}
      css={css`
        position: absolute;
        top: 20px;
        right: 24px;
      `}
      onClick={() => {
        closeDrawer?.()
        onClick?.()
      }}
    >
      <Icon name="icon_close" size={20} color={colors.gray.$900} />
    </button>
  )
}

const PrevButton = ({ className, style, onClick }: { onClick?: () => void } & DefaultProps) => {
  const { onClickPrev } = useDrawerContext()

  return (
    <button
      type="button"
      className={className}
      style={style}
      css={css`
        position: absolute;
        top: 20px;
        left: 24px;
      `}
      onClick={() => {
        onClickPrev?.()
        onClick?.()
      }}
    >
      <Icon name="icon_chevron_left" size={20} color={colors.gray.$900} />
    </button>
  )
}

Drawer.Content = Content
Drawer.Header = Header
Drawer.Body = Body
Drawer.Info = Info
Drawer.Footer = Footer
Drawer.CloseButton = CloseButton
Drawer.Divider = Divider
Drawer.PrevButton = PrevButton
