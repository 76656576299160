import styled from '@emotion/styled'
import type { MathflatApi } from '@mathflat/domain/@entities/Login/api'
import { addMonths, endOfToday } from 'date-fns'
import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { authApi } from '~/@common/api'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery.ts'
import { toastService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service.ts'
import validationService from '~/@common/services/validation.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import CheckboxInputLabel from '~/@common/ui/checkbox/CheckboxInputLabel.tsx'
import { ValidationInput } from '~/@common/ui/ValidationInput'

type Props = {
  isInitial?: boolean
}

const PasswordChange = ({ isInitial = false }: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<MathflatApi.Request.Password>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordCheck: '',
    },
  })

  const { isMobile } = useStudentAppMediaQuery()

  const onSubmit: SubmitHandler<MathflatApi.Request.Password> = async (data) => {
    if (data.newPassword !== data.newPasswordCheck) {
      setError('newPasswordCheck', {
        message: '비밀번호가 일치하지 않습니다',
      })
      return
    }

    authApi
      .passwordChange(data)
      .then(() => {
        modalService.closeModal()
        toastService.success('비밀번호가 변경되었습니다.', { isMobile })
      })
      .catch((error) => {
        if (error.message === 'USER_WRONG_PASSWORD') {
          setError('oldPassword', {
            message: '비밀번호가 일치하지 않습니다',
          })
        }
      })
  }

  const handleDoNotShowAgainForThreeMonths = () => {
    const studentId = commonRepo.student?.id

    Cookies.set(`doNotShowAgainForThreeMonths-password_change-${studentId}`, 'true', {
      expires: addMonths(endOfToday(), 3),
    })
  }

  useEffect(() => {
    const checkBox = ref.current

    return () => {
      if (!isInitial || !checkBox?.checked) return

      handleDoNotShowAgainForThreeMonths()
    }
  }, [isInitial])

  return (
    <>
      <S.PasswordChange>
        <div className="password-change">
          <div className="guide-box">
            <p>초기 비밀번호는 123456 입니다.</p>
            <p>영문 대/소문자, 숫자, 특수 문자 중 2가지를 조합하여</p>
            <p>6자 이상 길이로 만들어주세요.</p>
          </div>
          <div className="input-field">
            <div className="field">
              <label>현재 비밀번호</label>
              <ValidationInput
                className="validation-input"
                placeholder="현재 비밀번호를 입력해주세요"
                {...register('oldPassword', {
                  required: validationService.requiredMessage,
                })}
                errorMessage={errors.oldPassword?.message}
                type="password"
              />
            </div>
            <div className="field">
              <label>새로운 비밀번호</label>
              <ValidationInput
                className="validation-input"
                placeholder="새로운 비밀번호를 입력해주세요"
                errorMessage={errors.newPassword?.message}
                type="password"
                {...register('newPassword', {
                  required: validationService.requiredMessage,
                  validate: validationService.password,
                })}
              />
            </div>
            <div className="field">
              <label>비밀번호 확인</label>
              <ValidationInput
                className="validation-input"
                placeholder="다시 한 번 입력해주세요"
                errorMessage={errors.newPasswordCheck?.message}
                type="password"
                {...register('newPasswordCheck', {
                  required: validationService.requiredMessage,
                })}
              />
            </div>
          </div>
          <div className="button-wrapper">
            <Button
              className="cancel"
              size="small"
              onClick={() => {
                modalService.closeModal()
              }}
            >
              취소
            </Button>
            <Button theme="primary" size="small" onClick={handleSubmit(onSubmit)}>
              변경 완료
            </Button>
          </div>
        </div>
      </S.PasswordChange>
      {isInitial && (
        <S.Bottom>
          <div className="option">
            <CheckboxInputLabel ref={ref} className="option">
              3개월간 보지 않기
            </CheckboxInputLabel>
          </div>
        </S.Bottom>
      )}
    </>
  )
}

export default PasswordChange

const S = {
  PasswordChange: styled.div`
    width: 432px;
    padding: 0 30px 30px;
    ${mediaQuery.underTablet} {
      padding: 0 20px 20px;
      width: 328px;
    }

    .password-change {
      display: flex;
      flex-direction: column;
      gap: 26px;
      overflow: auto;
      max-height: calc(100vh - 65px - 120px);
    }

    .guide-box {
      padding: 13px 12px;
      text-align: center;
      border-radius: var(--Radius-300);
      background: ${colors.gray.$100};
      color: ${colors.gray.$700};
      ${typo.caption01};
    }

    .ms__Input_input {
      padding: 9.5px 12px;
      color: ${colors.gray.$500};
    }

    .input-field {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }

    .field > label {
      display: inline-block;
      margin-bottom: 14px;
      color: ${colors.gray.$900};
      font-weight: bold;
    }

    .button-wrapper {
      display: flex;
      gap: 10px;

      button {
        width: 100%;
      }

      .cancel {
        background-color: ${colors.gray.$400};
        color: ${colors.gray.$800};
      }
    }
  `,

  Bottom: styled.div`
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(100%);
    z-index: 10000;

    .option {
      margin-right: auto;
      color: ${colors.mono.white};
    }
  `,
}
