import { css } from '@emotion/react'
import { endOfToday, format, nextSunday, previousSunday } from 'date-fns'
import Cookies from 'js-cookie'
import { observer } from 'mobx-react'
import { type FC, useEffect } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service.tsx'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import LastWeeklyInfoModal from '~/@pages/student/student-home/@widgets/WeeklyInfo/LastWeeklyInfoModal.tsx'

import WeeklyInfoService from '../../@service/WeeklyInfo.service'
import SectionHeader from '../SectionHeader'
import WeeklyInfoDoubleBox from './WeeklyInfoDoubleBox'
import WeeklyInfoSingleBox from './WeeklyInfoSingleBox'
import WeeklyInfoTooltip from './WeeklyInfoTooltip'

const WeeklyInfoSection: FC = () => {
  const { isMobile } = useStudentAppMediaQuery()
  const service = useRepository(WeeklyInfoService)
  const { weeklyInfo, isReady } = service
  const { studentId } = commonRepo

  useEffect(() => {
    const previousSundayDate = format(previousSunday(new Date()), 'yyyy-MM-dd')

    service.loadWeeklyInfo().catch(errorHandlerService.handle)
    service.loadLastWeeklyInfo(previousSundayDate).catch(errorHandlerService.handle)
  }, [service])

  if (
    service.lastWeeklyInfo &&
    service.lastWeeklyInfo.totalLearningTime >= 5 &&
    Cookies.get(`이번주_방문_여부-${studentId}`) !== 'TRUE'
  ) {
    modalService.openModal(<LastWeeklyInfoModal weeklyInfo={service.lastWeeklyInfo} />, {
      modalName: '지난주_학습정보',
      modalStyle: { padding: '0' },
    })
    Cookies.set(`이번주_방문_여부-${studentId}`, 'TRUE', { expires: nextSunday(endOfToday()) })
  }

  return (
    <section css={_Style}>
      <SectionHeader title="이번주 학습정보">
        <WeeklyInfoTooltip />
      </SectionHeader>
      <div className="content-group">
        <div className="single-box-group">
          <WeeklyInfoSingleBox
            type="learningTime"
            title="총 학습시간(시간:분)"
            borderRadiusType={isMobile ? 'none' : 'full'}
            value={weeklyInfo?.totalLearningTimeText}
          />
          {isMobile && isReady && <div className="devider"></div>}
          <WeeklyInfoSingleBox
            type="solvedCount"
            title="푼 문제 수"
            borderRadiusType={isMobile ? 'none' : 'full'}
            value={weeklyInfo?.problemsSolvedCount}
          />
        </div>
        <WeeklyInfoDoubleBox />
      </div>
    </section>
  )
}

export default observer(WeeklyInfoSection)

const _Style = css`
  .content-group {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .single-box-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .devider {
    width: 1px;
    height: 50px;
    background-color: ${colors.gray.$200};
  }

  ${mediaQuery.underTablet} {
    .content-group {
      gap: 10px;
    }
    .single-box-group {
      gap: 0;
      border-radius: var(--Radius-300);
      background: ${colors.white};
      overflow: hidden;
    }
  }
`
