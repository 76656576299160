import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useState } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button'
import type { CalendarDateRange } from '~/@common/ui/Calendar/Calendar'
import Calendar from '~/@common/ui/Calendar/Calendar'
import { Icon } from '~/@common/ui/Icon/Icon'
import Switch from '~/@common/ui/Switch/Switch'
import { ScoringGuide_ViewOption } from '~/@pages/@common/(Scoring)/ScoringGuide_ViewOption'

import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'

export const StudentWorksheetFilterOptions = observer(() => {
  const { uiState, dateRangeForDisplay, updateDateRange } = useRepository(
    StudentWorksheetListService,
  )

  const openCalendarModal = () => {
    modalService.openModal(
      <CalendarModal selectedRange={uiState.dateRange} onRangeSelect={updateDateRange} />,
      {
        modalName: 'calendar-modal',
        hasCloseButton: true,
      },
    )
  }

  return (
    <ScoringGuide_ViewOption
      wrapperStyle={{
        justifyContent: 'space-between',
      }}
      leftNodes={
        <>
          <button
            css={css`
              display: flex;
              align-items: center;
              gap: 6px;
              height: 100%;
              padding: 5px 10px;
              margin-left: -8px;
              color: ${colors.gray.$800};
              border-radius: var(--Radius-100);
              &:active {
                background-color: #e5e9ed;
              }
            `}
            onClick={openCalendarModal}
          >
            <p>{dateRangeForDisplay}</p>
            <Icon name="icon_calendar" size={20} />
          </button>
        </>
      }
      rightNodes={
        <div
          style={{
            display: 'flex',
            gap: '28px',
          }}
        >
          <Switch
            checked={uiState.isOnlyHomeWorkOptionToggled}
            onChange={() => uiState.toggle('_isOnlyHomeWorkOptionToggled')}
          >
            숙제만 보기
          </Switch>
          <Switch
            checked={uiState.isOnlyShowExamWorksheetOptionToggled}
            onChange={() => uiState.toggle('_isOnlyShowExamWorksheetOptionToggled')}
          >
            학력평가만 보기
          </Switch>
        </div>
      }
    />
  )
})

const CalendarModal = observer(
  ({
    selectedRange,
    onRangeSelect,
  }: {
    selectedRange: CalendarDateRange
    onRangeSelect: (newRange: CalendarDateRange) => void
  }) => {
    const [calendarDateRange, setCalendarDateRange] = useState(selectedRange)

    const selectLocalCalendarDateRange = (newRange: CalendarDateRange) => {
      setCalendarDateRange(newRange)
    }

    return (
      <div>
        <strong
          css={css`
            color: ${colors.gray.$900};
            ${typo.body01};
          `}
        >
          날짜 선택
        </strong>
        <Calendar
          style={{
            marginTop: '12px',
            width: '312px',
            height: '288px',
          }}
          type="range"
          selectedRange={calendarDateRange}
          onRangeSelect={selectLocalCalendarDateRange}
        />
        <Button
          size="small"
          css={css`
            width: 100%;
            border-radius: var(--Radius-200);
            color: ${colors.blue.$500};
            background-color: #e7eaf8;
            margin-top: 12px;
          `}
          onClick={() => {
            onRangeSelect(calendarDateRange)
            modalService.closeModal()
          }}
        >
          확인
        </Button>
      </div>
    )
  },
)
