import { css } from '@emotion/react'
import clsx from 'clsx'
import { differenceInCalendarDays } from 'date-fns'
import { observer } from 'mobx-react'
import type { FC, MouseEvent } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { createSearchParams, Link } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { studentWorkbookDetailPathParams } from '~/@common/constants/route'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, colorTheme, fontWeight, textEllipsis, typo } from '~/@common/styles'
import {
  studentEduMaterialStatusText,
  studentMAATEduMaterialStatusText,
} from '~/@common/types/studentEduMaterial'
import Badge, { type Props as BadgeProps } from '~/@common/ui/Badge/Badge'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { StudentExamService } from '~/@pages/student/student-exam/$studentExamId/scoring/@service/StudentExamPage.service'
import MAATAlreadyCompleteModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/MAATAlreadyCompleteModal'
import StudentMAATExamModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/StudentMAATExamModal'

import type RecentStudy from '../../@common/model/RecentStudy'
import { recentStudyTypeText } from '../../@common/types'

interface Props {
  item: RecentStudy
}

interface MetaItem {
  text: string
  isPrimaryTheme?: boolean
}

const StudyCard: FC<Props> = ({ item }) => {
  const isMAAT = item.examType === 'MAAT'
  const isMAATComplete = isMAAT && item.status === 'COMPLETE'
  const isMAATUnStartedAndComplete = isMAAT && !item.studentExamOpenDatetime && isMAATComplete
  // TODO: 다음번엔 서버에서 시간 받아서 받은 시간으로 사용하기
  const MAATUntilDay = differenceInCalendarDays(new Date('2024-12-19'), new Date())

  const service = useRepository(StudentExamService)
  const navigate = useNavigate()

  const textItems: MetaItem[] = []

  if (item.existsHomework) {
    textItems.push({ text: '숙제' })
  }

  if (item.workbookPagesText) {
    textItems.push({ text: item.workbookPagesText })
  }

  if (item.problemsCount > 0) {
    textItems.push({ text: `${item.problemsCount}문제` })
  }

  if (item.score !== undefined && !isMAAT) {
    textItems.push({ text: `점수 ${item.score}점`, isPrimaryTheme: true })
  }

  const progressText = (() => {
    if (isMAAT) {
      if (isMAATUnStartedAndComplete) {
        return '응시종료'
      }

      return studentMAATEduMaterialStatusText[item.status]
    }

    return studentEduMaterialStatusText[item.status]
  })()

  let progressColorTheme: BadgeProps['theme']

  switch (item.status) {
    case 'INCOMPLETE':
      progressColorTheme = 'primaryBlue'
      break
    case 'COMPLETE':
      progressColorTheme = 'secondaryGray'
      break
    case 'PROGRESS':
      progressColorTheme = 'primaryGray'
      break
  }

  const iconName = item.type === 'WORKBOOK' ? 'icon_book_read' : 'icon_description_paper'

  let href = '#'

  switch (item.type) {
    case 'WORKBOOK': {
      const path = item.isSignatureWorkbook
        ? routeName.student.studentWorkbookViewer
        : routeName.student.studentWorkbookDetail

      href = generatePath(path, {
        [studentWorkbookDetailPathParams.studentWorkbookId]: item.studentWorkbookId,
        [studentWorkbookDetailPathParams.revisionId]: item.studentWorkbookRevisionId,
        [studentWorkbookDetailPathParams.pageNumber]: item.studentWorkbookProgressPageNumber,
      })
      break
    }
    case 'WORKSHEET':
      href = generatePath(routeName.student.studentWorksheetById, {
        studentWorksheetId: String(item.studentWorksheetId ?? item.id ?? ''),
      })
      break
    case 'EXAM': {
      if (item.studentExamId === null) {
        break
      }
      if (item.examType === 'MAAT' && item.status === 'COMPLETE') {
        break
      }
      const isComplete = item.status === 'COMPLETE'
      const searchParams = createSearchParams({
        title: item.title,
        size: isComplete ? 'one' : 'all',
        problemIndex: isComplete && item.problemsCount ? String(item.problemsCount - 1) : '0',
        examType: item.examType === 'MAAT' ? 'MAAT' : 'EXAM',
      })
      href = `${generatePath(routeName.student.studentExamScoringById, {
        studentExamId: String(item.studentExamId),
      })}?${searchParams.toString()}`
    }
  }

  const handleClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    if (isMAAT) {
      e.preventDefault()

      if (!item.studentExamId) return

      if (item.status === 'INCOMPLETE') {
        modalService.openModal(
          <StudentMAATExamModal studentExamId={item.studentExamId} title={item.title} />,
          {
            modalName: 'MAAT응시확인팝업',
          },
        )
        return
      }

      if (item.status === 'PROGRESS') {
        const { currentDatetime, startDatetime, timeLimit } = await service.getRemainingTime(
          item.studentExamId,
        )
        if (
          new Date(currentDatetime).getMilliseconds() <
          new Date(startDatetime).getMilliseconds() + timeLimit
        ) {
          const searchParams = createSearchParams({
            title: item.title,
            size: 'all',
            problemIndex: '0',
            examType: 'MAAT',
          })
          navigate(
            `${generatePath(routeName.student.studentExamScoringById, {
              studentExamId: String(item.studentExamId),
            })}?${searchParams.toString()}`,
          )
          return
        }
      }

      modalService.openModal(<MAATAlreadyCompleteModal />, {
        modalName: 'MAAT제출완료팝업',
      })
      return
    }

    if (item.isPrivate) {
      e.preventDefault()
      modalService.openModal(
        <Modal.Alert
          confirm={{
            onClick: () => {
              modalService.closeModal()
            },
          }}
        >
          선생님이 비공개 한 학습지입니다.
          <br />
          공개하기 전까지는 볼 수 없어요!
        </Modal.Alert>,
        { modalName: '비공개 학습지 안내 모달' },
      )
    }
  }

  return (
    <li css={_Style} className={clsx(isMAAT && (isMAATComplete ? 'MAAT-complete' : 'MAAT-active'))}>
      <Link to={href} onClick={handleClick}>
        <p className="badges">
          <Icon name={iconName} size={12} css={{ color: colorTheme.primary }} />
          <p className="card-type">
            {recentStudyTypeText[item.type]}
            <span className="dot">・</span>
            {item.schoolTypeGrade}
            {item.revision === 'CURRICULUM_22' && '(22개정)'}
          </p>
          {item.isPrivate && <Badge>비공개</Badge>}
          <Badge theme={progressColorTheme}>{progressText}</Badge>
          {item.studentWorkbookRevisionRound && (
            <Badge>{item.studentWorkbookRevisionRound}회차</Badge>
          )}
          {item.isAutoScored && <Badge>자동 채점</Badge>}
          {isMAAT && !isMAATComplete && MAATUntilDay > 0 && (
            <Badge theme="secondaryBlue" className="margin-left-auto">
              {MAATUntilDay}일 남음
            </Badge>
          )}
        </p>
        <p className="title">{item.title}</p>
        {textItems.length > 0 && (
          <p className="meta">
            {textItems.map((item, index) => (
              <span
                key={index}
                className="meta-item"
                style={{
                  color: item.isPrimaryTheme ? colorTheme.primary : undefined,
                }}
              >
                {item.text}
              </span>
            ))}
          </p>
        )}
      </Link>
    </li>
  )
}

export default observer(StudyCard)

const _Style = css`
  background-color: ${colors.white};
  border-radius: var(--Radius-300);
  display: flex;
  flex-direction: column;
  gap: 6px;

  &.MAAT-active {
    border: 2px solid rgba(57, 95, 226, 0.5);
  }
  &.MAAT-complete {
    .title,
    .meta {
      color: ${colors.gray.$500};
    }
  }

  .margin-left-auto {
    margin-left: auto;
  }

  > a {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 17px 20px;
  }
  .title {
    width: 100%;
    ${textEllipsis(2)};
    color: ${colors.gray.$900};
  }
  .card-type {
    display: flex;
    align-items: center;
    ${typo.caption01};
    color: ${colors.blue.$500};
    font-weight: ${fontWeight.bold};

    .dot {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
    }
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .meta {
    ${typo.caption01};
    color: ${colors.gray.$700};
  }
  .meta-item {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 10px;
      margin: 0 6px;
      background-color: ${colors.gray.$500};
    }
    &:first-of-type:before {
      display: none;
    }
  }
`
