import { computed, makeObservable } from 'mobx'

import type { StudentEduMaterialStatus } from '~/@common/types'
import type { Worksheet } from '~/@common/types/Worksheet'

import type {
  Revision,
  SchoolType,
  Semester,
} from '@mathflat/domain/@entities/(SchoolSystem)/schema'
import { SCHOOL_TYPE_KO_SHORT } from '@mathflat/domain/@entities/(SchoolSystem)/SchoolType/constants'
import type { RecentStudyItem } from '../response'
import type { RecentStudyType } from '../types'

export default class RecentStudy {
  readonly type: RecentStudyType
  readonly examType: RecentStudyItem['examType']
  readonly id: number
  readonly datetime: string
  readonly status: StudentEduMaterialStatus
  private readonly _accessModifierToStudent: Worksheet['accessModifierToStudent']
  readonly isAutoScored: true
  readonly revision: Revision
  readonly school: SchoolType
  readonly grade: number
  readonly semester: Semester
  readonly title: string
  readonly existsHomework: boolean | null
  readonly isSignatureWorkbook: boolean | null
  readonly problemsCount: number
  private readonly _score: number
  private readonly _workbookPage: string | null
  readonly workbookThumbnailImageUrl: string | null
  readonly studentWorksheetId: number | null
  readonly studentWorkbookId: number | null
  readonly studentWorkbookProgressId: number | null
  readonly studentWorkbookRevisionId: number | null
  readonly studentWorkbookProgressPageNumber: number | null
  private readonly _studentWorkbookRevisionRound: number | null
  readonly studentExamId: number | null
  readonly studentExamOpenDatetime: string | null

  constructor(data: {
    type: RecentStudyType
    examType: RecentStudyItem['examType']
    id: number
    datetime: string
    status: StudentEduMaterialStatus
    accessModifierToStudent: Worksheet['accessModifierToStudent']
    isAutoScored: true
    title: string
    revision: Revision
    school: SchoolType
    grade: number
    semester: Semester
    existsHomework: boolean | null
    isSignatureWorkbook: boolean | null
    problemsCount: number
    score: number
    workbookPage: string | null
    workbookThumbnailImageUrl: string | null
    studentWorksheetId: number | null
    studentWorkbookId: number | null
    studentWorkbookProgressId: number | null
    studentWorkbookRevisionId: number | null
    studentWorkbookProgressPageNumber: number | null
    studentWorkbookRevisionRound: number | null
    studentExamId: number | null
    studentExamOpenDatetime: string | null
  }) {
    this.type = data.type
    this.examType = data.examType
    this.id = data.id
    this.datetime = data.datetime
    this.status = data.status
    this._accessModifierToStudent = data.accessModifierToStudent
    this.isAutoScored = data.isAutoScored
    this.revision = data.revision
    this.school = data.school
    this.grade = data.grade
    this.semester = data.semester
    this.title = data.title
    this.existsHomework = data.existsHomework
    this.isSignatureWorkbook = data.isSignatureWorkbook
    this.problemsCount = data.problemsCount
    this._score = data.score
    this._workbookPage = data.workbookPage
    this.workbookThumbnailImageUrl = data.workbookThumbnailImageUrl
    this.studentWorksheetId = data.studentWorksheetId
    this.studentWorkbookId = data.studentWorkbookId
    this.studentWorkbookProgressId = data.studentWorkbookProgressId
    this.studentWorkbookRevisionId = data.studentWorkbookRevisionId
    this.studentWorkbookProgressPageNumber = data.studentWorkbookProgressPageNumber
    this._studentWorkbookRevisionRound = data.studentWorkbookRevisionRound
    this.studentExamId = data.studentExamId
    this.studentExamOpenDatetime = data.studentExamOpenDatetime

    makeObservable<this>(this, {
      studentWorkbookRevisionRound: computed,
      workbookPages: computed,
      workbookPagesText: computed,
      score: computed,
      isPrivate: computed,
    })
  }

  get schoolTypeGrade() {
    if (this.school === 'HIGH') {
      return this.grade
    }

    if (this.semester) {
      return `${SCHOOL_TYPE_KO_SHORT[this.school]}${this.grade}-${this.semester}`
    }

    return `${SCHOOL_TYPE_KO_SHORT[this.school]}${this.grade}`
  }

  get studentWorkbookRevisionRound() {
    if (this.type === 'WORKBOOK' && this._studentWorkbookRevisionRound) {
      return this._studentWorkbookRevisionRound
    }
  }

  get workbookPages() {
    if (this.type === 'WORKBOOK' && this.existsHomework && this._workbookPage) {
      return this._workbookPage.split(',').map((v) => {
        const pages = v.split('~')
        if (pages.length > 1) {
          return [Number(pages[0]), Number(pages[1])]
        }
        return Number(pages[0])
      })
    }
  }

  get workbookPagesText() {
    if (!this.workbookPages?.length) {
      return
    }
    const text = this.workbookPages
      .map((v) => {
        if (Array.isArray(v)) {
          return `p${v[0]} - p${v[1]}`
        }
        return `p${v}`
      })
      .join(', ')
    return text
  }

  get score() {
    if (this.status === 'COMPLETE' && (this.type === 'EXAM' || this.type === 'WORKSHEET')) {
      return this._score
    }
  }

  get isPrivate() {
    return this._accessModifierToStudent === 'PRIVATE'
  }
}
