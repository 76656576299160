import { css } from '@emotion/react'
import { groupBy } from 'lodash'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { type MouseEvent, useLayoutEffect, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, colorTheme, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { IconButtonModule } from '~/@common/ui/(Button)/IconButton'
import Switch from '~/@common/ui/Switch/Switch.tsx'
import WorkbookSchoolCurriculumDropDown from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSchoolCurriculomDropDown'
import {
  type SearchWorkbookType,
  WorkbookSearchService,
} from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service'
import WorkbookSearchModal, {
  type WorkbookSearchModalOnSubmit,
} from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearchModal'
import {
  ChallengeStore,
  DEFAULT_WORKBOOK_SEARCH_TAB_TYPE,
} from '~/@pages/student/@common/store/Challenge.store.ts'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'

import ChallengeFilterButton from './ChallengeFilterButton'
import ChallengeFiltersModal from './ChallengeFiltersModal/ChallengeFiltersModal'

export const kind = 'ms__ConceptChipSummary' as const
export const parts = {
  filterLabel: `${kind}_filterLabel`,
  conceptChipList: `${kind}_conceptChipList`,
  workbookFilterButton: `${kind}_workbookFilterButton`,
  recommendSwitch: `${kind}_recommendSwitch`,
} as const

const { WORKBOOK_TYPES, WORKBOOK_TYPE_KR } = WorkbookSearchService

export type Props = {
  onSearch: WorkbookSearchModalOnSubmit
  onWorkbookReset?: () => void
}

const ChallengeFilters = (props: Props) => {
  const challengeService = useRepository(ChallengeService)
  const challengeStore = useRepository(ChallengeStore)
  const { isMobile } = useStudentAppMediaQuery()
  const [modalType, setModalType] = useState<'concept-chip' | 'workbook'>()

  const handleCurriculumKeyChange = async (curriculumKey: string) => {
    if (!props.onSearch) {
      return
    }

    const payload: Parameters<typeof props.onSearch>[0] = {
      curriculumKey,
      workbookList: [],
      searchText: '',
      selectedWorkbookType: DEFAULT_WORKBOOK_SEARCH_TAB_TYPE,
    }
    await props.onSearch(payload)
  }

  const getWorkbookFilterSize = (workbookType: SearchWorkbookType) => {
    const groupedWorkbookData = groupBy(challengeStore.workbooks, (item) => item.type)
    return groupedWorkbookData[workbookType]?.length ?? 0
  }

  const selectedWorkbookTypes = WORKBOOK_TYPES.filter((workbookType) =>
    getWorkbookFilterSize(workbookType),
  )
  const hasWorkbookTypesFilter = Boolean(selectedWorkbookTypes.length)

  const workbookFilterContent = selectedWorkbookTypes.map((workbookType, index) => {
    return (
      <span
        key={workbookType}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {index !== 0 && <span css={_DotCss}></span>}
        <span
          style={{
            color: colors.gray.$900,
          }}
        >
          {WORKBOOK_TYPE_KR[workbookType]}{' '}
        </span>
        <b
          style={{
            fontWeight: fontWeight.bold,
            color: colors.blue.$500,
            marginLeft: '3px',
          }}
        >
          {getWorkbookFilterSize(workbookType)}개
        </b>
      </span>
    )
  })

  const hasConceptChipTypes = Boolean(challengeService.filterHashtags.levelOfAchievement.size)
  const conceptChipFilterContent = hasConceptChipTypes ? (
    <>
      <span
        style={{
          color: colors.gray.$900,
        }}
      >
        성취도{' '}
      </span>
      <b
        style={{
          fontWeight: fontWeight.bold,
          color: colors.blue.$500,
        }}
      >
        {challengeService.filterHashtags.levelOfAchievement.size}개
      </b>
    </>
  ) : (
    '성취도 선택'
  )

  const handleWorkbookSelect = () => {
    if (!modalType) {
      if (challengeStore.curriculumKey) {
        modalService.openModal(
          <WorkbookSearchModal
            onSelectCurriculum={console.debug}
            onSubmit={(p) => props.onSearch(p)}
          />,
          {
            modalName: '챌린지_교재검색모달',
            hasCloseButton: false,
            modalStyle: {
              padding: '0px',
            },
          },
        )
      }
    } else if (modalType === 'workbook') {
      modalService.closeModal()
    }
  }

  const handleConceptChipSelect = () => {
    if (isMobile) {
      setModalType((value) => (value ? undefined : 'concept-chip'))
    } else {
      if (!modalType) {
        modalService.openModal(
          <ChallengeFiltersModal isShow={true} onClose={() => modalService.closeModal()} />,
          {
            modalName: '챌린지_성취도선택모달',
            hasCloseButton: false,
            modalStyle: {
              padding: '0px',
            },
          },
        )
      } else if (modalType === 'concept-chip') {
        modalService.closeModal()
      }
    }
  }

  const handleWorkbookClear = (e: MouseEvent<SVGSVGElement>) => {
    if (!hasWorkbookTypesFilter || !props.onWorkbookReset) {
      return
    }
    e.stopPropagation()
    props.onWorkbookReset()
  }

  const handleConceptChipTypesClear = (e: MouseEvent<SVGSVGElement>) => {
    if (!hasConceptChipTypes) {
      return
    }
    e.stopPropagation()
    challengeService.resetFilterHashtags()
  }

  useLayoutEffect(() => {
    const disposer = reaction(
      () => challengeStore.onlyRecommended,
      (value) => {
        if (value) {
          challengeService.addRecommendedFilter('추천')
        } else {
          challengeService.deleteRecommendedFilter('추천')
        }
      },
      { fireImmediately: true },
    )

    return disposer
  }, [challengeStore, challengeService])

  return (
    <div css={_css}>
      <WorkbookSchoolCurriculumDropDown onChange={handleCurriculumKeyChange} />
      <ChallengeFilterButton
        type="workbook"
        hasItems={hasWorkbookTypesFilter}
        isActive={modalType === 'workbook'}
        LeftSlot={hasWorkbookTypesFilter ? workbookFilterContent : '교재・교과서 선택'}
        onClick={handleWorkbookSelect}
        onIconClick={handleWorkbookClear}
      />
      <div
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <ChallengeFilterButton
          type="concept-chip"
          hasItems={hasConceptChipTypes}
          isActive={modalType === 'concept-chip'}
          LeftSlot={conceptChipFilterContent}
          onClick={handleConceptChipSelect}
          onIconClick={handleConceptChipTypesClear}
        />
        {isMobile && (
          <ChallengeFiltersModal
            isShow={modalType === 'concept-chip'}
            onClose={() => setModalType(undefined)}
          />
        )}
      </div>
      <Switch
        className={parts.recommendSwitch}
        onChange={() => challengeStore.toggleRecommended()}
        checked={challengeStore.onlyRecommended}
      >
        <span className={parts.filterLabel}>추천유형</span>
      </Switch>
    </div>
  )
}

export default observer(ChallengeFilters)

const _css = css`
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 2;

  .${parts.conceptChipList} {
    display: flex;
    align-items: center;
    margin-right: 6px;

    li:not(:last-child)::after {
      content: '';
      display: block;
      width: 1px;
      height: 12px;
      margin: 0 8px;
      background: ${colors.gray.$600};
    }
  }

  .${parts.recommendSwitch} {
    margin-left: auto;
    margin-right: 16px;
  }

  .${parts.workbookFilterButton} {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .${parts.filterLabel} {
    ${typo.body02};
    color: ${colors.gray.$700};
  }

  .${parts.workbookFilterButton} {
    gap: 4px;
    padding: 2px 4px;
    border-radius: var(--Radius-200);

    .${IconButtonModule.parts.icon} {
      padding: 4px;
    }

    &:active {
      background-color: ${colors.blue.$110};
      span {
        color: ${colorTheme.primary};
      }
    }

    &[data-apply='true'] {
      .${IconButtonModule.parts.icon} {
        background: ${colors.blue.$110};
        border-radius: var(--Radius-200);
      }
    }
  }

  ${mediaQuery.underTablet} {
    .${parts.recommendSwitch} {
      margin-right: 0;
    }
  }
`

const _DotCss = css`
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: var(--Radius-Circle);
  margin-right: 5px;
  background-color: ${colors.gray.$900};
`
