import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type FC, type ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import { StudentEduMaterialScoringDashboard } from '~/@pages/@common/StudentEduMaterialScoringDashboard'
import { WorksheetScoringCardMobile } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/WorksheetScoringCard.mobile'
import ProblemScoringResultTable from '~/@pages/student/learning-process/@common/ProblemScoringResultTable'

import type { WorksheetWithProblems } from '../service/ConceptualLearning.service'
import CorrectAnswerImageModal from './CorrectAnswerImageModal'

interface Props {
  worksheetWithProblems: WorksheetWithProblems
  cardTitleNode: ReactNode
}

const ConceptualLearningResult: FC<Props> = ({ worksheetWithProblems, cardTitleNode }) => {
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()
  const [correctAnswerImageUrl, setCorrectAnswerImageUrl] = useState<string>()
  const { problemScoringColl, studentWorksheet, worksheet, problemScoringViewOption } =
    worksheetWithProblems

  if (!problemScoringColl || !studentWorksheet || !worksheet || !problemScoringViewOption) {
    return null
  }

  const problemScoringCollArr = problemScoringColl.toArr

  const toChallengeHome = () => {
    navigate(routeName.student.challenge)
  }

  const handleCorrectAnswerClick = (imageUrl: string) => {
    if (imageUrl === correctAnswerImageUrl) {
      setCorrectAnswerImageUrl(undefined)
      return
    }
    setCorrectAnswerImageUrl(imageUrl)
  }

  return (
    <div css={_css}>
      <div className="result-container">
        {cardTitleNode}
        <div className="result-container-inner">
          <div className="result-container-scrollable">
            <div className="result-content">
              <StudentEduMaterialScoringDashboard
                style={{ ...(isMobile && { minWidth: '100%' }) }}
                eduMaterialType="CONCEPTUAL"
                scoring={{
                  ...problemScoringColl.gradingCount,
                  total: problemScoringCollArr.length,
                }}
                score={studentWorksheet.score ?? 0}
                status={studentWorksheet.status}
              />
              {isMobile ? (
                <div className="scoring-card-container">
                  {problemScoringCollArr.map((problemScoring) => (
                    <WorksheetScoringCardMobile
                      key={problemScoring.problem.id}
                      problemScoring={problemScoring}
                      type={worksheet.type}
                      viewOption={problemScoringViewOption}
                      isVisibleConcept
                    />
                  ))}
                </div>
              ) : (
                <div className="result-table">
                  <ProblemScoringResultTable
                    problemScoringList={problemScoringCollArr}
                    type={worksheet.type}
                    onCorrentAnswerClick={handleCorrectAnswerClick}
                  />
                </div>
              )}
            </div>
          </div>
          {correctAnswerImageUrl && (
            <CorrectAnswerImageModal
              imageUrl={correctAnswerImageUrl}
              onClose={() => {
                setCorrectAnswerImageUrl(undefined)
              }}
            />
          )}
        </div>
      </div>

      <Button onClick={toChallengeHome} theme="primary" type="submit" style={{ marginTop: 'auto' }}>
        학습 종료하기
      </Button>
    </div>
  )
}

export default observer(ConceptualLearningResult)

const _css = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .result-container {
    background-color: ${colors.white};
    border-radius: var(--Radius-300);
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: hidden;

    ${mediaQuery.underTablet} {
      background-color: transparent;
    }
  }

  .result-container-inner {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .result-content {
    margin-top: 16px;
    padding: 0 20px;

    ${mediaQuery.underTablet} {
      margin-top: unset;
      padding: unset;
    }
  }

  .result-container-scrollable {
    height: 100%;
    overflow: auto;
    position: relative;

    ${mediaQuery.underTablet} {
      height: unset;
      overflow: unset;
    }
  }

  .result-table {
    padding-bottom: 20px;
  }

  ${mediaQuery.underTablet} {
    .scoring-card-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: transparent;
      margin-top: 20px;
    }
  }
`
