import { css } from '@emotion/react'
import clsx from 'clsx'
import { sortBy } from 'lodash'

import { colors, typo } from '~/@common/styles'
import type { IterableItem } from '~/@common/types'
import Checkbox from '~/@common/ui/checkbox'
import { checkboxGroupItemFactory } from '~/@common/ui/checkbox/group/CheckbotGroupItem'
import type { Props } from '~/@common/ui/checkbox/group/CheckboxGroup'
import type { CheckboxGroupInputValue } from '~/@common/ui/checkbox/group/CheckboxGroup.context'
import type { CheckboxGroupInputProps } from '~/@common/ui/checkbox/group/CheckboxGroupInput'

import { UNKNOWN_VALUE } from '.'
import S from './자동채점입력.style'

export const Circle = (props: CheckboxGroupInputProps) =>
  checkboxGroupItemFactory(
    props,
    'AutoMarkingItem',
    css`
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--Radius-Circle);
        color: ${colors.gray.$800};
        background-color: ${colors.gray.$100};
        border: 1px solid ${colors.gray.$200};
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      input:checked + label {
        color: ${colors.blue.$500};
        border: 1px solid ${colors.blue.$500};
        background-color: ${colors.blue.$110};
      }
      &.unknown label {
        font-size: 12px;
      }
      &.unknown-short-answer-circle {
        margin-left: 10px;
      }
      &.circle-sm {
        label {
          width: 28px;
          height: 28px;
        }
        &.unknown label {
          ${typo.caption02};
        }
      }
    `,
  )

// 모름Circle은 객관식과 주관식에서 공통으로 사용함
export const 모름Circle = ({
  className,
  ...props
}: Omit<CheckboxGroupInputProps, 'value' | 'children'>) => {
  return (
    <Circle value={UNKNOWN_VALUE} className={clsx('unknown', className)} {...props}>
      모름
    </Circle>
  )
}

// 객관식
export type 객관식Props = Omit<Props, 'max'> & {
  max: Props['max']
} & {
  data: IterableItem<string | number, CheckboxGroupInputValue>[]
  circleClassName?: string
}

export const 자동채점입력_객관식 = ({
  max,
  values,
  onChange,
  data,
  circleClassName,
  ...props
}: 객관식Props) => {
  return (
    <S.AutoMarkingContainer>
      <Checkbox.Group
        values={values}
        onChange={(v, e) => {
          if (v === undefined) return

          // 모름값은 연산에서 제외된다.
          const nextValues = v.filter((value) => value !== UNKNOWN_VALUE)

          // 가. 이번에 체크된 경우
          if (e?.target.checked) {
            // a. 모름일 경우 모름으로 세팅
            if (e?.target.value === UNKNOWN_VALUE) {
              onChange?.([UNKNOWN_VALUE])
              return
            }

            // (b) max를 넘어갈 경우 마지막 기록을 삭제하고,
            const diff = max ? nextValues.length + 1 - max : 0
            if (diff > 0) {
              for (let i = 0; i < diff; i++) {
                nextValues.pop()
              }
              // c. 새로운 값을 넣는다.
              nextValues.push(e.target.value)
            }
          }

          // 나. 이번에 체크해제 되었을 경우
          // a. 모름 체크해제일 경우 빈 값으로 세팅
          if (e?.target.value === UNKNOWN_VALUE) {
            onChange?.([])
            return
          }

          // b. 모름이 아닐 경우
          onChange?.(sortBy(nextValues))
        }}
        {...props}
      >
        {data.map((item) => (
          <Circle value={item.value} key={item.value} className={circleClassName}>
            {item.label}
          </Circle>
        ))}
        <모름Circle className={circleClassName} />
      </Checkbox.Group>
    </S.AutoMarkingContainer>
  )
}
