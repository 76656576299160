import { css } from '@emotion/react'
import styled from '@emotion/styled'
import clsx from 'clsx'

import { colors, colorTheme } from '~/@common/styles'
import type { StyleProps } from '~/@common/types/props.ts'

export type Props = {
  percent: number
  isOutDated?: boolean
} & StyleProps

export const ProgressBar = ({ percent, isOutDated = false, className, style }: Props) => {
  const isZero = percent === 0
  const isHundred = percent === 100

  return (
    <S.Container className={clsx('ms-component-progress-bar', className)} style={style}>
      <span className={clsx('progress-text', isZero && 'is-zero', isHundred && 'is-hundred')}>
        {percent}%
      </span>
      <div className="progress-outer">
        <div
          className={clsx('progress-inner', isOutDated && 'out-dated')}
          css={S.progress(percent)}
        ></div>
      </div>
    </S.Container>
  )
}

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: 100%;

    .progress-outer {
      width: 100%;
      height: 8px;
      border-radius: var(--Radius-Circle);
      background: ${colors.gray.$300};
    }

    .progress-inner {
      height: 100%;
      background: ${colorTheme.primary};
      color: ${colorTheme.primary};
      border-radius: var(--Radius-Circle);

      &.out-dated {
        background: #c0c0c0;
      }
    }

    .progress-text {
      color: ${colors.gray.$600};
    }
  `,
  progress: (percent: number) => css`
    width: ${percent}%;
  `,
}
