import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const S = {
  Wrapper: styled.div`
    overflow: auto;
  `,
  Profile: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 384px;
    margin: 0 auto;

    ${mediaQuery.underTablet} {
      width: 100%;
      margin-top: 10px;
    }

    .profile-box {
      background-color: ${colors.white};
      border-radius: var(--Radius-Circle);
      overflow: hidden;
    }
    .profile-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 20px 30px;
    }
    .name {
      color: ${colors.gray.$900};
      font-weight: 700;
      min-height: 24px;
      ${typo.body01}
    }
    .info {
      display: flex;
      gap: 12px;
    }
    .info-label {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: ${colors.gray.$700};
      ${typo.body02}
    }
    .info-value {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: ${colors.gray.$900};
      ${typo.body02}
      > p {
        min-height: 21px;
      }
    }
    .button-container {
      display: flex;
      border-top: 1px solid ${colors.gray.$200};

      button:first-of-type {
        border-right: 1px solid ${colors.gray.$200};
      }
    }
    .password-change {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0;
      width: 50%;
      gap: 2px;

      &:active {
        background-color: ${colors.gray.$100};
      }
      p {
        text-align: center;
        ${typo.caption01};
      }
    }
  `,
  Etc: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 384px;
    margin: 0 auto;
    gap: 10px;
    margin-top: 10px;
    ${mediaQuery.underTablet} {
      width: 100%;
    }

    .etc-card {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 0 30px;
      width: 100%;
      border-radius: var(--Radius-300);
      height: 45px;
      cursor: pointer;

      &:active {
        background-color: ${colors.gray.$100};
      }
      > p {
        margin-left: 4px;
        margin-right: auto;
      }
    }
  `,
}
