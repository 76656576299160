import { css } from '@emotion/react'
import styled from '@emotion/styled'
import clsx from 'clsx'

import type { DefaultProps } from '~/@common/types'
import { Card } from '~/@common/ui/(Card)'
import { LearningContentCardAsChild } from '~/@common/ui/(Card)/LearningContentCard'

interface StudentWorksheetCardProps extends DefaultProps {
  onClick?: () => void
  ctaSlot?: React.ReactNode
}

export const StudentWorksheetCardAsParent = ({
  children,
  ctaSlot,
  className,
  style,
  onClick,
}: StudentWorksheetCardProps) => {
  return (
    <StyledStudentWorksheetCardAsParent className={clsx(className)} style={style} onClick={onClick}>
      <div className="top">{children}</div>
      {ctaSlot}
    </StyledStudentWorksheetCardAsParent>
  )
}

export const StudentWorksheetCardAsChild = ({
  children,
  className,
  style,
  onClick,
}: StudentWorksheetCardProps) => {
  return (
    <div
      css={css`
        cursor: pointer;
        .wrapper {
          gap: 4px;
        }
      `}
      className={className}
      style={style}
      onClick={onClick}
    >
      <LearningContentCardAsChild wrapperClassName="wrapper" className="inner">
        {children}
      </LearningContentCardAsChild>
    </div>
  )
}

const StyledStudentWorksheetCardAsParent = styled(Card)`
  position: relative;
  z-index: 0;
  cursor: pointer;
  .top {
    padding: 14px 20px 8px;
    border-top-right-radius: var(--Radius-300);
    border-top-left-radius: var(--Radius-300);
    :last-of-type {
      padding-bottom: 14px;
    }
  }
`
