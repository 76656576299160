import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import type { FC, MouseEvent } from 'react'
import { isMobile } from 'react-device-detect'

import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { IconNames } from '~/@common/ui/Icon/iconNames.type'

interface Props {
  iconName: IconNames
  iconSize: number
  size?: 'sm' | 'md' | 'lg'
  label: string
  marginLeft?: number
  isHiddenLabel?: boolean
  isOn?: boolean
  disabled?: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const ImageViewerToolbarButton: FC<Props> = ({
  iconName,
  iconSize,
  size,
  label,
  marginLeft = 0,
  isHiddenLabel,
  isOn,
  disabled,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      data-size={size}
      disabled={disabled}
      className={clsx(isOn && 'on')}
      css={_ButtonStyle}
      style={{ marginLeft }}
      color={disabled ? 'lightGray' : 'middleGray'}
    >
      <Icon name={iconName} size={iconSize} />
      <span className={clsx('label', isHiddenLabel && 'hidden')}>{label}</span>
    </button>
  )
}

export default observer(ImageViewerToolbarButton)

const _ButtonStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--Radius-100);
  color: ${colors.gray.$700};
  padding: 10px 8px;

  &.on {
    color: ${colors.blue.$500};
    .label {
      color: inherit;
    }
  }
  &[disabled] {
    color: ${colors.gray.$500};
    > span {
      color: ${colors.gray.$500};
    }
  }
  &:not([disabled]):hover {
    background-color: ${isMobile ? 'transparent' : '#f5f5f5'};
  }
  &:not([disabled]):active {
    background-color: #ebebeb;
  }
  &[data-size='sm'] {
    width: 28px;
    height: 28px;
  }
  &[data-size='md'] {
    width: 61px;
    height: 62px;
    border-radius: var(--Radius-300);
  }
  &[data-size='lg'] {
    width: 64px;
    height: 64px;
    border-radius: var(--Radius-300);
  }
  .label {
    ${typo.caption01};
    color: ${colors.gray.$800};
  }
  .hidden {
    position: absolute;
    left: -9999px;
  }
`
