import styled from '@emotion/styled'
import { clsx } from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

import { colors, textEllipsis, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import type {
  Item,
  Items,
  SelectedItem,
} from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/types/LearningProcessNavigation.type.ts'
import { addCustomLabel } from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/utils/label.ts'

export type Props = {
  items: Items
  selectedItem: SelectedItem
  lastSelectedItems?: SelectedItem[]
  onClickItem?: (value: SelectedItem) => void
}

export const kind = 'ms__LearningProcessStepChips' as const
const parts = {
  chip: `${kind}_chip`,
} as const

const LearningProcessStepChips = ({
  selectedItem,
  items,
  onClickItem,
  lastSelectedItems,
}: Props) => {
  const renderItems = addCustomLabel({
    allItems: items,
    lastSelectedItems,
    currentSelectedItem: selectedItem,
  })
  const [isOpened, setIsOpened] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpened(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <S.ChipContainer>
      {renderItems.map((item) => (
        <React.Fragment key={item.label}>
          <div
            ref={buttonRef}
            role="button"
            className={clsx(parts.chip, selectedItem.parent.value === item.value && 'selected')}
            onClick={() =>
              item.children ? setIsOpened(!isOpened) : onClickItem && onClickItem({ parent: item })
            }
            tabIndex={0}
          >
            {item.renderLabel}
            {!!item.children && (
              <div className={clsx('icon-wrapper', isOpened && 'opened')}>
                <Icon name="icon_dropdown_m_arrow_down" />
              </div>
            )}
            {!!item.children && isOpened && (
              <LearningProcessStepChipDropdown
                item={item.children}
                selectedItemValue={selectedItem.children?.value}
                onClick={(each: Item) => {
                  onClickItem?.({
                    parent: item,
                    children: each,
                  })
                }}
              />
            )}
          </div>

          <div className="dashed-wrapper">
            <div className="dashed" />
            <div className="dashed" />
            <div className="dashed" />
          </div>
        </React.Fragment>
      ))}
    </S.ChipContainer>
  )
}

export default LearningProcessStepChips

const S = {
  ChipContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    .${parts.chip} {
      display: flex;
      align-items: center;
      position: relative;
      padding: 4px 10px;
      background-color: #c0c0c01a;
      border-radius: var(--Radius-200);
      min-width: fit-content;
      min-height: 30px;
      color: ${colors.gray.$500};
      font-weight: 700;
      cursor: pointer;

      &.selected {
        background-color: rgba(57, 95, 226, 0.1);
        color: ${colors.blue.$500};
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
      }

      .icon-wrapper.opened {
        rotate: 180deg;
        transition: rotate 100ms;
      }
    }

    .dashed-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      &:last-of-type {
        display: none;
      }
    }

    .dashed {
      display: block;
      width: 1.5px;
      height: 1px;
      border-radius: var(--Radius-100);
      background-color: ${colors.gray.$500};
    }
  `,
  DropdownWrapper: styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 6px);
    border: 1px solid ${colors.blue.$500};
    border-radius: var(--Radius-300);
    background-color: ${colors.white};
    overflow: hidden;
    z-index: 100;

    ul {
      width: max-content;
      max-height: 288px;
      overflow: scroll;
    }

    li {
      display: flex;
      align-items: center;
      width: 400px;
      padding: 20px;
      color: ${colors.gray.$900};
      font-weight: 400;
      ${typo.body02};
      text-align: left;

      &:hover {
        background-color: ${colors.gray.$200};
      }

      &.selected {
        background-color: hsla(227, 74%, 55%, 0.1);

        &:hover {
          background-color: hsla(227, 74%, 55%, 0.15);
        }
      }

      .title {
        width: 100%;
        ${textEllipsis(1)};
      }
    }

    span {
      color: ${colors.blue.$500};
      font-weight: 700;
      ${typo.body02};
    }
  `,
}

type DropdownItem = {
  value: string | number
  label: string
}

type DropdownProps = {
  selectedItemValue?: string | number
  item: DropdownItem[]
  onClick: (item: Item) => void
}

const LearningProcessStepChipDropdown = ({ item, selectedItemValue, onClick }: DropdownProps) => {
  return (
    <S.DropdownWrapper>
      <ul>
        {item.map((each, index) => (
          <li
            key={each.label + index}
            onClick={() => onClick(each)}
            className={index === Number(selectedItemValue) ? 'selected' : ''}
          >
            <div className="title">
              <span>학습 {index + 1}. </span>
              {each.label}
            </div>
            <Icon name="icon_chevron_right" width="24px" height="24px" />
          </li>
        ))}
      </ul>
    </S.DropdownWrapper>
  )
}
