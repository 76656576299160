import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'

import { colors, typo } from '~/@common/styles'

interface Props {
  level: ConceptChipDomain.LevelOfDifficulty
  className?: string
  size?: 'small' | 'medium'
}

const { LEVEL_OF_DIFFICULTY } = ConceptChipDomain
export const kind = 'ms__ConceptChipDifficultyLevel'

const ConceptChipDifficultyLevel = ({ level, className, size = 'medium' }: Props) => (
  <span css={_css} data-component={kind} data-level={level} data-size={size} className={className}>
    {level}
  </span>
)

export default ConceptChipDifficultyLevel

const _css = css`
  padding: 6px 10px;
  border-radius: var(--Radius-200);
  font-weight: bold;
  height: fit-content;
  ${typo.caption01};

  &[data-level='${LEVEL_OF_DIFFICULTY.기본}'] {
    color: #57bf9c;
    background-color: rgba(84, 192, 177, 0.1);
  }
  &[data-level='${LEVEL_OF_DIFFICULTY.개념}'] {
    color: ${colors.yellow};
    background-color: rgba(255, 177, 85, 0.1);
  }
  &[data-level='${LEVEL_OF_DIFFICULTY.심화}'] {
    color: ${colors.red.$300};
    background-color: rgba(255, 112, 125, 0.1);
  }

  &[data-size='small'] {
    ${typo.caption02};
    padding: 4px 6px;
  }
`
